
import { defineComponent, computed, toRefs, ref } from "vue";
import { ErrorMessage } from "vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import MapComponentOL from "@/components/maps/MapComponentOL.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "step-1",
  emit: ["nextStep"],
  props: {
    incidentData: {
      type: Object,
    },
    hasMoto: Boolean,
  },
  setup(props, { emit }) {
    let theMap = ref();
    const store = useStore();
    const myprops = toRefs(props);
    const router = useRouter();
    const goToNextStep = () => {
      emit("nextStep");
    };
    const genders = ref([
      { id: 1, name: "male" },
      { id: 2, name: "female" },
      { id: 3, name: "other" },
    ]);
    const minDate = computed(() => {
      return store.getters.getMinDate;
    });
    const maxDate = computed(() => {
      return store.getters.getMaxDate;
    });
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    let imageData = computed(() => {
      return store.getters.dataImageBase64;
    });
    const siteViewParameters = computed(() => {
      return store.getters.dataSiteViewInfo;
    });
    const mapView = computed(() => {
      return store.getters.dataMapView;
    });
    const imageIsLoading = computed(() => {
      return store.getters.imageIsLoading;
    });
    let listIncidents =
      [myprops.incidentData.value!.landIncident] || (null as any);
    let mode = ref(null) as any;
    let incidentsArray = ref([]) as any;
    const coordinateReceived = (coordinates) => {
      if (
        myprops.incidentData.value &&
        myprops.incidentData.value.landIncident
      ) {
        myprops.incidentData.value.landIncident.X = coordinates[0];
        myprops.incidentData.value.landIncident.Y = coordinates[1];
      }
    };
    const setMapView = (view) => {
      store.commit(Mutations.SET_MAP_VIEW, view);
    };
    const showImage = computed(() => {
      return store.getters.isImageVisible;
    });
    setTimeout(() => {
      mode.value = "editIncident";
      incidentsArray.value = listIncidents;
    }, 200);
    return {
      goToNextStep,
      myprops,
      minDate,
      maxDate,
      format,
      router,
      listIncidents,
      incidentsArray,
      mode,
      imageData,
      coordinateReceived,
      siteViewParameters,
      mapView,
      imageIsLoading,
      showImage,
      setMapView,
      theMap,
      genders,
    };
  },
  components: {
    ErrorMessage,
    Datepicker,
    MapComponentOL,
  },
});

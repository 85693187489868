
import { defineComponent, onMounted, onActivated, ref } from "vue";
import CurrentsTable from "@/components/tables/CurrentsTable.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ToolbarCurrents from "@/layout/toolbar/ToolbarCurrents.vue";
import { subheaderDisplay } from "@/core/helpers/config";
import MapComponentOL from "@/components/maps/MapComponentOL.vue";

export default defineComponent({
  name: "currents-view",
  components: {
    CurrentsTable,
    ToolbarCurrents,
    MapComponentOL,
  },
  setup() {
    const store = useStore();
    let theToolbar = ref();

    const theMap = ref();
    let dataLoaded = false;
    let tableView = ref();
    const propageDate = (arrayDate) => {
      theToolbar.value.setFilterDate(arrayDate);
    };
    // let mapView = store.getters.dataMapView;
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });
    let currentsDict = computed(() => {
      return store.getters.dataCurrentsList;
    });
    let currentsField = computed(() => {
      return [store.getters.dataCurrentsField];
    });
    let speedColors = computed(() => {
      return store.getters.dataSpeedColors;
    });
    let speedThresholds = computed(() => {
      return store.getters.dataSpeedThresholds;
    });
    let worstDirectionCartesian = computed(() => {
      return store.getters.dataWorstDirectionCartesian;
    });
    let mapView = computed(() => {
      return store.getters.dataMapView;
    });
    let imageIsLoading = computed(() => {
      return store.getters.imageIsLoading;
    });

    let siteViewParameters = computed(() => {
      return store.getters.dataSiteViewInfo;
    });

    const siteId = () => {
      return store.getters.getDefaultSiteId;
    };

    const loadData = (filterDate = null) => {
      // Default currents today + 2 days
      let filterStart =
        filterDate == null ? new Date() : filterDate["startDate"];
      let filterEnd =
        filterDate == null ? new Date(filterStart) : filterDate["endDate"];
      filterStart.setHours(0, 0, 0, 0);
      if (filterDate == null) filterEnd.setDate(filterEnd.getDate() + 2);
      filterEnd.setHours(23, 59, 59, 0);
      let promise1 = store
        .dispatch(Actions.GET_CURRENTS_LIST, {
          siteId: siteId(),
          startDate: filterStart.toISOString(),
          endDate: filterEnd.toISOString(),
        })
        .then((currentsList) => {
          propageDate([filterStart, filterEnd]);
          if (currentsList.length > 0) {
            store.dispatch(Actions.GET_CURRENTS_FIELD, {
              siteId: siteId(),
              uuid: currentsList[0].UUID,
            });
          }
        });
      let promise2 = store.dispatch(Actions.GET_CURRENTS_FORECAST_SIZE, {
        siteId: siteId(),
      });
      Promise.all([promise1, promise2]).then(() => {
        dataLoaded = true;
        store.commit(Mutations.SET_CURRENTS_VISIBLE, true);
      });
      // .catch(() => {
      //   store.commit(Mutations.SET_IMAGE_LOADING, false);
      // });
    };
    // onBeforeMount(() => {
    //   store.commit(Mutations.SET_IMAGE_VISIBLE, false);
    //   store.commit(Mutations.SET_CURRENTS_VISIBLE, true);
    //   store.commit(Mutations.SET_INCIDENTS_VISIBLE, false);
    // });
    onActivated(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: "currentsForecast",
      });
      store.commit(Mutations.SET_IMAGE_VISIBLE, false);
      store.commit(Mutations.SET_CURRENTS_VISIBLE, true);
      store.commit(Mutations.SET_INCIDENTS_VISIBLE, false);
      theMap.value.resizeMap();
    });
    onMounted(() => {
      loadData();
    });

    function refreshCurrents(uuid) {
      store.dispatch(Actions.GET_CURRENTS_FIELD, {
        siteId: siteId(),
        uuid,
      });
    }
    function clickROW(uuid) {
      // if (index != 0)
      //   document
      //     .getElementsByTagName("tbody")[0]
      //     .getElementsByTagName("tr")
      //     [index - 3]?.scrollIntoView({ behavior: "smooth" });
      // else
      //   document
      //     .getElementsByTagName("tbody")[0]
      //     .getElementsByTagName("tr")[0]
      //     .scrollIntoView({ block: "end" });
      refreshCurrents(uuid);
    }

    function setMapView(view) {
      store.commit(Mutations.SET_MAP_VIEW, view);
    }

    function previousRow() {
      tableView.value.previousRow();
    }

    function nextRow() {
      tableView.value.nextRow();
    }

    return {
      pageTitle,
      subheaderDisplay,
      currentsDict,
      speedColors,
      speedThresholds,
      worstDirectionCartesian,
      currentsField,
      dataLoaded,
      clickROW,
      refreshCurrents,
      setMapView,
      mapView,
      imageIsLoading,
      tableView,
      previousRow,
      nextRow,
      loadData,
      siteViewParameters,
      theMap,
      theToolbar,
    };
  },
});


import { defineComponent, toRefs, ref } from "vue";
import { ErrorMessage } from "vee-validate";
import "@vuepic/vue-datepicker/dist/main.css";
import slider from "vue3-slider";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "step-2",
  emit: ["previousStep", "nextStep"],
  props: {
    incidentData: {
      type: Object,
    },
    hasMoto: Boolean,
  },
  setup(props, { emit }) {
    const myprops = toRefs(props);
    const router = useRouter();
    const goToNextStep = () => {
      emit("nextStep");
    };
    const goToPreviousStep = () => {
      emit("previousStep");
    };

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    return {
      goToNextStep,
      goToPreviousStep,
      myprops,
      format,
      router,
    };
  },
  components: {
    ErrorMessage,
    slider,
  },
});

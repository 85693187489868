import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import i18n from "@/core/plugins/i18n";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("lang");
    window.localStorage.removeItem("defaultSite");
    window.localStorage.removeItem("maxDate");
    window.localStorage.removeItem("minDate");
    window.localStorage.removeItem("siteViewParameters");
    JwtService.destroyToken();
  }

  @Action
  async [Actions.LOGIN](credentials) {
    this.context.commit(Mutations.SET_ERROR, {});
    this.context.commit(Mutations.RESET_STATE_USER);
    this.context.commit(Mutations.RESET_STATE_DATA);
    await ApiService.unSetHeader();
    await ApiService.post("login", credentials)
      .then(async ({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        window.localStorage.setItem("email", credentials.email);
        this.context.commit(Mutations.SET_EMAIL, credentials.email);
        await ApiService.setHeader();
        await ApiService.get("", "get_default_user_info").then(
          async ({ data }) => {
            // console.log("GET_SITE_VIEW_INFO", data.defaultSite);
            // console.log(data);
            // Create cookies
            window.localStorage.setItem(
              "lang",
              data.defaultLanguage.replace("-ES", "")
            );
            window.localStorage.setItem(
              "defaultSite",
              JSON.stringify(data.defaultSite)
            );
            window.localStorage.setItem("minDate", data.minDate);
            window.localStorage.setItem("maxDate", data.maxDate);
            // Set userData
            this.context.commit(
              Mutations.SET_LANGUAGE,
              data.defaultLanguage.replace("-ES", "")
            );
            i18n.global.locale.value = data.defaultLanguage.replace("-ES", "");
            this.context.commit(Mutations.SET_DEFAULT_SITE, data.defaultSite);
            this.context.commit(
              Mutations.SET_MAX_DATE,
              data.maxDate != null ? new Date(data.maxDate) : null
            );
            this.context.commit(
              Mutations.SET_MIN_DATE,
              data.minDate != null ? new Date(data.minDate) : null
            );
            await this.context
              .dispatch(Actions.GET_SITE_VIEW_INFO, {
                siteId: data.defaultSite.Id,
              })
              .then((data) => {
                this.context.commit(Mutations.SET_VIEW_INFO, data[0]);
              });
          }
        );
      })
      .catch((error) => {
        // console.log("error", error);
        if (!error) {
          // console.log("No hay una respuesta");
          this.context.commit(Mutations.SET_ERROR, {
            message: ["No connection"],
          });
        } else {
          // console.log("Si hubo una respuesta");
          if ("message" in error.response.data) {
            this.context.commit(Mutations.SET_ERROR, error.response.data);
          }
        }
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  async [Actions.FORGOT_PASSWORD](payload) {
    // this.context.commit(Mutations.SET_ERROR, {});
    // console.log("FORGOT_PASSWORD");
    await ApiService.unSetHeader();
    await ApiService.post("forgot_password", payload)
      .then(() => {
        // console.log("OK");
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error.response.data);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    // console.log("Verificando");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("", "verify_token")
        // .then(({ data }) => {
        // .then(() => {
        //   console.log("Token verified");
        // })
        .catch(() => {
          // console.log("Token expired");
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // console.log(response);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}


import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  onActivated,
} from "vue";
import ToolbarAddIncident from "@/layout/toolbar/sea-incidents/ToolbarAddIncident.vue";
import Step1 from "@/views/crafted/sea-incidents/Step1.vue";
import Step2 from "@/views/crafted/sea-incidents/Step2.vue";
import Step3 from "@/views/crafted/sea-incidents/Step3.vue";
import store from "../../../store";
import Swal from "sweetalert2";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "add-incident",
  components: {
    ToolbarAddIncident,
    Step1,
    Step2,
    Step3,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    let step = ref(1);
    // TODO - Connect to database moto siteId
    const sitesWithMoto = [34, 41, 46];
    const hasMoto = sitesWithMoto.includes(store.getters.getDefaultSite.Id);
    let dataIncident = reactive({
      siteId: store.getters.getDefaultSite.Id,
      incident: {
        SiteID: store.getters.getDefaultSite.Id,
        Comments: null,
        ImageUUID: null,
        IncidentCauseID: null,
        IncidentTypeID: null,
        OccurrenceDateTime: new Date(),
        NPersons: 1,
        X: null,
        Y: null,
        UUID: null,
        JetSkiInvolved: null,
        VictimsToHospital: 0,
        VictimTypeID: null,
      },
    });
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });
    const nextStep = () => {
      step.value += 1;
    };
    const previousStep = () => {
      step.value -= 1;
    };
    const addIncident = () => {
      if (dataIncident.incident.Comments == "")
        dataIncident.incident.Comments = null;
      store
        .dispatch(Actions.ADD_NEW_INCIDENT, dataIncident)
        .then(() => {
          store.commit(
            Mutations.SET_LAST_DATE_INCIDENT_ADDED,
            dataIncident.incident.OccurrenceDateTime
          );
          Swal.fire({
            text: t("saveSuccessfull"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-success fw-bold",
            },
          }).then(() => {
            router.push({ name: "sea-incidents" });
          });
        })
        .catch(() => {
          Swal.fire({
            text: t("errorSaveIncident"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-error fw-bold",
            },
          });
        });
    };
    onActivated(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: "addNewSeaIncident",
      });
    });
    onMounted(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: pageTitle,
        pageBreadcrumbPath: [],
      });
    });
    return {
      pageTitle,
      step,
      nextStep,
      previousStep,
      addIncident,
      dataIncident,
      hasMoto,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"height":"86vh"}
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  class: "text-center p-6",
  style: {"position":"absolute","top":"10px","left":"13px"}
}
const _hoisted_4 = { class: "svg-icon svg-icon-4x svg-icon-primary" }
const _hoisted_5 = { class: "pb-lg-3 pt-7" }
const _hoisted_6 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_7 = { class: "row d-inline" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "mt-3 mb-2" }
const _hoisted_10 = { class: "w-100" }
const _hoisted_11 = { class: "fv-row" }
const _hoisted_12 = { class: "row justify-content-center px-10" }
const _hoisted_13 = ["id", "value"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "d-block fw-bold text-start" }
const _hoisted_16 = { class: "text-dark fw-bolder d-block fs-4 text-center" }
const _hoisted_17 = {
  key: 0,
  class: "col-6 mb-10"
}
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "w-100" }
const _hoisted_20 = { class: "col-12 text-center mb-9" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/landscape.svg" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.router.currentRoute.value.name === "add-land-incident"
              ? _ctx.$t("regNewIncidentLand")
              : _ctx.$t("editIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t("typeIncident")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeIncidents, (incident) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-4",
              key: incident.Id
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                class: "btn-check",
                id: `incident-${incident.Id}`,
                value: incident.Id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((
                _ctx.myprops.incidentData.value.landIncident.LandIncidentTypeID
              ) = $event)),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emptyCauseIncident()))
              }, null, 8, _hoisted_13), [
                [_vModelRadio, 
                _ctx.myprops.incidentData.value.landIncident.LandIncidentTypeID
              ]
              ]),
              _createElementVNode("label", {
                class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-center mb-10",
                for: `incident-${incident.Id}`
              }, [
                _createElementVNode("span", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(`typelandIncident.${incident.Name}`).toUpperCase()), 1)
                ])
              ], 8, _hoisted_14)
            ]))
          }), 128)),
          (
              _ctx.myprops.incidentData.value.landIncident.LandIncidentTypeID === 9
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _withDirectives(_createElementVNode("textarea", {
                  type: "textarea",
                  class: "form-control",
                  placeholder: _ctx.$t('indicateTypeIncident'),
                  name: "observations",
                  id: "comments",
                  style: {"height":"100px"},
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((
                _ctx.myprops.incidentData.value.landIncident
                  .LandIncidentTypeDescription
              ) = $event))
                }, null, 8, _hoisted_18), [
                  [_vModelText, 
                _ctx.myprops.incidentData.value.landIncident
                  .LandIncidentTypeDescription
              ]
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ErrorMessage, { name: "accountType" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("button", {
          class: "btn btn-lg btn-dark w-20 mb-5 me-15",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args)))
        }, _toDisplayString(_ctx.$t("goBack")), 1),
        _createElementVNode("button", {
          type: "submit",
          ref: "submitButton",
          id: "kt_sign_in_submit",
          class: "btn btn-lg btn-primary w-30 mb-5",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToNextStep && _ctx.goToNextStep(...args))),
          disabled: 
            _ctx.myprops.incidentData.value.landIncident.LandIncidentTypeID ==
              null ||
            (_ctx.myprops.incidentData.value.landIncident.LandIncidentTypeID === 9 &&
              (_ctx.myprops.incidentData.value.landIncident
                .LandIncidentTypeDescription == null ||
                _ctx.myprops.incidentData.value.landIncident
                  .LandIncidentTypeDescription.length < 3))
          
        }, [
          _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("continue")), 1)
        ], 8, _hoisted_21)
      ])
    ])
  ]))
}
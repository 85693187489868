import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"height":"86vh"}
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  class: "text-center p-6",
  style: {"position":"absolute","top":"10px","left":"13px"}
}
const _hoisted_4 = { class: "svg-icon svg-icon-4x svg-icon-primary" }
const _hoisted_5 = { class: "pb-lg-3 pt-7" }
const _hoisted_6 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_7 = { class: "text-gray-400 fw-bold fs-6 text-center mb-3" }
const _hoisted_8 = { class: "form-group row justify-content-center mb-2" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row d-inline" }
const _hoisted_11 = { class: "text-gray-400 fw-bold fs-6 text-center" }
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = { class: "col-1 d-inline-block" }
const _hoisted_14 = { class: "col-1 d-inline-block" }
const _hoisted_15 = { class: "mt-3 mb-2" }
const _hoisted_16 = { class: "w-100" }
const _hoisted_17 = { class: "pb-10 pb-lg-3" }
const _hoisted_18 = { class: "d-flex align-items-center text-dark text-center justify-content-center font-size-h4" }
const _hoisted_19 = { class: "fv-row" }
const _hoisted_20 = { class: "row justify-content-center" }
const _hoisted_21 = { class: "col-3" }
const _hoisted_22 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "surf"
}
const _hoisted_23 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_24 = { class: "d-block fw-bold text-start" }
const _hoisted_25 = { class: "text-dark fw-bolder d-block fs-4 mb-2 text-center" }
const _hoisted_26 = { class: "col-3" }
const _hoisted_27 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center",
  for: "bathe"
}
const _hoisted_28 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_29 = { class: "d-block fw-bold text-start" }
const _hoisted_30 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_31 = { class: "col-3" }
const _hoisted_32 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "otherType"
}
const _hoisted_33 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_34 = { class: "d-block fw-bold text-start" }
const _hoisted_35 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_36 = { class: "w-100" }
const _hoisted_37 = { class: "pb-10 pb-lg-3" }
const _hoisted_38 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_39 = { class: "fv-row" }
const _hoisted_40 = { class: "row justify-content-center" }
const _hoisted_41 = { class: "col-3" }
const _hoisted_42 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "currents"
}
const _hoisted_43 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_44 = { class: "d-block fw-bold text-start" }
const _hoisted_45 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_46 = { class: "col-3" }
const _hoisted_47 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center",
  for: "exhaust"
}
const _hoisted_48 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_49 = { class: "d-block fw-bold text-start" }
const _hoisted_50 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_51 = { class: "col-3" }
const _hoisted_52 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "otherCause"
}
const _hoisted_53 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_54 = { class: "d-block fw-bold text-start" }
const _hoisted_55 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_56 = { class: "w-100" }
const _hoisted_57 = { class: "col-12 text-center mb-9" }
const _hoisted_58 = ["disabled"]
const _hoisted_59 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_slider = _resolveComponent("slider")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/lifeJacket.svg" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.router.currentRoute.value.name === "add-incident"
              ? _ctx.$t("regNewIncident")
              : _ctx.$t("editIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("whenHappend")), 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Datepicker, {
            modelValue: _ctx.myprops.incidentData.value.incident.OccurrenceDateTime,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.OccurrenceDateTime) = $event)),
            locale: "es",
            format: _ctx.format,
            autoApply: "",
            "time-picker-inline": "",
            closeOnAutoApply: false,
            minDate: _ctx.minDate,
            maxDate: 
              _ctx.maxDate == null
                ? new Date()
                : new Date(_ctx.maxDate) > new Date()
                ? new Date()
                : new Date(_ctx.maxDate)
            ,
            noToday: "",
            clearable: false,
            hideInputIcon: "",
            inputClassName: "dp-custom-input"
          }, null, 8, ["modelValue", "format", "minDate", "maxDate"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("howManyPersons")), 1),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", {
              class: "svg-icon svg-icon-3x svg-icon-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (
                _ctx.myprops.incidentData.value.incident.NPersons < 2
                  ? ''
                  : (_ctx.myprops.incidentData.value.incident.NPersons -= 1)
              ))
            }, [
              _createVNode(_component_inline_svg, { src: "/media/icons/minus.svg" })
            ])
          ]),
          _createVNode(_component_slider, {
            modelValue: _ctx.myprops.incidentData.value.incident.NPersons,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.NPersons) = $event)),
            color: "#278ffb",
            "track-color": "#7A7A7A",
            min: 1,
            max: 20,
            step: 1,
            width: "300px",
            height: 12,
            style: {"margin":"0 auto"},
            class: "d-inline-block align-middle"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", {
              class: "svg-icon svg-icon-3x svg-icon-primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (
                _ctx.myprops.incidentData.value.incident.NPersons >= 20
                  ? ''
                  : (_ctx.myprops.incidentData.value.incident.NPersons += 1)
              ))
            }, [
              _createVNode(_component_inline_svg, { src: "/media/icons/plus.svg" })
            ])
          ]),
          _createElementVNode("h1", _hoisted_15, _toDisplayString(_ctx.myprops.incidentData.value.incident.NPersons) + " " + _toDisplayString(_ctx.myprops.incidentData.value.incident.NPersons == 1
                ? _ctx.$t("person")
                : _ctx.$t("persons")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("h5", _hoisted_18, _toDisplayString(_ctx.$t("typeIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              id: "surf",
              value: 2,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.IncidentTypeID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.IncidentTypeID]
            ]),
            _createElementVNode("label", _hoisted_22, [
              _createElementVNode("span", _hoisted_23, [
                _createVNode(_component_inline_svg, { src: "media/icons/surf.svg" })
              ]),
              _createElementVNode("span", _hoisted_24, [
                _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("surfAccident")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              value: 3,
              id: "bathe",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.IncidentTypeID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.IncidentTypeID]
            ]),
            _createElementVNode("label", _hoisted_27, [
              _createElementVNode("span", _hoisted_28, [
                _createVNode(_component_inline_svg, { src: "media/icons/bath.svg" })
              ]),
              _createElementVNode("span", _hoisted_29, [
                _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t("bathingAccident")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_31, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              id: "otherType",
              value: 1,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.IncidentTypeID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.IncidentTypeID]
            ]),
            _createElementVNode("label", _hoisted_32, [
              _createElementVNode("span", _hoisted_33, [
                _createVNode(_component_inline_svg, { src: "media/icons/other.svg" })
              ]),
              _createElementVNode("span", _hoisted_34, [
                _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("other")), 1)
              ])
            ])
          ]),
          _createVNode(_component_ErrorMessage, { name: "accountType" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_36, [
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("h5", _hoisted_38, _toDisplayString(_ctx.$t("typeCause")), 1)
      ]),
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              id: "currents",
              value: 1,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.IncidentCauseID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.IncidentCauseID]
            ]),
            _createElementVNode("label", _hoisted_42, [
              _createElementVNode("span", _hoisted_43, [
                _createVNode(_component_inline_svg, { src: "media/icons/currentcause.svg" })
              ]),
              _createElementVNode("span", _hoisted_44, [
                _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("currentRescue")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_46, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              id: "exhaust",
              value: 2,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.IncidentCauseID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.IncidentCauseID]
            ]),
            _createElementVNode("label", _hoisted_47, [
              _createElementVNode("span", _hoisted_48, [
                _createVNode(_component_inline_svg, { src: "media/icons/exhausted.svg" })
              ]),
              _createElementVNode("span", _hoisted_49, [
                _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.$t("exhaustionRescue")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_51, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              id: "otherCause",
              value: 3,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.IncidentCauseID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.IncidentCauseID]
            ]),
            _createElementVNode("label", _hoisted_52, [
              _createElementVNode("span", _hoisted_53, [
                _createVNode(_component_inline_svg, { src: "media/icons/other.svg" })
              ]),
              _createElementVNode("span", _hoisted_54, [
                _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.$t("other")), 1)
              ])
            ])
          ]),
          _createVNode(_component_ErrorMessage, { name: "accountType" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_56, [
      _createElementVNode("div", _hoisted_57, [
        _createElementVNode("button", {
          type: "submit",
          ref: "submitButton",
          id: "kt_sign_in_submit",
          class: "btn btn-lg btn-primary w-25 mb-5",
          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.goToNextStep && _ctx.goToNextStep(...args))),
          disabled: 
            _ctx.myprops.incidentData.value.incident.IncidentTypeID == null ||
            _ctx.myprops.incidentData.value.incident.IncidentCauseID == null
          
        }, [
          _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t("continue")), 1)
        ], 8, _hoisted_58)
      ])
    ])
  ]))
}
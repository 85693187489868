
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default defineComponent({
  name: "kt-widget-12",
  emits: ["setLineDate"],
  props: {
    widgetClasses: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    let active_row = ref(null);
    const list = computed(() => {
      return store.getters.dataMetocean;
    });
    const clickTR = (index, date) => {
      active_row.value = index;
      emit("setLineDate", date);
    };

    return {
      list,
      active_row,
      clickTR,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65eff444"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card-body py-3",
  style: {"height":"32vh"}
}
const _hoisted_2 = { class: "table-responsive tableFixHead" }
const _hoisted_3 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_4 = { style: {"position":"sticky","top":"0","z-index":"10"} }
const _hoisted_5 = { class: "fw-bolder text-muted" }
const _hoisted_6 = { class: "min-w-100px" }
const _hoisted_7 = { style: {"margin-left":"15px"} }
const _hoisted_8 = { class: "min-w-140px" }
const _hoisted_9 = { class: "min-w-120px" }
const _hoisted_10 = { class: "min-w-100px text-left" }
const _hoisted_11 = { id: "tableBody" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { style: {"border-radius":"10px 0 0 10px"} }
const _hoisted_14 = {
  style: {"margin-left":"15px"},
  href: "#",
  class: "text-dark fw-bolder fs-6"
}
const _hoisted_15 = {
  href: "#",
  class: "text-dark fw-bolder d-block mb-1 fs-6"
}
const _hoisted_16 = {
  href: "#",
  class: "text-dark fw-bolder d-block mb-1 fs-6"
}
const _hoisted_17 = { style: {"border-radius":"0 10px 10px 0"} }
const _hoisted_18 = { style: {"display":"inline-flex","vertical-align":"middle"} }
const _hoisted_19 = {
  href: "#",
  class: "text-dark fw-bolder d-block mb-1 fs-6"
}
const _hoisted_20 = {
  key: 0,
  class: "text-center fw-bolder",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", _hoisted_4, [
            _createElementVNode("tr", _hoisted_5, [
              _createElementVNode("th", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("date")), 1)
              ]),
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("waveHeight")), 1),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("levelSea")), 1),
              _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t("direction")), 1)
            ])
          ]),
          _createElementVNode("tbody", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: index,
                style: _normalizeStyle(index % 2 ? 'background-color:#dff5ff' : ''),
                class: _normalizeClass(_ctx.active_row == index ? 'activeRow' : ''),
                onClick: ($event: any) => (
                  _ctx.isLoading || _ctx.active_row == index
                    ? ''
                    : _ctx.clickTR(item['UUID'], index)
                )
              }, [
                _createElementVNode("td", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString((typeof item.TargetDateTime === "string"
                        ? new Date(item.TargetDateTime)
                        : item.TargetDateTime
                      ).toLocaleString("es-ES", { timeZone: "Europe/Madrid" })), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_15, _toDisplayString((Math.round(item.Hs * 100) / 100).toFixed(2) + " m"), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_16, _toDisplayString((Math.round(item.WaterLevel * 100) / 100).toFixed(2) + " m"), 1)
                ]),
                _createElementVNode("td", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(Math.round(item.Dir) + "º"), 1),
                    _createVNode(_component_inline_svg, {
                      style: _normalizeStyle(
                        'transform: rotate(' +
                        item.Dir +
                        'deg);margin-left:10px'
                      ),
                      src: "media/icons/arrow-down.svg"
                    }, null, 8, ["style"])
                  ])
                ])
              ], 14, _hoisted_12))
            }), 128))
          ])
        ]),
        (_ctx.list.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t("noResultsDates")), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bd9262e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card-body py-3",
  style: {"height":"44vh"}
}
const _hoisted_2 = { class: "table-responsive tableFixHead" }
const _hoisted_3 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_4 = { style: {"position":"sticky","top":"0","z-index":"10"} }
const _hoisted_5 = { class: "fw-bolder text-muted" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "text-dark fw-bolder fs-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", _hoisted_4, [
            _createElementVNode("tr", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.list), (property, indexprop) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: indexprop,
                  class: _normalizeClass([
                  property == 'TargetDateTime'
                    ? 'min-w-150px'
                    : ['TotalHs', 'TotalTp', 'Tsfc', 'WindSpeed'].includes(
                        property
                      )
                    ? 'min-w-70px'
                    : 'min-w-90px'
                , "text-center"])
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t(property)), 1)
                ], 2))
              }), 128))
            ])
          ]),
          (_ctx.list && Object.keys(_ctx.list).length > 0)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list[Object.keys(_ctx.list)[0]].length, (value, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: index,
                    class: _normalizeClass(index == _ctx.active_row ? 'activeRow' : ''),
                    onClick: ($event: any) => (_ctx.clickTR(index, _ctx.list['TargetDateTime'][index]))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.list), (property, indexProp) => {
                      return (_openBlock(), _createElementBlock("td", {
                        style: _normalizeStyle(
                  index == _ctx.active_row
                    ? 'background-color: #ffc80059'
                    : indexProp % 2
                    ? 'background-color:#dff5ff'
                    : ''
                ),
                        key: indexProp,
                        class: "text-center"
                      }, [
                        _createElementVNode("span", _hoisted_8, [
                          _createTextVNode(_toDisplayString(property != "WindSpeed" && property != "WindDirection"
                      ? _ctx.list[property][index] == -999
                        ? "-"
                        : typeof _ctx.list[property][index] === "number"
                        ? property == "Tsfc"
                          ? _ctx.list[property][index].toFixed(0)
                          : property == "TotalDirp"
                          ? _ctx.list[property][index].toFixed(0)
                          : property == "TotalTp"
                          ? _ctx.list[property][index].toFixed(1)
                          : _ctx.list[property][index].toFixed(2)
                        : new Date(_ctx.list[property][index]).toLocaleString(
                            "es-ES"
                          )
                      : "") + " " + _toDisplayString(property == "WindSpeed"
                      ? (_ctx.list[property][index] * 3.6).toFixed(0)
                      : "") + " " + _toDisplayString(property == "WindDirection"
                      ? _ctx.list[property][index] >= 0
                        ? _ctx.list[property][index]
                        : _ctx.list[property][index] + 360
                      : "") + " ", 1),
                          (
                      property == 'WindDirection' || property == 'TotalDirp'
                    )
                            ? (_openBlock(), _createBlock(_component_inline_svg, {
                                key: 0,
                                height: "20",
                                width: "30",
                                style: _normalizeStyle(
                      'transform: rotate(' +
                      _ctx.list[property][index] +
                      'deg);margin-left:10px'
                    ),
                                src: 
                      property == 'TotalDirp'
                        ? 'media/icons/arrow_down_black.svg'
                        : 'media/icons/arrow_down_blue.svg'
                    
                              }, null, 8, ["style", "src"]))
                            : _createCommentVNode("", true)
                        ])
                      ], 4))
                    }), 128))
                  ], 10, _hoisted_7))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}

import {
  defineComponent,
  computed,
  reactive,
  ref,
  onBeforeUnmount,
  onMounted,
} from "vue";
import ToolbarEditIncident from "@/layout/toolbar/land-incidents/ToolbarEditIncident.vue";
import Step1 from "@/views/crafted/land-incidents/Step1.vue";
import Step2 from "@/views/crafted/land-incidents/Step2.vue";
import Step3 from "@/views/crafted/land-incidents/Step3.vue";
import Step4 from "@/views/crafted/land-incidents/Step4.vue";
import Step5 from "@/views/crafted/land-incidents/Step5.vue";
import store from "../../../store";
import Swal from "sweetalert2";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "edit-land-incident",
  components: {
    ToolbarEditIncident,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    let step = ref(1);
    // TODO - Connect to database moto siteId

    let dataLandIncident = reactive(store.getters.getLandIncidentEditing);
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });
    const nextStep = () => {
      step.value += 1;
    };
    const previousStep = () => {
      step.value -= 1;
    };
    const goToStep = (pageStep: number) => {
      step.value = pageStep;
    };
    const isLoading = ref(false);
    // const setValues = (values: any) => {
    //   for (const key in values) {
    //     dataLandIncident[key] = values[key];
    //   }
    // };
    const editIncident = () => {
      isLoading.value = true;
      // Check if have all the required fields
      // Then add the incident
      if (dataLandIncident.landIncident.Comments == "")
        dataLandIncident.landIncident.Comments = null;

      console.log("Enviando para actualizar data land Incident");
      console.log(dataLandIncident);
      store
        .dispatch(Actions.UPDATE_LAND_INCIDENT, dataLandIncident)
        .then(() => {
          Swal.fire({
            text: t("saveSuccessfull"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-success fw-bold",
            },
          }).then(() => {
            router.push({ name: "land-incidents" });
            isLoading.value = false;
          });
        })
        .catch(() => {
          Swal.fire({
            text: t("errorSaveIncident"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-error fw-bold",
            },
          }).then(() => {
            isLoading.value = false;
          });
        });
    };
    onMounted(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: pageTitle,
        pageBreadcrumbPath: [],
      });
    });
    onBeforeUnmount(() => {
      store.commit(Mutations.SET_EDIT_LAND_INCIDENT, null);
    });
    return {
      pageTitle,
      step,
      nextStep,
      previousStep,
      goToStep,
      editIncident,
      dataLandIncident,
      isLoading,
    };
  },
});

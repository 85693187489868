import { createI18n } from "vue-i18n/index";

const messages = {
  es: {
    login: "Iniciar sesión",
    logout: "Cerrar sesión",
    wrongOldPassword: "Contraseña incorrecta",
    "Wrong user/pass": "Usuario o contraseña incorrectos",
    stayConnect: "Mantener sesión abierta en este dispositivo",
    dashboard: "Tablero",
    forgotPassword: "¿Has olvidado tu contraseña?",
    email: "Email",
    password: "Contraseña",
    emailRequired: "Es necesario introducir un email",
    passwordRequired: "Es necesario introducir una contraseña",
    errorLogin: "Usuario y/o contraseña incorrectos",
    errorPassword: "Contraseña incorrecta",
    recoverPassword: "Recuperar contraseña",
    msgRecover:
      "¿No recuerdas tu contraseña?, indica tu email y te enviaremos un enlace para restablecerla.",
    restorePassword: "Restablecer contraseña",
    submit: "Enviar",
    ok: "Aceptar",
    cancel: "Cancelar",
    back: "Volver",
    sending: "Enviando...",
    pleaseWait: "Porfavor, espere...",
    msgLogin: "o bien, inicia sesión",
    beachGuard: "BEACHGUARD",
    loginOK: "Sesión iniciada correctamente",
    logoutmsg: "Ciera tu sesión",
    currents: "Corrientes",
    currentsForecast: "Previsión de corrientes",
    incidents: "Incidentes",
    seaIncidents: "Incidentes de mar",
    landIncidents: "Incidentes de tierra",
    images: "Imágenes",
    metocean: "MetOcean",
    forecasts: "Previsiones",
    metoceanForecast: "Previsión de variables océano-meteorológicas",
    filter: "Filtrar",
    date: "Fecha",
    waveHeight: "Altura ola",
    levelSea: "Nivel del mar",
    peakPeriod: "Periodo pico",
    direction: "Dirección",
    edit: "Editar",
    changePassword: "Cambiar contraseña",
    emailAddress: "Dirección de email",
    currentPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    confirmNewPassword: "Repetir contraseña",
    updatePassword: "Actualizar contraseña",
    minSecurityPassword:
      "La contraseña debe contener al menos 6 caracteres incluyendo una mayúscula, una minúscula y un número",
    language: "Idioma",
    beach: "Playa",
    userDetails: "Detalles de usuario",
    saveChanges: "Guardar cambios",
    discard: "Cancelar",
    saveDaysPhotos: "Días guardado imágenes (5-15)",
    errorDays: "Introduce un valor entre 5 y 15 días",
    oldPasswordRequired: "Introduce la contraseña actual",
    newPasswordRequired: "Introduce la nueva contraseña",
    repeatPasswordNotIdentical: "No coincide con la nueva contraseña",
    passwordChangedSuccessfully: "Contraseña cambiada correctamente",
    cause: "Causa",
    type: "Tipo",
    typeIncident: "Tipo de incidente",
    typeCause: "Causa de incidente",
    observations: "Observaciones",
    currentRescue: "Corriente",
    exhaustionRescue: "Cansancio",
    other: "Otros",
    others: "Otros",
    surfAccident: "Surf",
    bathingAccident: "Baño",
    create: "Crear",
    createIncident: "Crear incidente",
    showAllIncidents: "Ver todos los incidentes de la tabla",
    showOneIncident: "Ver un sólo incidente de la tabla",
    waterLevel: "Nivel del mar",
    AirTemperature: "Temp. aire",
    TargetDateTime: "Fecha",
    TotalDirp: "Dirp (º)",
    TotalHs: "Hs (m)",
    TotalTp: "Tp (s)",
    WaterLevel: "Nivel (m)",
    WindDirection: "Dir V (º)",
    WindGust: "Racha",
    WindSpeed: "Vel V (km/h)",
    WaterTemperature: "Temp. del agua",
    settings: "Ajustes",
    addNewIncident: "Añadir incidente",
    addNewSeaIncident: "Añadir incidente de mar",
    addNewlandIncident: "Añadir incidente de tierra",
    continue: "Continuar",
    regNewIncident: "Registrar incidente",
    regNewIncidentLand: "Registrar incidente de tierra",
    select: "OK",
    whenHappend: "¿Cuando ocurrió?",
    howManyPersons: "¿Cuántas personas hay involucradas?",
    persons: "Personas",
    person: "Persona",
    filters: "Filtros",
    dates: "Fechas",
    deleteFilters: "Quitar filtros",
    max5Days: "Máximo 5 días",
    spanish: "Español - Gaztelera",
    euskera: "Euskera",
    saveSuccessfull: "Datos guardados con éxito",
    errorSave: "Hubo un problema al guardar los datos, vuelva a intentarlo.",
    noResultsDates: "No hay datos disponibles para estas fechas",
    apply: "Aplicar",
    Tsfc: "T aire(º)",
    saveIncidentSuccessfull: "Incidente registrado con éxito",
    errorSaveIncident:
      "Hubo un problema al registrar el incidente, vuelva a intentarlo.",
    saveIncident: "Guardar incidente",
    leaveAnObservation: "Deja una observación en caso de ser necesario",
    selectWhereTheIncidentOcurred: "Selecciona dónde ocurrió el incidente",
    goBack: "Volver atrás",
    forgotEmailSent: "Se ha enviado un email a tu dirección de correo.",
    instructionsSteps:
      "Sigue las instrucciones en el correo para establecer tu contraseña.",
    lookAtYourEmail: "Revisa tu email",
    min6characters: "La contraseña debe contener al menos 6 caracteres",
    userNotExist: "El usuario no se encuentra en nuestra base de datos",
    retry: "Volver a intentar",
    editIncident: "Editar incidente",
    editSeaIncident: "Editar incidente de mar",
    editlandIncident: "Editar incidente de tierra",
    noImage: "Sin imagen",
    imageNearest: "Hora cercana",
    imageFar: "Hora lejana",
    setNewSiteView: "¿Establecer nueva vista?",
    infoNewSiteView:
      "Se establecerá la nueva vista de la playa en el mapa para todos los dispositivos",
    establish: "Establecer",
    established: "Establecido",
    setView: "Establecer vista",
    setViewSuccessfull: "Vista establecida con éxito",
    all: "Todos",
    moto: "Moto",
    motoInvolved: "¿Moto involucrada?",
    addObservationsWithEmptyMsg: "¿Añadir incidente sin observaciones?",
    personsSentToHospital: "Personas enviadas al hospital",
    typeVictim: "Tipo de victima",
    distressVictim: "Distress",
    activeVictim: "Activo",
    passiveVictim: "Pasivo",
    yes: "Si",
    no: "No",
    motive: "Motivo",
    onlyHospitalized: "Sólo hospitalizados",
    onlyWithJetSki: "Sólo con moto",
    generalData: {
      beach: "Playa",
      date: "Fecha",
      nameInjured: "Nombre",
      gender: "Género",
      age: "Edad",
      place: "Lugar",
    },
    typelandIncident: {
      injury: "traumatismo",
      sting: "picadura",
      burn: "quemadura",
      wound: "herida",
      syncope: "sincope-lipotimia, insolación, golpe de calor",
      "airway obstruction": "obstrucción de la via aerea",
      seizure: "crisis convulsivas",
      "cardiorespiratory-arrest": "parada cardiorespiratoria",
      others: "otros",
      checkError: "Error",
    },
    bodyRegions: {
      "head region": "cabeza",
      "trunk region": "tronco",
      "upper limb region": "miembro superior",
      "lower limb region": "miembro inferior",
      skull: "craneo",
      face: "cara",
      forehead: "frente",
      eye: "ojo",
      ear: "oreja",
      nose: "nariz",
      cheek: "mejilla",
      mouth: "boca",
      chin: "menton",
      neck: "cuello",
      posterior: "posterior",
      anterior: "anterior",
      thorax: "torax",
      pectoral: "pectoral",
      back: "espalda",
      ribs: "costillas",
      clavicle: "clavicula",
      spine: "columna vertebral",
      abdomen: "abdomen",
      superior: "superior",
      central: "central",
      inferior: "inferior",
      flanks: "flancos",
      pelvis: "pelvis",
      shoulder: "hombro",
      humerus: "húmero",
      elbow: "codo",
      forearm: "antebrazo",
      wrist: "muñeca",
      hand: "mano",
      fingers: "dedos",
      hip: "cadera",
      thigh: "muslo",
      knee: "rodilla",
      midsection: "tercio medio",
      ankle: "tobillo",
      foot: "pie",
      toes: "dedos",
      others: "otros",
    },
    causeIncidents: {
      injury: "traumatismo",
      sting: "picadura",
      burn: "quemadura",
      wound: "herida",
      "fainting-syncope": "sincope-lipotimia",
      sunstroke: "insolación",
      "heat stroke": "golpe de calor",
      "airway obstruction": "obstrucción de la via aherea",
      seizure: "crisis convulsivas",
      "cardiorespiratory arrest": "parada cardiorespiratoria",
      others: "otros",
      fall: "caida",
      blow: "golpe",
      collision: "choque (tabla...)",
      action: "acción (surf, futbol, voley, paseo...)",
      salmonella: "salvera",
      jellyfish: "medusa",
      "portuguese jellyfish": "carabela portuguesa",
      bee: "abeja",
      wasp: "avispa",
      algae: "alga",
      anemone: "anemona",
      "sea urchin": "herizo",
      solar: "solar",
      fire: "fuego",
      ice: "hielo",
      chemical: "quimico",
      electrical: "eléctrico",
      friction: "fricción",
      keel: "quilla",
      utensil: "utensilio (cuchillo, vaso...)",
      incrustations: "incrustaciones (cristal, arena, anzuelo, objeto...)",
      heat: "calor",
      "low blood pressure": "bajada tensión",
      dehydration: "deshidratación",
      "lack of food": "falta de alimento",
      medication: "medicación",
      choking: "atragantamiento (comida, saliba, objeto)",
      epilepsy: "epilepsia",
      "high temperature": "alta temperatura",
      tce: "tce",
      "drowned in sea": "ahogado mar",
      "drowned on land": "ahogado tierra",
      "heart attack": "infarto",
      "sudden death": "muerte súbita",
      checkError: "Error",
    },
    actions: {
      transfer: "traslado",
      defibrillator: "desfibrilador",
      others: "otros",
      additionalActions: "Acciones adicionales",
      immobilization: "Inmovilización parcial o total",
    },
    male: "Hombre",
    female: "Mujer",
    indicateTypeIncident: "Indica el tipo de incidente",
    indicateCauseIncident: "Indica la causa del incidente",
    indicateOthers: "Marca otros detalles del incidente",
    selectRegion: "Selecciona la región del cuerpo",
    breadcrumbTooltip: "Haz click en la opción superior que quieras modificar",
    bodyRegion: "Zona del cuerpo",
    causeIncident: "Causa del incidente",
    indicateBodyRegion: "Indica la zona del cuerpo",
    typeAccident: "Tipo de accidente",
    indicateOtherInformation: "Indica otros detalles del incidente",
    indicateAdditionalActions: "Indica las acciones adicionales",
  },
  eu: {
    login: "Saioa hasi",
    logout: "Saioa itxi",
    wrongOldPassword: "Pasahitz okerra",
    "Wrong user/pass": "Erabiltzailea edo pasahitz okerra",
    stayConnect: "Irekita eduki saioa gailu honetan",
    dashboard: "Taula",
    forgotPassword: "Pasahitza ahaztu duzu?",
    email: "Posta elektronikoa",
    password: "Pasahitza",
    emailRequired: "Posta elektroniko bat sartu behar da",
    passwordRequired: "Pasahitza sartu behar da",
    errorLogin: "Erabiltzaile eta/edo pasahitz okerra",
    errorPassword: "Pasahitza ez da zuzena",
    recoverPassword: "Pasahitza berreskuratu",
    msgRecover:
      "Ez duzu pasahitza gogoratzen? Adierazi zure helbide elektronikoa eta esteka bat bidaliko dizugu berrezartzeko.",
    restorePassword: "Berrezarri pasahitza",
    submit: "Bidali",
    ok: "Onartu",
    cancel: "Utzi",
    back: "Itzuli",
    sending: "Bidaltzen...",
    pleaseWait: "Mesedez, itxaron ...",
    msgLogin: "edo, bestela, hasi saioa",
    beachGuard: "BEACHGUARD",
    loginOK: "Saioa ongi hasi da",
    logoutmsg: "itxi saioa",
    currents: "Korronteak",
    currentsForecast: "Korronteen aurreikuspena",
    incidents: "Gertakariak",
    seaIncidents: "Itsas-gertakariak",
    landIncidents: "Lur-gertariak",
    images: "Irudiak",
    metocean: "MetOcean",
    forecasts: "Aurreikuspenak",
    metoceanForecast: "Aldagai ozeano-meteorologikoen aurreikuspena",
    filter: "Iragazi",
    date: "Data",
    waveHeight: "Olatuaren altuera",
    levelSea: "Itsas-maila",
    peakPeriod: "Puntako periodoa",
    direction: "Norabidea",
    edit: "Editatu",
    changePassword: "Pasahitza aldatu",
    emailAddress: "Posta elektroniko helbidea",
    currentPassword: "Uneko pasahitza",
    newPassword: "Pasahitz berria",
    confirmNewPassword: "Errepikatu pasahitza",
    updatePassword: "Pasahitza eguneratu",
    minSecurityPassword:
      "Pasahitzak gutxienez 6 karaktere izan behar ditu, letra larri bat, minuskula bat eta zenbaki bat barne.",
    language: "Hizkuntza",
    beach: "Hondartza",
    userDetails: "Erabiltzailearen xehetasunak",
    saveChanges: "Gorde aldaketak",
    discard: "Ezeztatu",
    saveDaysPhotos: "Irudiak gordetako egunak (5-15)",
    errorDays: "5 eta 15 egun arteko balio bat sartu",
    oldPasswordRequired: "Sartu uneko pasahitza",
    newPasswordRequired: "Sartu pasahitz berria",
    repeatPasswordNotIdentical: "Ez dator bat pasahitz berriarekin",
    passwordChangedSuccessfully: "Pasahitza behar bezala aldatu da",
    cause: "Arrazoia",
    type: "Mota",
    typeIncident: "Gertakari mota",
    typeCause: "Gertakariaren arrazoia",
    observations: "Oharrak",
    currentRescue: "Korrontea",
    exhaustionRescue: "Nekea",
    other: "Besteak",
    others: "Besteak",
    surfAccident: "Surf",
    bathingAccident: "Bainua",
    create: "Sortu",
    createIncident: "Gertakaria sortu",
    showAllIncidents: "Ikusi taulako gertakari guztiak",
    showOneIncident: "Ikusi taulako gertakari bakarra",
    waterLevel: "Itsas-maila",
    AirTemperature: "Airearen tenp.",
    TargetDateTime: "Data",
    TotalDirp: "Norp (Âº)",
    TotalHs: "Hs (m)",
    TotalTp: "Tp (s)",
    WaterLevel: "Maila (m)",
    WindDirection: "Norabidea V (º)",
    WindGust: "Haize bolada",
    WindSpeed: "Abiadura V (km/h)",
    WaterTemperature: "Uraren tenp.",
    settings: "Ezarpenak",
    addNewIncident: "Gertakaria gehitu",
    addNewSeaIncident: "Itsas-gertakaria gehitu",
    addNewlandIncident: "Lur-gertakaria gehitu",
    continue: "Jarraitu",
    regNewIncident: "Gertakaria erregistratu",
    regNewIncidentLand: "Lurreko gertakaria erregistratu",
    select: "OK",
    whenHappend: "Noiz gertatu zen?",
    howManyPersons: "Zenbat pertsona daude tartean?",
    persons: "Pertsonak",
    person: "Pertsona",
    filters: "Iragazkiak",
    dates: "Datak",
    deleteFilters: "Iragazkiak kendu",
    max5Days: "5 egun gehienez",
    spanish: "Español - Gaztelera",
    euskera: "Euskera",
    saveSuccessfull: "Datuak ongi gorde dira",
    errorSave: "Arazo bat egon da datuak gordetzean, saiatu berriro.",
    noResultsDates: "Ez dago daturik data horietarako",
    apply: "Aplikatu",
    Tsfc: "Airearen T (º)",
    saveIncidentSuccessfull: "Gertaera ondo erregistratu da",
    errorSaveIncident:
      "Arazo bat izan da gertaera erregistratzean, saiatu berriro.",
    saveIncident: "Gertakaria gorde",
    leaveAnObservation: "Utzi ohar bat, beharrezkoa bada",
    selectWhereTheIncidentOcurred: "Hautatu gertaera non gertatu den",
    goBack: "Itzuli atzera",
    forgotEmailSent: "Helbide elektronikora mezu elektroniko bat bidali zaizu.",
    instructionsSteps: "Bete postako jarraibideak zure pasahitza ezartzeko.",
    lookAtYourEmail: "Berrikusi zure posta elektronikoa",
    min6characters: "Pasahitzak gutxienez 6 karaktere izan behar ditu",
    userNotExist: "Erabiltzailea ez dago gure datu-basean",
    retry: "Saiatu berriro",
    editIncident: "Editatu gertakaria",
    editSeaIncident: "Editatu itsas-gertakaria",
    editlandIncident: "Editatu lur-gertakaria",
    noImage: "Irudirik gabe",
    imageNearest: "Hurbileko ordua",
    imageFar: "Urruneko ordua",
    setNewSiteView: "Ikuspegi berria ezarri?",
    infoNewSiteView:
      "Hondartzaren ikuspegi berria ezarriko da mapan, gailu guztietarako",
    establish: "Ezarri",
    established: "Ezarria",
    setView: "Ezarri ikuspegia",
    setViewSuccessfull: "Ikuspegia ondo ezarri da",
    all: "Danak",
    moto: "Motoa",
    motoInvolved: "Motorraren erabilera",
    addObservationsWithEmptyMsg: "Gertakaria gehitu oharrik gabe?",
    personsSentToHospital: "Ospitalera bidalitako pertsonak",
    typeVictim: "Biktima mota",
    distressVictim: "Distress",
    activeVictim: "Aktiboa",
    passiveVictim: "Pasiboa",
    yes: "Bai",
    no: "Ez",
    motive: "Mota",
    onlyHospitalized: "Ospitaleratuak bakarrik",
    onlyWithJetSki: "Motoarekin bakarrik",
    generalData: {
      beach: "Hondartza",
      date: "Data",
      nameInjured: "Izena",
      gender: "Sexua",
      age: "Adina",
      place: "Lekua",
    },
    typelandIncident: {
      injury: "traumatismoa",
      sting: "ziztada",
      burn: "erredura",
      wound: "zauria",
      syncope: "sinkope-lipotimia, eguzki-kolpea, bero-kolpea",
      "airway obstruction": "arnasbidearen oztopoa",
      seizure: "konbultsio-krisia",
      "cardiorespiratory-arrest": "bihotz-biriketako geldialdia",
      others: "beste batzuk",
      checkError: "Errorea",
    },
    bodyRegions: {
      "head region": "burua",
      "trunk region": "enborra",
      "upper limb region": "goiko zatia",
      "lower limb region": "beheko zatia",
      skull: "kraneo",
      face: "aurpegia",
      forehead: "bekokia",
      eye: "begia",
      ear: "belarria",
      nose: "sudurra",
      cheek: "masaila",
      mouth: "ahoa",
      chin: "kokotsa",
      neck: "lepoa",
      posterior: "atzeko",
      anterior: "aurreko",
      thorax: "torax",
      others: "besteak",
      pectoral: "bularraldea",
      back: "bizkarraldea",
      ribs: "saihets-hezurrak",
      clavicle: "klabikula",
      spine: "bizkar-hezurra",
      abdomen: "sabelalde",
      superior: "goikoa",
      central: "zentrala",
      inferior: "behekoa",
      flanks: "alboak",
      pelvis: "pelbisa",
      shoulder: "sorbalda",
      humerus: "humeroa",
      elbow: "ukondoa",
      forearm: "besaurre",
      wrist: "eskumuturra",
      hand: "eskua",
      fingers: "hatzak",
      hip: "aldaka",
      thigh: "izterra",
      knee: "belauna",
      midsection: "erdiko alderdia",
      ankle: "txorkatila",
      foot: "oina",
      toes: "behatzak",
    },
    causeIncidents: {
      injury: "traumatismoa",
      sting: "ziztada",
      burn: "erredura",
      wound: "zauria",
      "fainting-syncope": "sinkope-lipotimia",
      sunstroke: "eguzki-kolpea",
      "heat stroke": "bero-kolpea",
      "airway obstruction": "arnasbidearen oztopoa",
      seizure: "konbultsio-krisia",
      "cardiorespiratory arrest": "bihotz-biriketako geldialdia",
      others: "beste batzuk",
      fall: "erortzea",
      blow: "kolpea",
      collision: "talka (tabla...)",
      action: "ekintza (surf, futbol, voley, paseo...)",
      salmonella: "salbera",
      jellyfish: "marmoka",
      "portuguese jellyfish": "karabela portugaldar",
      bee: "erlea",
      wasp: "listorra",
      algae: "alga",
      anemone: "anemona",
      "sea urchin": "itsas triku",
      solar: "eguzkiko",
      fire: "sua",
      ice: "izotza",
      chemical: "kimiko",
      electrical: "elektriko",
      friction: "igurtzaldi",
      keel: "gila",
      utensil: "tresna (labana, edalontzi...)",
      incrustations: "inkrustazioak (kristala, harea, amua, objektua...)",
      heat: "beroa",
      "low blood pressure": "tentsio baxua",
      dehydration: "deshidratazioa",
      "lack of food": "janari falta",
      medication: "medikazioa",
      choking: "eztarri-trabatzea (janaria, lurra, objektua)",
      epilepsy: "epilepsia",
      "high temperature": "tenperatura altua",
      tce: "tce",
      "drowned in sea": "itsasoan itoa",
      "drowned on land": "lurrean itoa",
      "heart attack": "infartua",
      "sudden death": "bat-bateko heriotza",
      checkError: "Errorea",
    },
    actions: {
      transfer: "lekualdaketa",
      defibrillator: "defibriladorea",
      others: "besterik",
      additionalActions: "Ekintza gehigarriak",
      immobilization: "Immobilizazio partziala edo osoa",
    },
    male: "Gizona",
    female: "Emakumea",
    indicateTypeIncident: "Adierazi gertakari mota",
    indicateCauseIncident: "Adierazi gertakariaren kausa",
    indicateOthers: "Adierazi gertakariaren beste xehetasun bat",
    selectRegion: "Hautatu gorputzaren eremua",
    breadcrumbTooltip: "Egin klik aldatu nahi duzun goiko aukeran",
    bodyRegion: "Gorputzaren eremua",
    causeIncident: "Gertakariaren kausa",
    indicateBodyRegion: "Adierazi gorputzaren eremua",
    typeAccident: "Istripu mota",
    indicateOtherInformation: "Adierazi gertakariaren beste xehetasun bat",
    indicateAdditionalActions: "Adierazi ekintza osagarriak",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "es",
  globalInjection: true,
  messages,
});

export default i18n;


import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

export default defineComponent({
  name: "settings-view",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n();
    let i18n = useI18n();
    const store = useStore();
    const submitButton1 = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const passwordFormDisplay = ref(false);
    let selectedBeach = ref({}) as any;
    let selectedLanguage = ref(null) as any;
    let availableBeachs = ref([]) as any;

    const siteId = () => {
      return store.getters.getDefaultSiteId;
    };

    const language = () => {
      return store.getters.getLanguage;
    };

    const changePassword = Yup.object().shape({
      oldPassword: Yup.string().required().label("Current password"),
      newPassword: Yup.string().min(6).required().label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const saveChanges = () => {
      let hasChanged = false;
      if (submitButton1.value) {
        if (selectedBeach.value != siteId()) {
          const newSiteInfo = availableBeachs.value.filter((beach) => {
            return beach.Id == selectedBeach.value;
          });
          if (newSiteInfo.length > 0) {
            // Reset position of map to inherit
            store.commit(Mutations.SET_MAP_VIEW, null);
            store.commit(Mutations.SET_DEFAULT_SITE, newSiteInfo[0]);
            store.commit(
              Mutations.SET_ENABLE_LAND_INCIDENTS,
              newSiteInfo[0].EnableLandIncidents
            );

            window.localStorage.setItem(
              "defaultSite",
              JSON.stringify(newSiteInfo[0])
            );
            store.dispatch(Actions.GET_SITE_VIEW_INFO, {
              siteId: newSiteInfo[0].Id,
            });
            store.commit(Mutations.SET_RESET_VIEWS, true);
            setInterval(() => {
              store.commit(Mutations.SET_RESET_VIEWS, false);
            }, 500);
            hasChanged = true;
          }
          // Aquí tengo que eliminar todas las vistas anteriores para que se recreen con los nuevos datos.
        }

        // Activate indicator
        if (selectedLanguage.value != language()) {
          submitButton1.value.setAttribute("data-kt-indicator", "on");
          store
            .dispatch(Actions.SET_USER_LANGUAGE, {
              language: selectedLanguage.value,
            })
            .then(() => {
              window.localStorage.setItem("lang", selectedLanguage.value);
              store.commit(Mutations.SET_LANGUAGE, selectedLanguage.value);
              i18n.locale.value = selectedLanguage.value;
              Swal.fire({
                text: t("saveSuccessfull"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("ok"),
                customClass: {
                  confirmButton: "btn btn-success fw-bold",
                },
              });
              submitButton1.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: t("errorSave"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton1.value?.removeAttribute("data-kt-indicator");
            });
        } else if (hasChanged) {
          Swal.fire({
            text: t("saveSuccessfull"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-success fw-bold",
            },
          });
        }
      }
    };

    const updatePassword = async (values) => {
      console.log("Actualizando password");
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
        console.log(values);
        if (values["newPassword"] == values["confirmpassword"]) {
          await store.dispatch(Actions.CHANGE_PASSWORD, values);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];

          if (!error) {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: t("passwordChangedSuccessfully"),
              icon: "success",
              confirmButtonText: t("ok"),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            }).then(() => {
              passwordFormDisplay.value = false;
            });
          } else {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t("errorPassword"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        }
      }
    };

    onMounted(() => {
      //setCurrentPageBreadcrumbs("Ajustes", ["Account"]);
      store.dispatch(Actions.GET_AVAILABLE_BEACHS).then((data) => {
        availableBeachs.value = data;
      });
      selectedLanguage.value = store.getters.getLanguage;
      selectedBeach.value = store.getters.getDefaultSite.Id;
      console.log(store.getters.getDefaultSite);
      console.log(selectedBeach.value);
    });

    return {
      submitButton1,
      passwordFormDisplay,
      changePassword,
      updatePasswordButton,
      updatePassword,
      availableBeachs,
      selectedBeach,
      selectedLanguage,
      saveChanges,
      language,
      siteId,
    };
  },
});

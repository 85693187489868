
import { defineComponent, ref } from "vue";
import FilterMetocean from "@/components/filters/FilterMetocean.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "toolbar-metocean",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    FilterMetocean,
  },
  setup(props, { emit }) {
    let dateFilter = ref(null) as any;
    let theFilter = ref() as any;
    const propageFilters = (filterMetocean) => {
      // console.log("Segundo componente");
      // console.log(filterMetocean);
      dateFilter.value = filterMetocean;
      emit("applyfilter", filterMetocean);
    };
    function setFilterDate(arrayDates) {
      // Filter forced by insertion of incident
      dateFilter.value = {
        startDate: arrayDates[0],
        endDate: arrayDates[1],
      };
      theFilter.value.setFilterDate(arrayDates);
    }
    return {
      toolbarWidthFluid,
      propageFilters,
      dateFilter,
      setFilterDate,
      theFilter,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"height":"86vh"}
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  class: "text-center p-6",
  style: {"position":"absolute","top":"10px","left":"13px"}
}
const _hoisted_4 = { class: "svg-icon svg-icon-4x svg-icon-primary" }
const _hoisted_5 = { class: "pb-lg-3 pt-7" }
const _hoisted_6 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_7 = { class: "row d-inline" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "mt-3 mb-2" }
const _hoisted_10 = { class: "py-6 text-center" }
const _hoisted_11 = {
  key: 0,
  "aria-label": "breadcrumb"
}
const _hoisted_12 = { class: "breadcrumb justify-content-center" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 1,
  "aria-label": "breadcrumb"
}
const _hoisted_15 = { class: "breadcrumb justify-content-center" }
const _hoisted_16 = {
  class: "breadcrumb-item h4",
  "aria-current": "page"
}
const _hoisted_17 = { class: "form-text" }
const _hoisted_18 = { class: "w-100" }
const _hoisted_19 = {
  key: 0,
  class: "fv-row"
}
const _hoisted_20 = {
  key: 0,
  class: "row justify-content-center px-10"
}
const _hoisted_21 = ["id", "value", "onClick"]
const _hoisted_22 = ["for"]
const _hoisted_23 = { class: "d-block fw-bold text-start" }
const _hoisted_24 = { class: "text-dark fw-bolder d-block fs-4 text-center" }
const _hoisted_25 = {
  key: 1,
  class: "row justify-content-center px-10"
}
const _hoisted_26 = ["id", "value", "onClick"]
const _hoisted_27 = ["for"]
const _hoisted_28 = { class: "d-block fw-bold text-start" }
const _hoisted_29 = { class: "text-dark fw-bolder d-block fs-4 text-center" }
const _hoisted_30 = {
  key: 2,
  class: "row justify-content-center px-10"
}
const _hoisted_31 = ["id", "value", "onClick"]
const _hoisted_32 = ["for"]
const _hoisted_33 = { class: "d-block fw-bold text-start" }
const _hoisted_34 = { class: "text-dark fw-bolder d-block fs-4 text-center" }
const _hoisted_35 = {
  key: 3,
  class: "fv-row"
}
const _hoisted_36 = { class: "row justify-content-center" }
const _hoisted_37 = { class: "col-10" }
const _hoisted_38 = ["placeholder"]
const _hoisted_39 = { class: "w-100" }
const _hoisted_40 = { class: "col-12 text-center mt-4" }
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/landscape.svg" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.router.currentRoute.value.name === "add-land-incident"
              ? _ctx.$t("regNewIncidentLand")
              : _ctx.$t("editIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t("bodyRegion")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.bodyRegionSelected.length > 0)
        ? (_openBlock(), _createElementBlock("nav", _hoisted_11, [
            _createElementVNode("ol", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRegionSelected, (region, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: region,
                  class: _normalizeClass(`breadcrumb-item h4 ${
              _ctx.bodyRegionSelected.length - 1 === index ? 'active' : ''
            }`),
                  "aria-current": "page",
                  onClick: ($event: any) => (_ctx.modifyBody(index))
                }, _toDisplayString(_ctx.$t(`bodyRegions.${region}`)), 11, _hoisted_13))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("nav", _hoisted_14, [
            _createElementVNode("ol", _hoisted_15, [
              _createElementVNode("li", _hoisted_16, _toDisplayString(_ctx.$t("selectRegion")), 1)
            ])
          ])),
      _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("breadcrumbTooltip")), 1)
    ]),
    _createElementVNode("div", _hoisted_18, [
      (
          _ctx.myprops.incidentData.value &&
          _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            (
            _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
              .BodyRegionID === null
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRegions, (bodyRegion) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-6",
                      key: bodyRegion.Id
                    }, [
                      _withDirectives(_createElementVNode("input", {
                        type: "radio",
                        class: "btn-check",
                        id: bodyRegion.Id,
                        value: bodyRegion.Id,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyRegionID
              ) = $event)),
                        onClick: ($event: any) => (_ctx.bodyRegionSelected.push(bodyRegion.Name))
                      }, null, 8, _hoisted_21), [
                        [_vModelRadio, 
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyRegionID
              ]
                      ]),
                      _createElementVNode("label", {
                        class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-center mb-10",
                        for: bodyRegion.Id
                      }, [
                        _createElementVNode("span", _hoisted_23, [
                          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t(`bodyRegions.${bodyRegion.Name}`).toUpperCase()), 1)
                        ])
                      ], 8, _hoisted_22)
                    ]))
                  }), 128))
                ]))
              : (
            _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
              .BodyPartID === null
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRegionsParts.filter(
              (part) =>
                part.BodyRegionId ===
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyRegionID
            ), (bodyRegion) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "col-6",
                        key: bodyRegion.Id
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          id: bodyRegion.Id,
                          value: bodyRegion.Id,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyPartID
              ) = $event)),
                          onClick: ($event: any) => (_ctx.bodyRegionSelected.push(bodyRegion.Name))
                        }, null, 8, _hoisted_26), [
                          [_vModelRadio, 
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyPartID
              ]
                        ]),
                        _createElementVNode("label", {
                          class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-center mb-10",
                          for: bodyRegion.Id
                        }, [
                          _createElementVNode("span", _hoisted_28, [
                            _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t(`bodyRegions.${bodyRegion.Name}`).toUpperCase()), 1)
                          ])
                        ], 8, _hoisted_27)
                      ]))
                    }), 128))
                  ]))
                : (
            _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
              .BodyPartsZoneID === null
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyPartZones.filter(
              (part) =>
                part.BodyPartId ===
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyPartID
            ), (bodyRegion) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "col-6",
                          key: bodyRegion.Id
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            type: "radio",
                            class: "btn-check",
                            id: bodyRegion.Id,
                            value: bodyRegion.Id,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyPartsZoneID
              ) = $event)),
                            onClick: ($event: any) => (_ctx.bodyRegionSelected.push(bodyRegion.Name))
                          }, null, 8, _hoisted_31), [
                            [_vModelRadio, 
                _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                  .BodyPartsZoneID
              ]
                          ]),
                          _createElementVNode("label", {
                            class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-center mb-10",
                            for: bodyRegion.Id
                          }, [
                            _createElementVNode("span", _hoisted_33, [
                              _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t(`bodyRegions.${bodyRegion.Name}`).toUpperCase()), 1)
                            ])
                          ], 8, _hoisted_32)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
            (_ctx.bodyRegionSelected[0] === 'others')
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _withDirectives(_createElementVNode("textarea", {
                        type: "textarea",
                        class: "form-control",
                        placeholder: _ctx.$t('indicateBodyRegion'),
                        name: "observations",
                        id: "comments",
                        style: {"height":"100px"},
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((
                  _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                    .Description
                ) = $event))
                      }, null, 8, _hoisted_38), [
                        [_vModelText, 
                  _ctx.myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                    .Description
                ]
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_39, [
      _createElementVNode("div", _hoisted_40, [
        _createElementVNode("button", {
          class: "btn btn-lg btn-dark w-20 mb-5 me-15",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args)))
        }, _toDisplayString(_ctx.$t("goBack")), 1),
        _createElementVNode("button", {
          type: "submit",
          ref: "submitButton",
          id: "kt_sign_in_submit",
          class: "btn btn-lg btn-primary w-30 mb-5",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToNextStep && _ctx.goToNextStep(...args))),
          disabled: !_ctx.isSubmiteable
        }, [
          _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t("continue")), 1)
        ], 8, _hoisted_41)
      ])
    ])
  ]))
}
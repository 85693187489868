
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "forgot-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email").trim(),
    });

    //Form submit function
    const onSubmitForgotPassword = async (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      // Send login request
      await store.dispatch(Actions.FORGOT_PASSWORD, values);

      const [errorName] = Object.keys(store.getters.getErrors);
      // console.log(errorName);
      const error = store.getters.getErrors[errorName];
      // console.log("ERROR");
      // console.log(error);
      if (!error) {
        Swal.fire({
          text: t("forgotEmailSent") + "\n" + t("instructionsSteps"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("ok"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(() => {
          router.push("/sign-in");
        });
      } else {
        Swal.fire({
          text: t(error),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("retry"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});

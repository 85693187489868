import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"height":"86vh"}
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  class: "text-center p-6",
  style: {"position":"absolute","top":"10px","left":"13px"}
}
const _hoisted_4 = { class: "svg-icon svg-icon-4x svg-icon-primary" }
const _hoisted_5 = { class: "pb-lg-3 pt-7" }
const _hoisted_6 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_7 = { class: "row d-inline" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "mt-3 mb-2" }
const _hoisted_10 = { class: "w-100" }
const _hoisted_11 = { class: "fv-row" }
const _hoisted_12 = {
  key: 0,
  class: "row justify-content-center px-10"
}
const _hoisted_13 = ["id", "value"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "d-block fw-bold text-start" }
const _hoisted_16 = { class: "text-dark fw-bolder d-block fs-4 text-center" }
const _hoisted_17 = {
  key: 1,
  class: "row justify-content-center px-10"
}
const _hoisted_18 = { class: "fv-row" }
const _hoisted_19 = { class: "row justify-content-center" }
const _hoisted_20 = { class: "col-10" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = { class: "w-100" }
const _hoisted_23 = { class: "card mb-10" }
const _hoisted_24 = { class: "col-12 text-center mt-4" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/landscape.svg" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.router.currentRoute.value.name === "add-land-incident"
              ? _ctx.$t("regNewIncidentLand")
              : _ctx.$t("editIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t("causeIncident")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        (_ctx.incidentType !== 9)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.causeIncidents.filter(
              (cause) =>
                cause.LandIncidentTypeId ===
                _ctx.myprops.incidentData.value.landIncident.LandIncidentTypeID
            ), (cause) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-6",
                  key: cause.LandIncidentTypeId
                }, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    class: "btn-check",
                    id: cause.Id,
                    value: cause.Id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((
                _ctx.myprops.incidentData.value.landIncident.LandIncidentCauseID
              ) = $event))
                  }, null, 8, _hoisted_13), [
                    [_vModelRadio, 
                _ctx.myprops.incidentData.value.landIncident.LandIncidentCauseID
              ]
                  ]),
                  _createElementVNode("label", {
                    class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center justify-content-center mb-10",
                    for: cause.Id
                  }, [
                    _createElementVNode("span", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(`causeIncidents.${cause.Name}`).toUpperCase()), 1)
                    ])
                  ], 8, _hoisted_14)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (
            _ctx.incidentType === 9 ||
            [50, 58, 65, 69, 75, 78, 82, 89, 90].includes(
              _ctx.myprops.incidentData.value.landIncident.LandIncidentCauseID
            )
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives(_createElementVNode("textarea", {
                      type: "textarea",
                      class: "form-control",
                      placeholder: _ctx.$t('indicateCauseIncident'),
                      name: "observations",
                      id: "comments",
                      style: {"height":"100px"},
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((
                    _ctx.myprops.incidentData.value.landIncident
                      .LandIncidentCauseDescription
                  ) = $event))
                    }, null, 8, _hoisted_21), [
                      [_vModelText, 
                    _ctx.myprops.incidentData.value.landIncident
                      .LandIncidentCauseDescription
                  ]
                    ])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("button", {
            class: "btn btn-lg btn-dark w-20 mb-5 me-15",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args)))
          }, _toDisplayString(_ctx.$t("goBack")), 1),
          _createElementVNode("button", {
            class: "btn btn-lg btn-primary w-25 mb-5",
            disabled: 
              _ctx.myprops.incidentData.value.landIncident.LandIncidentCauseID ==
                null ||
              ([50, 58, 65, 69, 75, 78, 82, 89, 90].includes(
                _ctx.myprops.incidentData.value.landIncident.LandIncidentCauseID
              ) &&
                (_ctx.myprops.incidentData.value.landIncident
                  .LandIncidentCauseDescription == null ||
                  _ctx.myprops.incidentData.value.landIncident
                    .LandIncidentCauseDescription.length < 3))
            ,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToNextStep && _ctx.goToNextStep(...args)))
          }, [
            _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("continue")), 1)
          ], 8, _hoisted_25)
        ])
      ])
    ])
  ]))
}

import { defineComponent, ref, computed, onActivated, toRefs } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import MapComponentOL from "@/components/maps/MapComponentOL.vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
export default defineComponent({
  name: "step-3",
  components: {
    MapComponentOL,
  },
  emits: ["addIncident", "editIncident", "previousStep"],
  props: {
    incidentData: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    let theMap = ref();
    const router = useRouter();
    const myprops = toRefs(props) as any;
    const { t } = useI18n();
    let listIncidents = [myprops.incidentData.value.incident] || (null as any);
    let incidentsImagesList = ref([]) as any;
    let mode = ref(null) as any;
    let incidentsArray = ref([]) as any;
    // listIncidents = [myprops.incidentData.value.incident];
    if (myprops.incidentData.value.incident.Comments) {
      let editedBefore =
        myprops.incidentData.value.incident.Comments.indexOf("[Editado:");
      if (editedBefore != -1) {
        myprops.incidentData.value.incident.Comments =
          myprops.incidentData.value.incident.Comments.slice(0, editedBefore);
      }
    }
    setTimeout(() => {
      mode.value = "editIncident";
      incidentsArray.value = listIncidents;
    }, 200);
    const store = useStore();
    const siteViewParameters = computed(() => {
      return store.getters.dataSiteViewInfo;
    });
    const mapView = computed(() => {
      return store.getters.dataMapView;
    });
    const imageIsLoading = computed(() => {
      return store.getters.imageIsLoading;
    });

    let imageData = computed(() => {
      return store.getters.dataImageBase64;
    });

    const setMapView = (view) => {
      store.commit(Mutations.SET_MAP_VIEW, view);
    };
    const coordinateReceived = (coordinates) => {
      myprops.incidentData.value.incident.X = coordinates[0];
      myprops.incidentData.value.incident.Y = coordinates[1];
    };
    const saveIncident = () => {
      console.log("emitiendo incidente");
      if (
        !myprops.incidentData.value.incident.Comments ||
        myprops.incidentData.value.incident.Comments === ""
      ) {
        Swal.fire({
          title: t("addObservationsWithEmptyMsg"),
          icon: "warning",
          showCancelButton: true,
          buttonsStyling: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: t("ok"),
          cancelButtonText: t("cancel"),
          customClass: {
            confirmButton: "btn btn-success fw-bold",
            cancelButton: "btn btn-danger fw-bold",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            emit("addIncident");
          }
        });
      } else {
        emit("addIncident");
      }
    };
    const editIncident = () => {
      emit("editIncident");
    };
    const goToPreviousStep = () => {
      emit("previousStep");
    };
    const showImage = computed(() => {
      return store.getters.isImageVisible;
    });
    const setImage = (uuid) => {
      console.log("Estableciendo imagen", uuid);
      myprops.incidentData.value.incident.ImageUUID = uuid;
      if (uuid != null) {
        refreshImage(uuid);
      } else {
        store.commit(Mutations.SET_IMAGE_VISIBLE, false);
      }
    };
    // onMounted(() => {
    //   store.dispatch(Actions.GET_INCIDENT_IMAGES_LIST, {
    //     siteId: store.getters.getDefaultSite.Id,
    //     date: myprops.incidentData.value.incident.OccurrenceDateTime.toISOString(),
    //   });
    //   // Then we load 0 array and offer next images
    // });
    const refreshImage = (uuid) => {
      console.log(theMap);
      if (uuid != null) {
        store.commit(Mutations.SET_IMAGE_VISIBLE, true);
        if (!theMap.value.isImageLoaded(uuid)) {
          console.log("Refrescando imagen", uuid);
          store
            .dispatch(Actions.GET_IMAGE_BASE64, {
              siteId: store.getters.getDefaultSite.Id,
              uuid: uuid,
            })
            .then(() => {
              console.log("End refreshImage");
            });
        } else {
          theMap.value.setImageLayerVisible(uuid);
          console.log("Cargo imagen de cache");
        }
      }
    };
    onActivated(() => {
      if (router.currentRoute.value.name === "add-incident") {
        store.commit(Mutations.SET_INCIDENTS_VISIBLE, true);
        store
          .dispatch(Actions.GET_INCIDENT_IMAGES_LIST, {
            siteId: store.getters.getDefaultSite.Id,
            date: myprops.incidentData.value.incident.OccurrenceDateTime.toISOString(),
          })
          .then((listImages) => {
            if (listImages.length > 0) {
              incidentsImagesList.value = listImages;
              myprops.incidentData.value.incident.ImageUUID =
                listImages[0].UUID;
              store.dispatch(Actions.GET_IMAGE_BASE64, {
                uuid: listImages[0].UUID,
                siteId: store.getters.getDefaultSite.Id,
              });
            }
          });
        // Then we load 0 array and offer next images
      } else {
        // store
        //   .dispatch(Actions.GET_INCIDENT_IMAGES_LIST, {
        //     siteId: store.getters.getDefaultSite.Id,
        //     date: new Date(
        //       myprops.incidentData.value.incident.OccurrenceDateTime
        //     ).toISOString(),
        //   })
        //   .then((incidentsImagesList) => {
        if (myprops.incidentData.value.incident.ImageUUID != null) {
          // Carga imagen que se escogió en su momento
          store.dispatch(Actions.GET_IMAGE_BASE64, {
            uuid: myprops.incidentData.value.incident.ImageUUID,
            siteId: store.getters.getDefaultSite.Id,
          });
        }
        // });
      }
    });

    return {
      theMap,
      siteViewParameters,
      mapView,
      imageIsLoading,
      setMapView,
      myprops,
      coordinateReceived,
      saveIncident,
      editIncident,
      goToPreviousStep,
      listIncidents,
      router,
      imageData,
      incidentsImagesList,
      setImage,
      showImage,
      mode,
      incidentsArray,
    };
  },
});

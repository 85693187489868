import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
  email = "" as string;
  defaultSite = undefined as any;
  availableBeachs = [] as any;
  language = "es" as string;
  maxDate = null as any;
  minDate = null as any;
  resetViews = false as boolean;

  get getEmail(): string {
    return this.email;
  }

  get getDefaultSite(): number {
    return this.defaultSite;
  }

  get getAvailableBeachs(): any {
    return this.availableBeachs;
  }

  get getLanguage(): string {
    return this.language;
  }

  get getMaxDate(): any {
    return this.maxDate;
  }

  get getMinDate(): any {
    return this.minDate;
  }

  get getDefaultSiteId(): number {
    return this.defaultSite.Id;
  }

  get getResetViews(): boolean {
    return this.resetViews;
  }

  /**
   * Change password
   * @returns none
   */
  @Action
  [Actions.CHANGE_PASSWORD](dataNewPassword) {
    return ApiService.post("change_password", dataNewPassword)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          message: response.data.message,
        });
      });
  }

  @Action
  [Actions.CHECK_TOKEN_VALIDITY](dataToken) {
    return new Promise((resolve, reject) => {
      ApiService.post("check_token_validity", dataToken)
        .then(({ data }) => {
          console.log(data);
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  @Action
  [Actions.CHANGE_FORGOTTEN_PASSWORD](dataToken) {
    return new Promise((resolve, reject) => {
      ApiService.post("change_forgotten_password", dataToken)
        .then(({ data }) => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  @Action
  [Actions.GET_AVAILABLE_BEACHS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("", "get_user_available_sites")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            message: response.data.message,
          });
          reject(response);
        });
    });
  }

  @Action
  [Actions.SET_USER_LANGUAGE](lang) {
    return new Promise((resolve, reject) => {
      ApiService.post("set_user_default_language", lang)
        .then(() => {
          this.context.commit(Mutations.SET_LANGUAGE, lang.language);
          window.localStorage.setItem("lang", lang.language);
          //this.context.commit(Mutations.SET_LANGUAGE, lang.language);
          resolve({});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            message: response.data.message,
          });
          reject(response);
        });
    });
  }

  /**
   * Change email
   * @returns none
   */
  @Mutation
  [Mutations.SET_EMAIL](userEmail) {
    this.email = userEmail;
  }

  @Mutation
  [Mutations.SET_LANGUAGE](language) {
    this.language = language;
  }

  @Mutation
  [Mutations.SET_AVAILABLE_BEACHS](availableBeachs) {
    this.availableBeachs = availableBeachs;
  }

  @Mutation
  [Mutations.SET_DEFAULT_SITE](defaultSite) {
    this.defaultSite = defaultSite;
  }

  @Mutation
  [Mutations.SET_MAX_DATE](maxDate) {
    this.maxDate = maxDate;
  }

  @Mutation
  [Mutations.SET_MIN_DATE](minDate) {
    this.minDate = minDate;
  }

  @Mutation
  [Mutations.SET_RESET_VIEWS](resetViews) {
    this.resetViews = resetViews;
  }

  @Mutation
  [Mutations.RESET_STATE_USER]() {
    this.defaultSite = undefined;
    this.availableBeachs = [];
    this.language = "es";
    this.maxDate = null;
    this.minDate = null;
  }
}

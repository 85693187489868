
import { defineComponent, toRefs, ref } from "vue";
import { ErrorMessage } from "vee-validate";
import "@vuepic/vue-datepicker/dist/main.css";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "step-2",
  emit: ["previousStep", "nextStep"],
  props: {
    incidentData: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const myprops = toRefs(props);
    const router = useRouter();
    const store = useStore();
    const typeIncidents = store.getters.getTypeLandIncidents;
    const goToNextStep = () => {
      if (myprops?.incidentData?.value) {
        if (myprops.incidentData.value?.landIncident.LandIncidentTypeID !== 9) {
          myprops.incidentData.value.landIncident.LandIncidentTypeDescription =
            null;
        }
        if (
          myprops.incidentData.value?.landIncident.LandIncidentTypeID > 4 &&
          myprops.incidentData.value?.landIncident.LandIncidentTypeID < 11 &&
          myprops.incidentData.value?.landIncident.LandIncidentTypeID !== 9
        ) {
          if (myprops.incidentData.value?.landIncident) {
            myprops.incidentData.value.landIncident.bodyZone = null;
          }
          emit("goToStep", 4);
        } else {
          emit("nextStep");
        }
      }
    };
    const goToPreviousStep = () => {
      emit("previousStep");
    };

    const emptyCauseIncident = () => {
      if (myprops?.incidentData?.value) {
        myprops.incidentData.value.landIncident.LandIncidentCauseID = null;
        myprops.incidentData.value.landIncident.LandIncidentCauseDescription =
          null;
      }
    };
    return {
      goToNextStep,
      goToPreviousStep,
      emptyCauseIncident,
      myprops,
      router,
      typeIncidents,
    };
  },
  components: {
    ErrorMessage,
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolbarAddIncident = _resolveComponent("ToolbarAddIncident")!
  const _component_Step1 = _resolveComponent("Step1")!
  const _component_Step2 = _resolveComponent("Step2")!
  const _component_Step3 = _resolveComponent("Step3")!
  const _component_Step4 = _resolveComponent("Step4")!
  const _component_Step5 = _resolveComponent("Step5")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ToolbarAddIncident, { title: "addNewIncident" }),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.step === 1)
        ? (_openBlock(), _createBlock(_component_Step1, {
            key: 0,
            onNextStep: _ctx.nextStep,
            incidentData: _ctx.dataLandIncident
          }, null, 8, ["onNextStep", "incidentData"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_ctx.step === 2)
      ? (_openBlock(), _createBlock(_component_Step2, {
          key: 0,
          onPreviousStep: _ctx.previousStep,
          onNextStep: _ctx.nextStep,
          onGoToStep: _ctx.goToStep,
          incidentData: _ctx.dataLandIncident
        }, null, 8, ["onPreviousStep", "onNextStep", "onGoToStep", "incidentData"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.step === 3)
        ? (_openBlock(), _createBlock(_component_Step3, {
            key: 0,
            onPreviousStep: _ctx.previousStep,
            onNextStep: _ctx.nextStep,
            incidentData: _ctx.dataLandIncident
          }, null, 8, ["onPreviousStep", "onNextStep", "incidentData"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_ctx.step === 4)
      ? (_openBlock(), _createBlock(_component_Step4, {
          key: 1,
          onPreviousStep: _ctx.previousStep,
          onNextStep: _ctx.nextStep,
          onGoToStep: _ctx.goToStep,
          incidentData: _ctx.dataLandIncident
        }, null, 8, ["onPreviousStep", "onNextStep", "onGoToStep", "incidentData"]))
      : _createCommentVNode("", true),
    (_ctx.step === 5)
      ? (_openBlock(), _createBlock(_component_Step5, {
          key: 2,
          onPreviousStep: _ctx.previousStep,
          onAddIncident: _ctx.addIncident,
          incidentData: _ctx.dataLandIncident,
          isLoading: _ctx.isLoading
        }, null, 8, ["onPreviousStep", "onAddIncident", "incidentData", "isLoading"]))
      : _createCommentVNode("", true)
  ], 64))
}

import { defineComponent, computed, toRefs, ref } from "vue";
import { ErrorMessage } from "vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import slider from "vue3-slider";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "step-1",
  emit: ["nextStep"],
  props: {
    incidentData: {
      type: Object,
    },
    hasMoto: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const myprops = toRefs(props);
    const router = useRouter();
    const goToNextStep = () => {
      emit("nextStep");
    };
    const minDate = computed(() => {
      return store.getters.getMinDate;
    });
    const maxDate = computed(() => {
      return store.getters.getMaxDate;
    });
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    return {
      goToNextStep,
      myprops,
      minDate,
      maxDate,
      format,
      router,
    };
  },
  components: {
    ErrorMessage,
    Datepicker,
    slider,
  },
});

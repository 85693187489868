import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "toolbar",
  id: "kt_toolbar"
}
const _hoisted_2 = {
  id: "kt_page_title",
  "data-kt-swapper": "false",
  "data-kt-swapper-mode": "prepend",
  "data-kt-swapper-parent": "{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}",
  class: "page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0 d-md-none d-sm-none d-lg-block"
}
const _hoisted_3 = { class: "d-flex align-items-center text-dark fw-bolder my-1 fs-3" }
const _hoisted_4 = { class: "d-flex align-items-center py-1" }
const _hoisted_5 = { class: "me-4" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "svg-icon svg-icon-3 svg-icon-gray-500 me-1" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  href: "",
  class: "btn btn-sm btn-primary rounded-pill"
}
const _hoisted_11 = { class: "ms-5" }
const _hoisted_12 = {
  href: "#",
  class: "btn btn-sm btn-flex btn-light btn-active-primary fw-bolder rounded-pill",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_13 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FilterIncidents = _resolveComponent("FilterIncidents")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "kt_toolbar_container",
      class: _normalizeClass([{
        'container-fluid': _ctx.toolbarWidthFluid,
        'container-xxl': !_ctx.toolbarWidthFluid,
      }, "d-flex flex-stack"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("seaIncidents")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn btn-sm btn-dark rounded-pill",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeShowAll && _ctx.changeShowAll(...args))),
            disabled: _ctx.incidentsList.length <= 1
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, {
                src: "media/icons/geopoint.svg",
                style: {"filter":"invert(100%)"}
              })
            ]),
            (_ctx.myprops.statusShowAll.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("showOneIncident")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("showAllIncidents")), 1))
          ], 8, _hoisted_6)
        ]),
        (_ctx.canWrite)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "menu-link",
              "active-class": "active",
              to: { name: 'add-incident' }
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("createIncident")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("a", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, [
              _createVNode(_component_inline_svg, { src: "media/icons/gen031.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.dateFilter == null ? _ctx.$t("all") : "") + " " + _toDisplayString(_ctx.dateFilter != null
                ? _ctx.dateFilter["startDate"]
                    .toLocaleDateString()
                    .substring(0, 10) +
                  " - " +
                  _ctx.dateFilter["endDate"].toLocaleDateString().substring(0, 10)
                : ""), 1)
          ]),
          _createVNode(_component_FilterIncidents, {
            ref: "theFilter",
            onApplyfilter: _ctx.propageFilters
          }, null, 8, ["onApplyfilter"])
        ])
      ])
    ], 2)
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolbarMetocean = _resolveComponent("ToolbarMetocean")!
  const _component_MetOceanPlots = _resolveComponent("MetOceanPlots")!
  const _component_MetoceanTable = _resolveComponent("MetoceanTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.subheaderDisplay)
      ? (_openBlock(), _createBlock(_component_ToolbarMetocean, {
          key: 0,
          title: _ctx.pageTitle,
          onApplyfilter: _ctx.loadData,
          ref: "theToolbar"
        }, null, 8, ["title", "onApplyfilter"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MetOceanPlots, {
      wlChartDatasets: _ctx.chartDatasets,
      wlChartOptions: _ctx.wlChartOptions,
      wlChartLabels: _ctx.chartLabels,
      hsChartDatasets: _ctx.chartDatasets2,
      hsChartOptions: _ctx.hsChartOptions,
      hsChartLabels: _ctx.chartLabels2,
      hsChartDirs: _ctx.hsChartDirs,
      hsChartWindDirs: _ctx.hsChartWindDirs,
      hsChartWindSpeeds: _ctx.hsChartWindSpeeds,
      instantMarker: _ctx.instantMarker,
      suntimesInfo: _ctx.suntimesInfo,
      canvasid: "metoceancanvas1"
    }, null, 8, ["wlChartDatasets", "wlChartOptions", "wlChartLabels", "hsChartDatasets", "hsChartOptions", "hsChartLabels", "hsChartDirs", "hsChartWindDirs", "hsChartWindSpeeds", "instantMarker", "suntimesInfo"]),
    _createVNode(_component_MetoceanTable, {
      "widget-classes": "mb-5 mb-xl-8",
      onSetLineDate: _ctx.setLineDate
    }, null, 8, ["onSetLineDate"])
  ], 64))
}
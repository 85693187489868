
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onBeforeUnmount,
  onMounted,
} from "vue";
import ToolbarEditIncident from "@/layout/toolbar/sea-incidents/ToolbarEditIncident.vue";
import Step1 from "@/views/crafted/sea-incidents/Step1.vue";
import Step2 from "@/views/crafted/sea-incidents/Step2.vue";
import Step3 from "@/views/crafted/sea-incidents/Step3.vue";
import store from "../../../store";
import Swal from "sweetalert2";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "edit-incident",
  components: {
    ToolbarEditIncident,
    Step1,
    Step2,
    Step3,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    let step = ref(1);
    // TODO - Connect to database moto siteId
    const sitesWithMoto = [34, 41, 46];
    const hasMoto = sitesWithMoto.includes(store.getters.getDefaultSite.Id);

    let dataIncident = reactive(store.getters.getIncidentEditing);
    console.log("DATAINCIDENT EDITING", dataIncident);
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });
    const nextStep = () => {
      step.value += 1;
    };
    const previousStep = () => {
      step.value -= 1;
    };
    // const setValues = (values: any) => {
    //   for (const key in values) {
    //     dataIncident[key] = values[key];
    //   }
    // };
    const editIncident = () => {
      // Check if have all the required fields
      // Then add the incident
      if (dataIncident.incident.Comments == "")
        dataIncident.incident.Comments = null;
      console.log(dataIncident);
      store
        .dispatch(Actions.UPDATE_INCIDENT, dataIncident)
        .then(() => {
          Swal.fire({
            text: t("saveSuccessfull"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-success fw-bold",
            },
          }).then(() => {
            router.push({ name: "sea-incidents" });
          });
        })
        .catch(() => {
          Swal.fire({
            text: t("errorSaveIncident"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-error fw-bold",
            },
          });
        });
    };
    onMounted(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: pageTitle,
        pageBreadcrumbPath: [],
      });
    });
    onBeforeUnmount(() => {
      store.commit(Mutations.SET_EDIT_INCIDENT, null);
    });
    return {
      pageTitle,
      step,
      nextStep,
      previousStep,
      editIncident,
      dataIncident,
      hasMoto,
    };
  },
});


import { defineComponent, ref, computed, onActivated, toRefs } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "step-3",
  emits: ["addIncident", "editIncident", "previousStep", "nextStep"],
  props: {
    incidentData: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    let theMap = ref();
    const router = useRouter();
    const store = useStore();
    const myprops = toRefs(props) as any;
    const bodyRegions = store.getters.getBodyRegions;
    const bodyRegionsParts = store.getters.getBodyRegionsParts;
    const bodyPartZones = store.getters.getBodyPartZones;

    const bodyRegionSelected = store.getters.getBodyRegionSelected;
    console.log("myprops vale:", myprops);
    let listIncidents =
      [myprops.incidentData.value.landIncident] || (null as any);
    let incidentsImagesList = ref([]) as any;
    let mode = ref(null) as any;
    let incidentsArray = ref([]) as any;
    // listIncidents = [myprops.incidentData.value.landIncident];
    if (myprops.incidentData.value.landIncident.Comments) {
      let editedBefore =
        myprops.incidentData.value.landIncident.Comments.indexOf("[Editado:");
      if (editedBefore != -1) {
        myprops.incidentData.value.landIncident.Comments =
          myprops.incidentData.value.landIncident.Comments.slice(
            0,
            editedBefore
          );
      }
    }
    setTimeout(() => {
      mode.value = "editIncident";
      incidentsArray.value = listIncidents;
    }, 200);
    const siteViewParameters = computed(() => {
      return store.getters.dataSiteViewInfo;
    });
    const mapView = computed(() => {
      return store.getters.dataMapView;
    });

    let imageData = computed(() => {
      return store.getters.dataImageBase64;
    });

    const goToNextStep = () => {
      if (bodyRegionSelected[0] !== "others") {
        myprops.incidentData.value.landIncidentAffectedBodyParts[0].Description =
          null;
      }
      emit("nextStep");
    };
    const goToPreviousStep = () => {
      if (bodyRegionSelected[0] !== "others") {
        myprops.incidentData.value.landIncidentAffectedBodyParts[0].Description =
          null;
      }
      emit("previousStep");
    };

    const modifyBody = (index) => {
      bodyRegionSelected.splice(index);
      console.log(myprops.incidentData);
      switch (index) {
        case 0:
          myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyRegionID =
            null;
          myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyPartID =
            null;
          myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyPartsZoneID =
            null;
          break;
        case 1:
          myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyPartID =
            null;
          myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyPartsZoneID =
            null;
          break;
        case 2:
          myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyPartsZoneID =
            null;
          break;
      }
    };
    const isSubmiteable = computed(() => {
      // no parts selected
      if (
        !myprops.incidentData ||
        !myprops.incidentData.value.landIncidentAffectedBodyParts
      ) {
        return false;
      }
      // 3 parts selected
      if (
        myprops.incidentData.value.landIncidentAffectedBodyParts[0]
          .BodyRegionID != null &&
        myprops.incidentData.value.landIncidentAffectedBodyParts[0]
          .BodyPartID != null &&
        myprops.incidentData.value.landIncidentAffectedBodyParts[0]
          .BodyPartsZoneID != null
      ) {
        return true;
      }
      // others + description
      if (
        bodyRegionSelected[0] === "others" &&
        myprops.incidentData.value.landIncidentAffectedBodyParts[0]
          .Description != null &&
        myprops.incidentData.value.landIncidentAffectedBodyParts[0].Description
          .length > 2
      ) {
        return true;
      }
      // 2 parts and can't continue
      console.log("bodyPartZones");
      console.log(bodyPartZones);
      console.log(
        "myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyPartID"
      );
      console.log(
        myprops.incidentData.value.landIncidentAffectedBodyParts[0].BodyPartID
      );
      if (
        myprops.incidentData.value.landIncidentAffectedBodyParts[0]
          .BodyRegionID != null &&
        myprops.incidentData.value.landIncidentAffectedBodyParts[0]
          .BodyPartID != null
      ) {
        const noMoreParts = bodyPartZones.some((part) => {
          console.log(
            part.BodyPartId ===
              myprops.incidentData.value.landIncidentAffectedBodyParts[0]
                .BodyPartID
          );
          return (
            part.BodyPartId ===
            myprops.incidentData.value.landIncidentAffectedBodyParts[0]
              .BodyPartID
          );
        });
        if (!noMoreParts) return true;
      }
      return false;
    });

    onActivated(() => {
      if (router.currentRoute.value.name === "add-land-incident") {
        store.commit(Mutations.SET_INCIDENTS_VISIBLE, true);
      }
    });

    return {
      theMap,
      siteViewParameters,
      mapView,
      myprops,
      goToNextStep,
      goToPreviousStep,
      listIncidents,
      router,
      imageData,
      incidentsImagesList,
      mode,
      incidentsArray,
      bodyRegionSelected,
      bodyRegions,
      bodyRegionsParts,
      bodyPartZones,
      modifyBody,
      isSubmiteable,
    };
  },
});

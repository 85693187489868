import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import router from "@/router";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_API_URL_DEV;
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        if (!error) {
          store.commit(Mutations.SET_ERROR, { message: ["No connection"] });
        } else if (
          error.response.status == 401 &&
          router.currentRoute.value.name != "forgot-password"
        ) {
          // console.log(router.currentRoute.value.name);
          store.dispatch(Actions.VERIFY_AUTH);
          store.commit(Mutations.SET_ERROR, error.response.data);
          router.push({ name: "sign-in" });
        } else {
          store.commit(Mutations.SET_ERROR, error.response.data);
        }
        return Promise.reject(error);
      }
    );
  }
  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static unSetHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common["Authorization"]
      ? delete ApiService.vueInstance.axios.defaults.headers.common[
          "Authorization"
        ]
      : null;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    if (slug == "omitSlug") {
      return ApiService.vueInstance.axios.get(`${resource}`);
    } else {
      return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    }
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;


import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default defineComponent({
  name: "kt-widget-12",

  props: {
    widgetClasses: String,
  },
  emits: ["clickROW"],
  setup(props, { emit }) {
    let active_row = ref(0);
    let mainTableView = ref() as any;
    const checkedRows = ref([]);
    const store = useStore();
    const list = computed(() => {
      return store.getters.dataImagesList;
    });
    const isLoading = computed(() => {
      return store.getters.imageIsLoading;
    });

    function clickTR(uuid, index) {
      active_row.value = index;
      emit("clickROW", uuid, index);
    }

    function nextRow() {
      if (active_row.value < list.value.length - 1) {
        active_row.value += 1;
        emit("clickROW", list.value[active_row.value].UUID, active_row.value);
      }
    }

    function previousRow() {
      if (active_row.value > 0) {
        active_row.value = active_row.value - 1;
        emit("clickROW", list.value[active_row.value].UUID, active_row.value);
      }
    }
    return {
      list,
      checkedRows,
      isLoading,
      active_row,
      clickTR,
      nextRow,
      previousRow,
      mainTableView,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"height":"86vh"}
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  class: "text-center p-6",
  style: {"position":"absolute","top":"10px","left":"13px"}
}
const _hoisted_4 = { class: "svg-icon svg-icon-4x svg-icon-primary" }
const _hoisted_5 = { class: "pb-lg-3 pt-7" }
const _hoisted_6 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_7 = { class: "row d-inline" }
const _hoisted_8 = { class: "text-gray-400 fw-bold fs-6 text-center" }
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = { class: "col-1 d-inline-block" }
const _hoisted_11 = { class: "col-1 d-inline-block" }
const _hoisted_12 = { class: "mt-3 mb-2" }
const _hoisted_13 = { class: "w-100" }
const _hoisted_14 = { class: "pb-10 pb-lg-3" }
const _hoisted_15 = { class: "d-flex align-items-center text-dark text-center justify-content-center font-size-h4" }
const _hoisted_16 = { class: "fv-row" }
const _hoisted_17 = { class: "row justify-content-center" }
const _hoisted_18 = { class: "col-3" }
const _hoisted_19 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "distressVictim"
}
const _hoisted_20 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_21 = { class: "d-block fw-bold text-start" }
const _hoisted_22 = { class: "text-dark fw-bolder d-block fs-4 mb-2 text-center" }
const _hoisted_23 = { class: "col-3" }
const _hoisted_24 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center",
  for: "activeVictim"
}
const _hoisted_25 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_26 = { class: "d-block fw-bold text-start" }
const _hoisted_27 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_28 = { class: "col-3" }
const _hoisted_29 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "passiveVictim"
}
const _hoisted_30 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_31 = { class: "d-block fw-bold text-start" }
const _hoisted_32 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_33 = {
  key: 0,
  class: "w-100"
}
const _hoisted_34 = { class: "pb-10 pb-lg-3" }
const _hoisted_35 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_36 = { class: "fv-row" }
const _hoisted_37 = { class: "row justify-content-center" }
const _hoisted_38 = { class: "col-2" }
const _hoisted_39 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "currents"
}
const _hoisted_40 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_41 = { class: "d-block fw-bold text-start" }
const _hoisted_42 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_43 = { class: "col-2" }
const _hoisted_44 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10",
  for: "otherCause"
}
const _hoisted_45 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_46 = { class: "d-block fw-bold text-start" }
const _hoisted_47 = { class: "text-dark fw-bolder d-block fs-4 mb-2" }
const _hoisted_48 = { class: "w-100" }
const _hoisted_49 = { class: "col-12 text-center mb-9" }
const _hoisted_50 = ["disabled"]
const _hoisted_51 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_slider = _resolveComponent("slider")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/lifeJacket.svg" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.router.currentRoute.value.name === "add-incident"
              ? _ctx.$t("regNewIncident")
              : _ctx.$t("editIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("personsSentToHospital")), 1),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", {
              class: "svg-icon svg-icon-3x svg-icon-primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (
                _ctx.myprops.incidentData.value.incident.VictimsToHospital > 0
                  ? (_ctx.myprops.incidentData.value.incident.VictimsToHospital -= 1)
                  : ''
              ))
            }, [
              _createVNode(_component_inline_svg, { src: "/media/icons/minus.svg" })
            ])
          ]),
          _createVNode(_component_slider, {
            modelValue: _ctx.myprops.incidentData.value.incident.VictimsToHospital,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.VictimsToHospital) = $event)),
            color: 
              _ctx.myprops.incidentData.value.incident.VictimsToHospital >
              _ctx.myprops.incidentData.value.incident.NPersons
                ? 'red'
                : '#278ffb'
            ,
            "track-color": "#7A7A7A",
            min: 0,
            max: _ctx.myprops.incidentData.value.incident.NPersons,
            step: 1,
            width: "400px",
            height: 12,
            style: {"margin":"0 auto"},
            class: "col-10 d-inline-block align-middle"
          }, null, 8, ["modelValue", "color", "max"]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", {
              class: "svg-icon svg-icon-3x svg-icon-primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (
                _ctx.myprops.incidentData.value.incident.VictimsToHospital >=
                _ctx.myprops.incidentData.value.incident.NPersons
                  ? ''
                  : (_ctx.myprops.incidentData.value.incident.VictimsToHospital += 1)
              ))
            }, [
              _createVNode(_component_inline_svg, { src: "/media/icons/plus.svg" })
            ])
          ]),
          _createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.myprops.incidentData.value.incident.VictimsToHospital) + " " + _toDisplayString(_ctx.myprops.incidentData.value.incident.VictimsToHospital == 1
                ? _ctx.$t("person")
                : _ctx.$t("persons")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("h5", _hoisted_15, _toDisplayString(_ctx.$t("typeVictim")), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              id: "distressVictim",
              value: 1,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.VictimTypeID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.VictimTypeID]
            ]),
            _createElementVNode("label", _hoisted_19, [
              _createElementVNode("span", _hoisted_20, [
                _createVNode(_component_inline_svg, { src: "media/icons/distress.svg" })
              ]),
              _createElementVNode("span", _hoisted_21, [
                _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("distressVictim")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              value: 2,
              id: "activeVictim",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.VictimTypeID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.VictimTypeID]
            ]),
            _createElementVNode("label", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, [
                _createVNode(_component_inline_svg, { src: "media/icons/active.svg" })
              ]),
              _createElementVNode("span", _hoisted_26, [
                _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t("activeVictim")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "btn-check",
              id: "passiveVictim",
              value: 3,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.VictimTypeID) = $event))
            }, null, 512), [
              [_vModelRadio, _ctx.myprops.incidentData.value.incident.VictimTypeID]
            ]),
            _createElementVNode("label", _hoisted_29, [
              _createElementVNode("span", _hoisted_30, [
                _createVNode(_component_inline_svg, { src: "media/icons/passive.svg" })
              ]),
              _createElementVNode("span", _hoisted_31, [
                _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t("passiveVictim")), 1)
              ])
            ])
          ]),
          _createVNode(_component_ErrorMessage, { name: "accountType" })
        ])
      ])
    ]),
    (_ctx.hasMoto)
      ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("h5", _hoisted_35, _toDisplayString(_ctx.$t("motoInvolved")), 1)
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  class: "btn-check",
                  id: "currents",
                  value: 1,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.JetSkiInvolved) = $event))
                }, null, 512), [
                  [_vModelRadio, _ctx.myprops.incidentData.value.incident.JetSkiInvolved]
                ]),
                _createElementVNode("label", _hoisted_39, [
                  _createElementVNode("span", _hoisted_40, [
                    _createVNode(_component_inline_svg, { src: "media/icons/jetski.svg" })
                  ]),
                  _createElementVNode("span", _hoisted_41, [
                    _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t("yes")), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  class: "btn-check",
                  id: "otherCause",
                  value: 0,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.myprops.incidentData.value.incident.JetSkiInvolved) = $event))
                }, null, 512), [
                  [_vModelRadio, _ctx.myprops.incidentData.value.incident.JetSkiInvolved]
                ]),
                _createElementVNode("label", _hoisted_44, [
                  _createElementVNode("span", _hoisted_45, [
                    _createVNode(_component_inline_svg, { src: "media/icons/disabled.svg" })
                  ]),
                  _createElementVNode("span", _hoisted_46, [
                    _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t("no")), 1)
                  ])
                ])
              ]),
              _createVNode(_component_ErrorMessage, { name: "accountType" })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_48, [
      _createElementVNode("div", _hoisted_49, [
        _createElementVNode("button", {
          class: "btn btn-lg btn-dark w-20 mb-5 me-15",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args)))
        }, _toDisplayString(_ctx.$t("goBack")), 1),
        _createElementVNode("button", {
          type: "submit",
          ref: "submitButton",
          id: "kt_sign_in_submit",
          class: "btn btn-lg btn-primary w-25 mb-5",
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.goToNextStep && _ctx.goToNextStep(...args))),
          disabled: 
            _ctx.myprops.incidentData.value.incident.VictimsToHospital >
              _ctx.myprops.incidentData.value.incident.NPersons ||
            (!_ctx.hasMoto &&
              _ctx.myprops.incidentData.value.incident.VictimTypeID == null) ||
            (_ctx.hasMoto &&
              (_ctx.myprops.incidentData.value.incident.VictimTypeID == null ||
                _ctx.myprops.incidentData.value.incident.JetSkiInvolved == null))
          
        }, [
          _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.$t("continue")), 1)
        ], 8, _hoisted_50)
      ])
    ])
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolbarCurrents = _resolveComponent("ToolbarCurrents")!
  const _component_MapComponentOL = _resolveComponent("MapComponentOL")!
  const _component_CurrentsTable = _resolveComponent("CurrentsTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.subheaderDisplay)
      ? (_openBlock(), _createBlock(_component_ToolbarCurrents, {
          title: _ctx.pageTitle,
          onApplyfilter: _ctx.loadData,
          key: "toolbarCurrents",
          ref: "theToolbar"
        }, null, 8, ["title", "onApplyfilter"]))
      : _createCommentVNode("", true),
    (_ctx.currentsField)
      ? (_openBlock(), _createBlock(_component_MapComponentOL, {
          mapid: "map1",
          ref: "theMap",
          key: 0,
          currentsDict: _ctx.currentsField,
          speedColors: _ctx.speedColors,
          speedThresholds: _ctx.speedThresholds,
          worstDirection: _ctx.worstDirectionCartesian,
          siteViewParameters: _ctx.siteViewParameters,
          onViewChanged: _ctx.setMapView,
          mapViewToSet: _ctx.mapView,
          imageIsLoading: _ctx.imageIsLoading,
          showButtons: true,
          onPreviousRow: _ctx.previousRow,
          onNextRow: _ctx.nextRow
        }, null, 8, ["currentsDict", "speedColors", "speedThresholds", "worstDirection", "siteViewParameters", "onViewChanged", "mapViewToSet", "imageIsLoading", "onPreviousRow", "onNextRow"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CurrentsTable, {
      onClickROW: _ctx.clickROW,
      ref: "tableView"
    }, null, 8, ["onClickROW"])
  ], 64))
}

import { defineComponent, computed, toRefs, ref } from "vue";
import FilterIncidents from "@/components/filters/FilterIncidents.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useStore } from "vuex";

export default defineComponent({
  name: "toolbar-incidents",
  props: {
    breadcrumbs: Array,
    title: String,
    statusShowAll: Boolean,
  },
  components: {
    FilterIncidents,
  },
  emits: ["changeShowAll", "applyfilter"],
  setup(props, { emit }) {
    let store = useStore();
    const myprops = toRefs(props);
    let dateFilter = ref(null) as any;
    let theFilter = ref();
    const propageFilters = (filter) => {
      // console.log("Segundo componente");
      // console.log(filter);
      dateFilter.value = filter;
      emit("applyfilter", filter);
    };

    const changeShowAll = () => {
      emit("changeShowAll");
    };
    const canWrite = computed(() => {
      return store.getters.getDefaultSite.CanWrite;
    });

    const incidentsList = computed(() => {
      return store.getters.dataincidentsList;
    });

    function setFilterDate(arrayDates) {
      // Filter forced by insertion of incident
      if (arrayDates[0].getDate() == 1 && arrayDates[0].getMonth() == 0) {
        dateFilter.value = null;
      } else {
        dateFilter.value = {
          startDate: arrayDates[0],
          endDate: arrayDates[1],
        };
        theFilter.value.setFilterDate(arrayDates);
      }
    }
    return {
      toolbarWidthFluid,
      canWrite,
      propageFilters,
      changeShowAll,
      incidentsList,
      myprops,
      dateFilter,
      theFilter,
      setFilterDate,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18ae2cda"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card-body py-3",
  style: {"height":"32vh"}
}
const _hoisted_2 = { class: "table-responsive tableFixHead" }
const _hoisted_3 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_4 = { style: {"position":"sticky","top":"0"} }
const _hoisted_5 = { class: "fw-bolder text-muted" }
const _hoisted_6 = { class: "min-w-100px" }
const _hoisted_7 = { style: {"margin-left":"15px"} }
const _hoisted_8 = { class: "min-w-140px" }
const _hoisted_9 = { class: "min-w-120px" }
const _hoisted_10 = ["onClick", "id"]
const _hoisted_11 = { style: {"border-radius":"10px 0 0 10px"} }
const _hoisted_12 = {
  style: {"margin-left":"15px"},
  class: "text-dark fw-bolder fs-6"
}
const _hoisted_13 = { class: "text-dark fw-bolder d-block mb-1 fs-6" }
const _hoisted_14 = { style: {"border-radius":"0 10px 10px 0"} }
const _hoisted_15 = { class: "text-dark fw-bolder d-block mb-1 fs-6" }
const _hoisted_16 = {
  key: 0,
  class: "text-center fw-bolder",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"]),
    ref: "mainTableView"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", _hoisted_4, [
            _createElementVNode("tr", _hoisted_5, [
              _createElementVNode("th", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("date")), 1)
              ]),
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("waveHeight")), 1),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("waterLevel")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: index,
                class: _normalizeClass(_ctx.active_row == index ? 'activeRow' : ''),
                style: _normalizeStyle(index % 2 ? 'background-color:#dff5ff' : ''),
                onClick: ($event: any) => (
                  _ctx.isLoading || _ctx.active_row == index
                    ? ''
                    : _ctx.clickTR(item['UUID'], index)
                ),
                id: _ctx.active_row == index ? 'activeRow' : ''
              }, [
                _createElementVNode("td", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(new Date(item.DateTime).toLocaleString("es-ES", {
                        timeZone: "Europe/Madrid",
                      })), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(item.Hs + " m"), 1)
                ]),
                _createElementVNode("td", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(item.WaterLevel + " m"), 1)
                ])
              ], 14, _hoisted_10))
            }), 128))
          ])
        ]),
        (_ctx.list.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t("noResultsDates")), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}

import { defineComponent, computed, ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";

export default defineComponent({
  name: "filterMetocean",
  props: ["startDate", "endDate"],
  emits: ["applyfilter"],
  components: {
    Datepicker,
  },
  setup(props, { emit }) {
    let filterDate = ref([]);
    let actualDate = ref([]) as any;
    let haveFilter = ref(false) as any;
    const store = useStore();
    const minDate = computed(() => {
      return store.getters.getMinDate;
    });
    const maxDate = computed(() => {
      return calculateMaxDate(store.getters.getMaxDate);
    });
    function setFilters() {
      // console.log("Llamando a setFilters");
      // console.log(filterDate.value);
      let startDateZeroTime = new Date(filterDate.value[0]);
      // startDateZeroTime.setDate(startDateZeroTime.getDate());
      // startDateZeroTime.setHours(0, 0, 0, 0);
      // startDateZeroTime.setTime(
      //   startDateZeroTime.getTime() -
      //     startDateZeroTime.getTimezoneOffset() * 60 * 1000
      // );
      let endDateZeroTime = new Date(filterDate.value[1]);
      // endDateZeroTime.setDate(endDateZeroTime.getDate() + 1);
      // endDateZeroTime.setHours(0, 0, 0, 0);
      // endDateZeroTime.setTime(
      //   endDateZeroTime.getTime() -
      //     endDateZeroTime.getTimezoneOffset() * 60 * 1000
      // );
      // console.log(startDateZeroTime);
      // console.log(endDateZeroTime);

      emit("applyfilter", {
        startDate: startDateZeroTime,
        endDate: endDateZeroTime,
      });
      actualDate.value = filterDate.value;
      haveFilter.value = true;
    }
    function resetFilters() {
      filterDate.value = [];
      haveFilter.value = false;
      actualDate.value = [];
      emit("applyfilter", null);
      // console.log(filterDate.value);
    }
    const calculateMaxDate = (maxDate) => {
      if (maxDate == null) {
        maxDate = new Date();
      } else {
        maxDate = new Date(maxDate);
        maxDate > new Date() ? (maxDate = new Date()) : maxDate;
      }
      maxDate.setDate(maxDate.getDate() + 3);
      return maxDate;
    };
    function setFilterDate(arrayDates) {
      // Filter forced by insertion of incident
      filterDate.value = arrayDates;
      actualDate.value = arrayDates;
      // Remove all filters
    }
    return {
      filterDate,
      resetFilters,
      minDate,
      maxDate,
      setFilters,
      haveFilter,
      actualDate,
      setFilterDate,
    };
  },
});


import { defineComponent, ref, toRefs } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "step-5",
  emits: ["addIncident", "editIncident", "previousStep"],
  props: {
    incidentData: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const myprops = toRefs(props) as any;
    let listIncidents =
      [myprops.incidentData.value.landIncident] || (null as any);
    let incidentsImagesList = ref([]) as any;
    let mode = ref(null) as any;
    let incidentsArray = ref([]) as any;
    let otherDescription = ref(null) as any;
    const landIncidentActions = myprops.incidentData.value.landIncidentActions;
    landIncidentActions.forEach((el) => {
      if (el.LandIncidentActionTypeID === 3)
        otherDescription.value = el.Description;
    });
    const auxList = ref([]) as any;
    landIncidentActions.forEach((el) => {
      auxList.value.push({
        Id: el.LandIncidentActionTypeID,
      });
    });
    if (myprops.incidentData.value.landIncident.Comments) {
      let editedBefore =
        myprops.incidentData.value.landIncident.Comments.indexOf("[Editado:");
      if (editedBefore != -1) {
        myprops.incidentData.value.landIncident.Comments =
          myprops.incidentData.value.landIncident.Comments.slice(
            0,
            editedBefore
          );
      }
    }
    setTimeout(() => {
      mode.value = "editIncident";
      incidentsArray.value = listIncidents;
    }, 200);
    const store = useStore();
    const actionsList = store.getters.getLandActionTypes;

    const goToPreviousStep = () => {
      emit("previousStep");
    };

    const saveIncident = () => {
      emit("addIncident");
    };
    const editIncident = () => {
      // Crear un objeto con los elementos de lista2 indexados por ID
      const mergedList = auxList.value
        .map((el) => {
          const elementFound = landIncidentActions.find(
            (element) => element.LandIncidentActionTypeID === el.Id
          );
          if (elementFound) {
            if (elementFound.LandIncidentActionTypeID === 3) {
              elementFound.Description = otherDescription.value;
            }
            return elementFound;
          } else {
            return {
              LandIncidentActionTypeID: el.Id,
              Description: el.Id === 3 ? otherDescription.value : null,
            };
          }
        })
        .filter((item) => item);
      myprops.incidentData.value.landIncidentActions = mergedList;
      emit("editIncident");
    };
    return {
      goToPreviousStep,
      listIncidents,
      router,
      incidentsImagesList,
      mode,
      incidentsArray,
      actionsList,
      saveIncident,
      editIncident,
      auxList,
      otherDescription,
    };
  },
});

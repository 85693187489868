
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "land-incidents-table",
  props: {
    widgetClasses: String,
  },
  emits: ["clickROW"],
  setup(props, { emit }) {
    // show page loading
    let active_row = ref(0);
    const router = useRouter();
    const checkedRows = ref([]);
    const store = useStore();
    const causes = computed(() => {
      return store.getters.getCauseLandIncidents;
    });
    const types = computed(() => {
      return store.getters.getTypeLandIncidents;
    });

    const list = computed(() => {
      return store.getters.dataLandIncidentsList;
    });
    const isLoading = computed(() => {
      return store.getters.imageIsLoading;
    });
    const getTextCause = (causeID) => {
      if (causes.value)
        return causes.value.filter((cause) => cause.Id == causeID)[0]?.Name;
      return "checkError";
    };
    const getTextType = (typeID) => {
      if (types.value)
        return types.value.filter((type) => type.Id == typeID)[0]?.Name;
      return "checkError";
    };

    function clickTR(ImageUUID, index) {
      active_row.value = index;
      emit("clickROW", ImageUUID, active_row);
    }

    function nextRow() {
      if (active_row.value < list.value.length - 1) {
        active_row.value += 1;
        emit("clickROW", list.value[active_row.value].ImageUUID, active_row);
      }
    }

    function previousRow() {
      if (active_row.value > 0) {
        active_row.value = active_row.value - 1;
        emit("clickROW", list.value[active_row.value].ImageUUID, active_row);
      }
    }

    function setRow(numberRow) {
      if (
        numberRow != null &&
        numberRow < list.value.length &&
        numberRow >= 0
      ) {
        active_row.value = numberRow;
        // console.log(list.value[numberRow]);
        emit("clickROW", list.value[active_row.value].ImageUUID, active_row);
      }
    }
    function resolveName(value, names) {
      if (!names) return;
      return names.filter((name) => name.Id == value)[0]?.Name;
    }
    const editIncident = async (item) => {
      const extraIncident = await store.dispatch(
        Actions.GET_LAND_EXTRA_INCIDENT,
        {
          siteId: store.getters.getDefaultSite.Id,
          landIncidentId: item.Id,
        }
      );
      store.commit(
        Mutations.SET_EDIT_LAND_INCIDENT,
        ref({
          siteId: store.getters.getDefaultSite.Id,
          landIncident: item,
          landIncidentAffectedBodyParts:
            extraIncident.landIncidentAffectedBodyParts,
          landIncidentActions: extraIncident.landIncidentActions,
        })
      );
      // Add the body regions to the array with
      if (extraIncident.landIncidentAffectedBodyParts.length > 0) {
        store.commit(
          Mutations.SET_BODY_REGION_SELECTED,
          [
            extraIncident.landIncidentAffectedBodyParts[0].BodyRegionID
              ? resolveName(
                  extraIncident.landIncidentAffectedBodyParts[0].BodyRegionID,
                  store.getters.getBodyRegions
                )
              : null,
            extraIncident.landIncidentAffectedBodyParts[0].BodyPartID
              ? resolveName(
                  extraIncident.landIncidentAffectedBodyParts[0].BodyPartID,
                  store.getters.getBodyRegionsParts
                )
              : null,
            extraIncident.landIncidentAffectedBodyParts[0].BodyPartsZoneID
              ? resolveName(
                  extraIncident.landIncidentAffectedBodyParts[0]
                    .BodyPartsZoneID,
                  store.getters.getBodyPartZones
                )
              : null,
          ].filter((el) => el)
        );
      }
      router.push({ name: "edit-land-incident" });
    };
    return {
      list,
      checkedRows,
      isLoading,
      getTextCause,
      getTextType,
      clickTR,
      active_row,
      nextRow,
      previousRow,
      setRow,
      editIncident,
      causes,
      types,
    };
  },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolbarIncidents = _resolveComponent("ToolbarIncidents")!
  const _component_MapComponentOL = _resolveComponent("MapComponentOL")!
  const _component_IncidentsTable = _resolveComponent("IncidentsTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.subheaderDisplay)
      ? (_openBlock(), _createBlock(_component_ToolbarIncidents, {
          key: 0,
          title: _ctx.pageTitle,
          onApplyfilter: _ctx.loadData,
          onChangeShowAll: _ctx.changeShowAll,
          statusShowAll: _ctx.showAll,
          ref: "theToolbar"
        }, null, 8, ["title", "onApplyfilter", "onChangeShowAll", "statusShowAll"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_MapComponentOL, {
      mapid: "map1",
      ref: "theMap",
      key: 0,
      imageDict: _ctx.imageData,
      incidentsArray: _ctx.incidentsList,
      selectedIncidentIdProp: _ctx.selectedIncidentId,
      siteViewParameters: _ctx.siteViewParameters,
      onViewChanged: _ctx.setMapView,
      mapViewToSet: _ctx.mapView,
      imageIsLoading: _ctx.imageIsLoading,
      showButtons: true,
      showImage: _ctx.showImage,
      onPreviousRow: _ctx.previousRow,
      onNextRow: _ctx.nextRow,
      onSelectIncidentClick: _ctx.incidentClicked,
      currentSelectMode: _ctx.mode,
      infoSelectedIncident: _ctx.infoSelectedIncident
    }, null, 8, ["imageDict", "incidentsArray", "selectedIncidentIdProp", "siteViewParameters", "onViewChanged", "mapViewToSet", "imageIsLoading", "showImage", "onPreviousRow", "onNextRow", "onSelectIncidentClick", "currentSelectMode", "infoSelectedIncident"])),
    _createVNode(_component_IncidentsTable, {
      onClickROW: _ctx.clickROW,
      ref: "tableView"
    }, null, 8, ["onClickROW"])
  ], 64))
}
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import Currents from "@/views/Currents.vue";
import Incidents from "@/views/crafted/sea-incidents/Incidents.vue";
import LandIncidents from "@/views/crafted/land-incidents/Incidents.vue";
import Images from "@/views/crafted/images/Images.vue";
import Metocean from "@/views/crafted/metocean/Metocean.vue";
import Settings from "@/views/crafted/settings/Settings.vue";
import AddIncident from "@/views/crafted/sea-incidents/AddIncident.vue";
import EditIncident from "@/views/crafted/sea-incidents/EditIncident.vue";
import AddLandIncidents from "@/views/crafted/land-incidents/AddIncident.vue";
import EditLandIncident from "@/views/crafted/land-incidents/EditIncident.vue";
import ForgotPassword from "@/views/crafted/authentication/basic-flow/ForgotPassword.vue";
import RecoverPassword from "@/views/crafted/authentication/basic-flow/RecoverPassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/currents",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/currents",
        name: "currents",
        component: Currents,
      },
      {
        path: "/incidents",
        name: "sea-incidents",
        component: Incidents,
      },
      {
        path: "/incidents/add",
        name: "add-incident",
        component: AddIncident,
      },
      {
        path: "/incidents/edit",
        name: "edit-incident",
        component: EditIncident,
      },
      {
        path: "/land-incidents",
        name: "land-incidents",
        component: LandIncidents,
      },
      {
        path: "/land-incidents/add",
        name: "add-land-incident",
        component: AddLandIncidents,
      },
      {
        path: "/land-incidents/edit",
        name: "edit-land-incident",
        component: EditLandIncident,
      },
      {
        path: "/settings",
        name: "account-settings",
        component: Settings,
      },
      {
        path: "/images",
        name: "images",
        component: Images,
      },
      {
        path: "/metocean",
        name: "metocean",
        component: Metocean,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
      },
      {
        path: "/recover-password",
        name: "recover-password",
        component: RecoverPassword,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
  // console.log(store.getters.isUserAuthenticated);
  // console.log(to.name);
  if (
    !store.getters.isUserAuthenticated &&
    to.name != "sign-in" &&
    to.name != "forgot-password" &&
    to.name != "recover-password"
  ) {
    router.push({ name: "sign-in" });
  } else if (
    store.getters.isUserAuthenticated &&
    (to.name == "sign-in" ||
      to.name == "forgot-password" ||
      to.name == "recover-password")
  ) {
    router.push({ name: "currents" });
  } else if (to.name == "edit-incident" && !store.getters.getIncidentEditing) {
    router.push({ name: "sea-incidents" });
  } else if (
    to.name == "edit-land-incident" &&
    !store.getters.getLandIncidentEditing
  ) {
    router.push({ name: "land-incidents" });
  }

  next();
  // Scroll page to top on every route change
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
});

export default router;

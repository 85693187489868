import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"height":"86vh"}
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  class: "text-center p-6",
  style: {"position":"absolute","top":"10px","left":"13px"}
}
const _hoisted_4 = { class: "svg-icon svg-icon-4x svg-icon-primary" }
const _hoisted_5 = { class: "pb-lg-3 pt-7" }
const _hoisted_6 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_7 = { class: "row d-inline" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "mt-3 mb-2" }
const _hoisted_10 = { class: "w-100 pb-10" }
const _hoisted_11 = { class: "pt-4 pb-10 text-start" }
const _hoisted_12 = { class: "fw-bolder d-flex align-items-center text-dark text-center justify-content-center" }
const _hoisted_13 = { class: "fv-row justify-content-center" }
const _hoisted_14 = { class: "row px-10" }
const _hoisted_15 = ["id", "value"]
const _hoisted_16 = ["for"]
const _hoisted_17 = { class: "d-block fw-bold text-start" }
const _hoisted_18 = { class: "text-dark fw-bolder d-block fs-4 text-center" }
const _hoisted_19 = {
  key: 0,
  class: "row justify-content-center px-10 mb-10"
}
const _hoisted_20 = { class: "fv-row" }
const _hoisted_21 = { class: "row justify-content-center" }
const _hoisted_22 = { class: "col-10" }
const _hoisted_23 = ["placeholder"]
const _hoisted_24 = { class: "w-100" }
const _hoisted_25 = { class: "card mb-10" }
const _hoisted_26 = { class: "col-12 text-center mt-4" }
const _hoisted_27 = ["disabled"]
const _hoisted_28 = ["disabled"]
const _hoisted_29 = { class: "indicator-label" }
const _hoisted_30 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/landscape.svg" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.router.currentRoute.value.name === "add-land-incident"
              ? _ctx.$t("regNewIncidentLand")
              : _ctx.$t("editIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t("others")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.$t("indicateOthers")), 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionsList, (action, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(`col-6 ${index % 2 ? '' : 'text-end'} mb-10`),
              key: action.Id
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                id: action.Id,
                value: { Id: action.Id },
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.auxList) = $event))
              }, null, 8, _hoisted_15), [
                [_vModelCheckbox, _ctx.auxList]
              ]),
              _createElementVNode("label", {
                class: "form-check-label mx-4",
                for: action.Id
              }, [
                _createElementVNode("span", _hoisted_17, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t(`actions.${action.Name}`).toUpperCase()), 1)
                ])
              ], 8, _hoisted_16)
            ], 2))
          }), 128))
        ])
      ])
    ]),
    (_ctx.auxList.filter((el) => el.Id === 3).length === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _withDirectives(_createElementVNode("textarea", {
                  type: "textarea",
                  class: "form-control",
                  placeholder: _ctx.$t('indicateAdditionalActions'),
                  name: "observations",
                  id: "comments",
                  style: {"height":"100px"},
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.otherDescription) = $event))
                }, null, 8, _hoisted_23), [
                  [_vModelText, _ctx.otherDescription]
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("button", {
            class: "btn btn-lg btn-dark w-20 mb-5 me-15",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args))),
            disabled: _ctx.isLoading
          }, _toDisplayString(_ctx.$t("goBack")), 9, _hoisted_27),
          _createElementVNode("button", {
            class: "btn btn-lg btn-primary w-25 mb-5",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (
              _ctx.router.currentRoute.value.name === 'add-land-incident'
                ? _ctx.saveIncident()
                : _ctx.editIncident()
            )),
            disabled: 
              (_ctx.auxList.filter((el) => el.Id === 3).length === 1 &&
                !_ctx.otherDescription) ||
              _ctx.isLoading
            
          }, [
            _createElementVNode("span", _hoisted_29, [
              _createTextVNode(_toDisplayString(_ctx.router.currentRoute.value.name === "add-land-incident"
                  ? _ctx.$t("saveIncident")
                  : _ctx.$t("saveChanges")) + " ", 1),
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_30))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_28)
        ])
      ])
    ])
  ]))
}
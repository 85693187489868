
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default defineComponent({
  name: "kt-widget-12",

  props: {
    widgetClasses: String,
  },
  emits: ["clickROW"],
  setup(props, { emit }) {
    const checkedRows = ref([]);
    const store = useStore();
    const list = computed(() => {
      return store.getters.dataCurrentsList;
    });
    let active_row = ref(0);

    function clickTR(uuid, index) {
      active_row.value = index;
      emit("clickROW", uuid, active_row.value);
      moveToSelectedPosition(active_row.value);
    }

    function nextRow() {
      if (active_row.value < list.value.length - 1) {
        active_row.value += 1;
        emit("clickROW", list.value[active_row.value].UUID, active_row.value);
        moveToSelectedPosition(active_row.value);
      }
    }

    function previousRow() {
      if (active_row.value > 0) {
        active_row.value = active_row.value - 1;
        emit("clickROW", list.value[active_row.value].UUID, active_row.value);
        moveToSelectedPosition(active_row.value);
      }
    }

    function moveToSelectedPosition(index) {
      if (index != 0)
        document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")
          [index - 3]?.scrollIntoView({ behavior: "smooth" });
      else
        document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")[0]
          .scrollIntoView({ block: "end" });
    }
    let isLoading = computed(() => {
      return store.getters.imageIsLoading;
    });
    return {
      list,
      checkedRows,
      isLoading,
      clickTR,
      active_row,
      nextRow,
      previousRow,
    };
  },
});


import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  onActivated,
} from "vue";
import ToolbarAddIncident from "@/layout/toolbar/land-incidents/ToolbarAddIncident.vue";
import Step1 from "@/views/crafted/land-incidents/Step1.vue";
import Step2 from "@/views/crafted/land-incidents/Step2.vue";
import Step3 from "@/views/crafted/land-incidents/Step3.vue";
import Step4 from "@/views/crafted/land-incidents/Step4.vue";
import Step5 from "@/views/crafted/land-incidents/Step5.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "add-land-incident",
  components: {
    ToolbarAddIncident,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);
    let step = ref(1);
    // TODO - Connect to database moto siteId
    let dataLandIncident = reactive({
      siteId: store.getters.getDefaultSite.Id,
      landIncident: {
        SiteID: store.getters.getDefaultSite.Id,
        VictimName: null,
        VictimGender: null,
        VictimAge: null,
        LandIncidentCauseID: null,
        LandIncidentTypeID: null,
        OccurrenceDateTime: new Date(),
        X: null,
        Y: null,
        UUID: null,
        LandIncidentTypeDescription: null,
        LandIncidentCauseDescription: null,
      },
      landIncidentAffectedBodyParts: [
        {
          BodyRegionID: null,
          BodyPartID: null,
          BodyPartsZoneID: null,
          Description: null,
        },
      ],
      landIncidentActions: [],
    });
    store.commit(Mutations.SET_BODY_REGION_SELECTED, []);
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });
    const nextStep = () => {
      step.value += 1;
    };
    const previousStep = () => {
      step.value -= 1;
    };
    const goToStep = (pageStep: number) => {
      step.value = pageStep;
    };
    const addIncident = () => {
      isLoading.value = true;
      if (dataLandIncident.landIncident.LandIncidentTypeDescription == "")
        dataLandIncident.landIncident.LandIncidentTypeDescription = null;

      if (dataLandIncident.landIncident.LandIncidentCauseDescription == "")
        dataLandIncident.landIncident.LandIncidentTypeDescription = null;

      if (dataLandIncident.landIncidentAffectedBodyParts[0].Description == "")
        dataLandIncident.landIncidentAffectedBodyParts[0].Description = null;

      store
        .dispatch(Actions.ADD_NEW_LAND_INCIDENT, dataLandIncident)
        .then(() => {
          store.commit(
            Mutations.SET_LAST_DATE_INCIDENT_ADDED,
            dataLandIncident.landIncident.OccurrenceDateTime
          );
          Swal.fire({
            text: t("saveSuccessfull"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-success fw-bold",
            },
          }).then(() => {
            router.push({ name: "land-incidents" });
            isLoading.value = false;
          });
        })
        .catch(() => {
          Swal.fire({
            text: t("errorSaveIncident"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("ok"),
            customClass: {
              confirmButton: "btn btn-error fw-bold",
            },
          }).then(() => {
            isLoading.value = false;
          });
        });
    };
    onActivated(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: "addNewlandIncident",
      });
    });
    onMounted(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: pageTitle,
        pageBreadcrumbPath: [],
      });
    });
    return {
      pageTitle,
      step,
      nextStep,
      previousStep,
      goToStep,
      addIncident,
      dataLandIncident,
      isLoading,
    };
  },
});

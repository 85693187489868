
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "recover-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const passwordFormDisplay = ref(false);
    console.log(route.query.email);
    console.log(route.query.key);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);
    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });
    const changePassword = Yup.object().shape({
      newPassword: Yup.string().min(6).required().label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const updatePassword = async (values) => {
      console.log("Actualizando password");
      console.log(updatePasswordButton);
      if (updatePasswordButton.value) {
        // Activate indicator
        console.log("INICIANDO PROCESO");
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
        console.log(values);
        if (values["newPassword"] == values["confirmpassword"]) {
          values["email"] = route.query.email;
          values["key"] = route.query.key;
          delete values["confirmpassword"];
          console.log(values);
          await store.dispatch(Actions.CHANGE_FORGOTTEN_PASSWORD, values);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];

          if (!error) {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: t("passwordChangedSuccessfully"),
              icon: "success",
              confirmButtonText: t("ok"),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            }).then(() => {
              router.push("/sign-in");
            });
          } else {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t("errorPassword"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        }
      }
    };

    onMounted(() => {
      if (route.query.email && route.query.key) {
        console.log(route);
        store.dispatch(Actions.CHECK_TOKEN_VALIDITY, {
          email: route.query.email,
          key: route.query.key,
        });
      } else {
        router.push("/sign-in");
      }
    });

    return {
      updatePassword,
      forgotPassword,
      submitButton,
      passwordFormDisplay,
      changePassword,
      updatePasswordButton,
    };
  },
});

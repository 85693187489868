<template>
  <div class="card mb-5">
    <!--begin::Body-->
    <div class="card-body py-1" id="containerMap">
      <div
        v-if="infoSelectedIncident != null"
        class="row"
        style="
          background: black;
          color: white;
          padding: 2px;
          font-size: 15px;
          border-radius: 25px;
          position: absolute;
          z-index: 1;
          width: 100%;
          top: 3px;
          padding: 0;
          margin: 0;
          left: 0;
        "
      >
        <span class="date col-3 text-center">
          {{
            new Date(infoSelectedIncident.OccurrenceDateTime).toLocaleString(
              "es-ES",
              {
                timeZone: "Europe/Madrid",
              }
            )
          }}
        </span>
        <span class="type col-3 text-center">
          {{ $t("type") + " - " + $t(infoSelectedIncident.IncidentTypeID) }}
        </span>
        <span class="cause col-3 text-center">
          {{ $t("cause") + " - " + $t(infoSelectedIncident.IncidentCauseID) }}
        </span>
        <span class="persons col-3 text-center">
          {{ $t("persons") + " - " + infoSelectedIncident.NPersons }}
        </span>
      </div>
      <div
        :id="mapid"
        style="width: 100%; margin: auto"
        :style="'height: ' + (sizeHeight || '48') + 'vh'"
      ></div>
      <div
        class="spinner-border text-light"
        v-show="imageIsLoading"
        style="position: absolute; bottom: 20px; left: 40px"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div
        class="text-light"
        v-if="imageDateTimeLabel"
        style="
          position: absolute;
          bottom: 20px;
          right: 40px;
          background: black;
          padding: 2px 11px 2px 11px;
          border-radius: 25px;
          font-family: monospace;
          font-size: 15px;
        "
        role="status"
      >
        <span class="background:black;padding:4px;">{{
          imageDateTimeLabel
        }}</span>
      </div>
      <div
        v-if="
          $router.currentRoute.value.name === 'images' &&
          siteViewInfo.CanWrite >= 1000
        "
        style="position: absolute; top: 5px"
      >
        <button class="btn btn-primary btn-sm m-4" @click="setSiteView">
          {{ $t("setView") }}
        </button>
      </div>
      <div v-show="showButtons">
        <div
          style="
            position: absolute;
            top: 48%;
            left: 50px;
            border: 7px solid white;
            border-radius: 50%;
            padding: 4px;
          "
          @click="previousRow()"
        >
          <span class="svg-icon svg-icon-primary svg-icon-2x"
            ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Arrow-left.svg--><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="2" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <rect
                  fill="#FFF"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) "
                  x="11"
                  y="5"
                  width="2"
                  height="14"
                  rx="1"
                />
                <path
                  d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                  fill="#FFF"
                  fill-rule="nonzero"
                  transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "
                />
              </g>
            </svg>
          </span>
          <!--end::Svg Icon-->
        </div>
        <div
          style="
            position: absolute;
            top: 48%;
            right: 50px;
            border: 7px solid white;
            border-radius: 50%;
            padding: 4px;
          "
          @click="nextRow()"
        >
          <span class="svg-icon svg-icon-primary svg-icon-2x"
            ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Arrow-right.svg--><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <rect
                  fill="#FFF"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                  x="11"
                  y="5"
                  width="2"
                  height="14"
                  rx="1"
                />
                <path
                  d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                  fill="#FFF"
                  fill-rule="nonzero"
                  transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "
                />
              </g>
            </svg>
          </span>
          <!--end::Svg Icon-->
          <!--end::Svg Icon-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
//import {getTopLeft, getWidth, getCenter} from 'ol/extent';
//import {defaults} from 'ol/control';
import WMTSTileGrid from "ol/tilegrid/WMTS";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import Map from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
//import {Style, Icon} from 'ol/style';
import { Style, Circle, Fill, Stroke } from "ol/style";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import Static from "ol/source/ImageStatic";
import { WindLayer, Field } from "ol-wind";
import Select from "ol/interaction/Select";
import { click } from "ol/events/condition";
import WMTS from "ol/source/WMTS";
import { get as getProjection } from "ol/proj";
//import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS'
//import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import ImageLayer from "ol/layer/Image";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default {
  name: "MapComponentOL",
  emits: [
    "selectIncidentClick",
    "clickedCoordinates",
    "viewChanged",
    "previousRow",
    "nextRow",
  ],
  props: [
    "mapid",
    "imageDict",
    "currentsDict",
    "currentsData",
    "speedThresholds",
    "speedColors",
    "worstDirection",
    "incidentsArray",
    "selectedIncidentIdProp",
    "showImage",
    "showCurrents",
    "showIncidents",
    "maxLoadedImages",
    "currentSelectMode",
    "editedIncidentId",
    "mapViewToSet",
    "siteViewParameters",
    "imageIsLoading",
    "showButtons",
    "sizeHeight",
    "infoSelectedIncident",
  ],
  data() {
    return {
      mapView: null,
      map: null,
      imageLayer: null,
      //imageLayers:null,
      currentsLayer: null,
      //currentLayers:null,
      markersLayer: null,
      markersArray: null,
      newIncidentLayer: null,
      createdIncidentMarker: null,
      //selectedIncidentLayer:null,
      worstDirectionEx: 0,
      worstDirectionEy: 0,
      speedColorsR: null,
      speedColorsG: null,
      speedColorsB: null,
      speedColorsA: null,
      selectClick: null,
      selectedIncidentId: null,
      imageUUIDDict: {},
      imageLayerArray: [],
      activeImageLayer: null,
      currentInteraction: null,
      imageDateTimeLabel: null,
      t: useI18n(),
      router: useRouter(),
      store: useStore(),
    };
  },
  methods: {
    setSiteView() {
      Swal.fire({
        title: this.$t("setNewSiteView"),
        text: this.$t("infoNewSiteView"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("establish"),
        cancelButtonText: this.$t("cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          //console.log(this.siteViewParameters);
          // console.log(this.dataMapView.values_);
          this.$store
            .dispatch(Actions.SET_SITE_VIEW_INFO, {
              siteId: this.$store.getters.getDefaultSiteId,
              siteViewInfo: {
                Projection: "EPSG:25830",
                CenterX: this.map.getView().values_.center[0],
                CenterY: this.map.getView().values_.center[1],
                Zoom: this.map.getView().values_.zoom,
              },
            })
            .then(() => {
              Swal.fire(
                this.$t("established"),
                this.$t("setViewSuccessfull"),
                "success"
              );
            });
        }
      });
    },
    colorScale(aU, aV) {
      var threshold1 = 0;
      var threshold2 = 0;
      var aNormalSpeed =
        aU * this.worstDirectionEx + aV * this.worstDirectionEy;
      var nThresholds = this.speedThresholds.length;
      var theColor = "";
      var colorSet = false;

      if (aNormalSpeed <= this.speedThresholds[nThresholds - 2]) {
        theColor = this.speedColors[nThresholds - 2];
        colorSet = true;
      } else {
        for (
          var whichThreshold = nThresholds - 2;
          whichThreshold > 0;
          whichThreshold--
        ) {
          if (aNormalSpeed >= this.speedThresholds[whichThreshold - 1])
            continue;
          else {
            //Está en este intervalo
            threshold1 = this.speedThresholds[whichThreshold];
            threshold2 = this.speedThresholds[whichThreshold - 1];
            const r1 = this.speedColorsR[whichThreshold];
            const r2 = this.speedColorsR[whichThreshold - 1];
            const g1 = this.speedColorsG[whichThreshold];
            const g2 = this.speedColorsG[whichThreshold - 1];
            const b1 = this.speedColorsB[whichThreshold];
            const b2 = this.speedColorsB[whichThreshold - 1];
            const a1 = this.speedColorsA[whichThreshold];
            const a2 = this.speedColorsA[whichThreshold - 1];
            const aFactor =
              (aNormalSpeed - threshold1) / (threshold2 - threshold1);

            const r = Math.floor(r1 + (r2 - r1) * aFactor);
            const g = Math.floor(g1 + (g2 - g1) * aFactor);
            const b = Math.floor(b1 + (b2 - b1) * aFactor);
            const a = Math.floor(a1 + (a2 - a1) * aFactor);
            theColor = "rgba(" + r + "," + g + "," + b + "," + a + ")";
            colorSet = true;
            break;
          }
        }
      }

      if (!colorSet)
        //Si no se le ha dado color es porque es el máximo.
        theColor = this.speedColors[0];
      else {
        if (
          Math.sqrt(aU * aU + aV * aV) > this.speedThresholds[nThresholds - 1]
        )
          theColor = this.speedColors[nThresholds - 1];
      }
      return theColor;
    },
    // showImageMethod(e) {
    //     // if(e != null){
    //     //     this.$emit("click",e.coordinate);
    //     //     console.log(e.coordinate);
    //     // }
    //     // else
    //     // {
    //     if(this.imageLayer == null){
    //     var wld = this.imageDict['imageRef'];
    //     var xMin = wld['x0'];
    //     var yMax = wld['y0'];
    //     var xMax = xMin + wld['dx'] * wld['width'];
    //     var yMin = yMax + wld['dy'] * wld['height'];
    //     this.imageLayer = new ImageLayer({
    //         zIndex:1,
    //         source: new Static({
    //             url: "data:image/png;base64,"+this.imageDict['image'],
    //             projection: 'EPSG:25830',
    //             imageExtent: [xMin, yMin, xMax, yMax]
    //             })
    //         });
    //     this.imageLayer.set('uuid',this.imageDict['uuid'])
    //     this.map.addLayer(this.imageLayer);
    //     this.imageLayer.setOpacity(1.0)
    //     }
    //     //}
    // },
    createImageLayer(anImageDict) {
      // if(e != null){
      //     this.$emit("click",e.coordinate);
      //     console.log(e.coordinate);
      // }
      // else
      // {
      var wld = anImageDict["imageRef"];
      var xMin = wld["x0"];
      var yMax = wld["y0"];
      var xMax = xMin + wld["dx"] * wld["width"];
      var yMin = yMax + wld["dy"] * wld["height"];
      var anImageLayer = new ImageLayer({
        zIndex: 1,
        source: new Static({
          url: "data:image/png;base64," + anImageDict["image"],
          projection: "EPSG:25830",
          imageExtent: [xMin, yMin, xMax, yMax],
        }),
      });
      anImageLayer.set("uuid", anImageDict["uuid"]);
      anImageLayer.set("dateTime", new Date(anImageDict["dateTime"]));
      anImageLayer.setOpacity(1.0);
      return anImageLayer;
    },
    showWind(e) {
      if (e != null) {
        console.log(e.coordinate);
      } else {
        if (this.currentsLayer == null) {
          this.currentsLayer = new WindLayer(new Field(this.currentsDict[0]), {
            zIndex: 2,
            forceRender: true,
            windOptions: {
              velocityScale: 10, // / 15,
              paths: 2500,
              maxAge: 100,
              colorScale: this.colorScale, //this.speedThresholds==null ? "#00ff00" : this.colorScale,
              lineWidth: 2,
              frameRate: 10,
              //colorScale: "#ff0000",
              // colorScale: [
              //   "rgb(36,104, 180)",
              //   "rgb(60,157, 194)",
              //   "rgb(128,205,193 )",
              //   "rgb(151,218,168 )",
              //   "rgb(198,231,181)",
              //   "rgb(238,247,217)",
              //   "rgb(255,238,159)",
              //   "rgb(252,217,125)",
              //   "rgb(255,182,100)",
              //   "rgb(252,150,75)",
              //   "rgb(250,112,52)",
              //   "rgb(245,64,32)",
              //   "rgb(237,45,28)",
              //   "rgb(220,24,32)",
              //   "rgb(180,0,35)"
              // ],
              generateParticleOption: true,
              projection: "EPSG:25830",
            },
            // map: map,
            // projection: 'EPSG:4326'
          });

          // console.log(this.map, this.currentsLayer);
          // this.currentsLayer.set("name", "currents");
          // console.log("this.currentsLayer.get('name')");
          // console.log(this.currentsLayer.get("name"));
          this.map.addLayer(this.currentsLayer);
          this.currentsLayer.setOpacity(1.0);
        } else {
          this.currentsLayer.setData(new Field(this.currentsDict[0]));
        }
      }
    },
    // AddMarker(e){
    //   var marker = new Feature(new Point(e.coordinate));
    //   this.markersLayer.getSource().addFeature(marker);
    // },
    // clickOnIncident() {},
    setImageLayerVisible(anUUID) {
      const nImages = this.imageLayerArray.length;
      for (var whichLayerIdx = 0; whichLayerIdx < nImages; whichLayerIdx++) {
        if (anUUID == this.imageLayerArray[whichLayerIdx].get("uuid")) {
          this.imageLayerArray[whichLayerIdx].setVisible(true);
          this.imageDateTimeLabel = this.imageLayerArray[whichLayerIdx]
            .get("dateTime")
            .toLocaleString("es-ES");
        } else {
          this.imageLayerArray[whichLayerIdx].setVisible(false);
        }
      }
    },
    isImageLoaded(anUUID) {
      if (this.imageUUIDDict != null) {
        if (anUUID in this.imageUUIDDict) return true;
        else return false;
      }
      return false;
    },
    setClickMode(aMode) {
      //Este método sirve para establecer qué hacer cuando se hace click.
      //Modos disponibles:
      //-Nada: no se hace nada al hacer click
      //- Seleccionar incidente: al hacer click, se busca el incidente que se ha seleccionado y se genera un evento que incluye el id del incidente
      //- Crear incidente: al hacer click, se crea un marcador y se lanza un evento con un objeto coordinate. Si se vuelve a hacer click, se elimina el último
      //marcador, se crea otro y se vuelve a generar un evento con un objeto coordinate
      //Remove all interactions
      // console.log("Update setclickmode because aMode changed");
      this.map.removeInteraction(this.currentInteraction);
      this.currentInteraction = null;
      if (aMode == "selectIncident") {
        this.currentInteraction = this.selectClick;
      }
      if (this.currentInteraction != null)
        this.map.addInteraction(this.currentInteraction);
    },
    setClickModeLayers(aMode) {
      //Este método sirve para establecer qué se ve cuando se hace click.
      if (aMode == "selectIncident") {
        if (this.createdIncidentMarker != null) {
          this.newIncidentLayer.getSource().clear();
          this.createdIncidentMarker = null;
        }
        this.newIncidentLayer.setVisible(false);
        this.markersLayer.setVisible(true);
        //TODO: Al ponerse en modo select incident, hay que ver dos capas: la markers, con todos los incidentes,
        // y otra con el incidente seleccionado. Lo que haya en esta capa hay que gestionarlo con el método
        // setSelectedIncident
        //this.selectedIncidentLayer.setVisible(true)
      } else if (aMode == "editIncident") {
        if (this.editedIncidentId != null) {
          const nMarkers = this.markersArray.length;
          for (
            var whichMarkerIdx = 0;
            whichMarkerIdx < nMarkers;
            whichMarkerIdx++
          ) {
            if (
              this.markersArray[whichMarkerIdx].get("id") ==
              this.editedIncidentId
            ) {
              this.createdIncidentMarker = new Feature(
                this.markersArray[whichMarkerIdx].getGeometry()
              );
              this.newIncidentLayer
                .getSource()
                .addFeature(this.createdIncidentMarker);
              break;
            }
          }
        }
        this.newIncidentLayer.setVisible(true);
        this.markersLayer.setVisible(false);
        //this.selectedIncidentLayer.setVisible(false);
      }
    },
    setSelectedIncident(anIncidentId) {
      //Get the feature from the
      const nMarkers = this.markersArray.length;
      for (
        var whichMarkerIdx = 0;
        whichMarkerIdx < nMarkers;
        whichMarkerIdx++
      ) {
        if (this.markersArray[whichMarkerIdx].get("id") == this.anIncidentId) {
          if (this.anIncidentId != this.selectedIncidentId) {
            this.selectedIncidentId = anIncidentId;
            this.selectClick.getFeatures().clear();
            this.selectClick
              .getFeatures()
              .push(this.markersArray[whichMarkerIdx]);
            break;
          }
        }
      }
    },
    onMoveEnd(evt) {
      this.$emit("viewChanged", evt.map.getView());
      // console.log(evt.map.getView());
    },
    previousRow() {
      this.$emit("previousRow");
    },
    nextRow() {
      this.$emit("nextRow");
    },
    resizeMap() {
      setTimeout(() => {
        if (this.map) {
          this.map.updateSize();
        }
      }, 200);
    },
  },
  mounted() {
    // console.log("INFOSELECTED");
    const mapRef = document.getElementById(this.mapid);
    window.onresize = function () {
      setTimeout(() => {
        if (this.map) {
          this.map.updateSize();
        }
      }, 200);
    };
    proj4.defs(
      "EPSG:25830",
      "+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs"
    );
    register(proj4);

    var projectionExtent = [
      -1099679.5794465232, 2986395.7564299013, 1295283.6097877056,
      4997989.030221562,
    ];

    var matrixIds = new Array(20);
    var tileSizes = new Array(20);

    var origins = [
      [-1968157.095, 22856087],
      [-1968157.095, 12837333],
      [-1968157.095, 7827955],
      [-1968157.095, 7827955],
      [-1968157.095, 7827955],
      [-1968157.095, 7827955],
      [-1968157.095, 7514869],
      [-1968157.095, 7358326],
      [-1968157.095, 7280055],
      [-1968157.095, 7280055],
      [-1968157.095, 7280055],
      [-1968157.095, 7280055],
      [-1968157.095, 7275163],
      [-1968157.095, 7272717],
      [-1968157.095, 7271494],
      [-1968157.095, 7272105],
      [-1968157.095, 7271800],
      [-1968157.095, 7271647],
      [-1968157.095, 7271570],
      [-1968157.095, 7271532],
    ];
    //const parser = new WMTSCapabilities();
    var resolutions = [
      78271.51698, 39135.75849, 19567.879245, 9783.9396225, 4891.96981125,
      2445.984905625, 1222.9924528125, 611.49622640625, 305.748113203125,
      152.8740566015625, 76.43702830078125, 38.218514150390625,
      19.109257075195313, 9.554628537597656, 4.777314268798828,
      2.388657134399414, 1.194328567199707, 0.5971642835998535,
      0.2985821417999268, 0.1492910708999634,
    ];
    var fullTileRanges = [
      { minX: 0, maxX: 0, minY: 0, maxY: 0 },
      { minX: 0, maxX: 0, minY: 0, maxY: 0 },
      { minX: 0, maxX: 0, minY: 0, maxY: 0 },
      { minX: 0, maxX: 1, minY: 1, maxY: 1 },
      { minX: 0, maxX: 2, minY: 2, maxY: 3 },
      { minX: 1, maxX: 4, minY: 4, maxY: 7 },
      { minX: 2, maxX: 9, minY: 8, maxY: 14 },
      { minX: 5, maxX: 19, minY: 15, maxY: 27 },
      { minX: 11, maxX: 39, minY: 29, maxY: 54 },
      { minX: 22, maxX: 78, minY: 58, maxY: 109 },
      { minX: 44, maxX: 157, minY: 116, maxY: 219 },
      { minX: 88, maxX: 314, minY: 233, maxY: 438 },
      { minX: 177, maxX: 629, minY: 465, maxY: 876 },
      { minX: 355, maxX: 1258, minY: 929, maxY: 1752 },
      { minX: 710, maxX: 2516, minY: 1858, maxY: 3503 },
      { minX: 1420, maxX: 5033, minY: 3718, maxY: 7008 },
      { minX: 2840, maxX: 10066, minY: 7436, maxY: 14016 },
      { minX: 5681, maxX: 20132, minY: 14872, maxY: 28031 },
      { minX: 11362, maxX: 40265, minY: 29744, maxY: 56061 },
      { minX: 22724, maxX: 80531, minY: 59488, maxY: 112121 },
    ];
    // let options;
    const proj25830 = getProjection("EPSG:25830");

    for (var z = 0; z < 20; ++z) {
      // generate resolutions and matrixIds arrays for this WMTS
      matrixIds[z] = z; //"EPSG:25830:" + z;
      tileSizes[z] = 256;
    }

    this.selectClick = new Select({
      condition: click,
      style: new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: "rgba(255, 0, 0, 0.1)",
          }),
          stroke: new Stroke({
            color: "yellow",
            width: 2,
          }),
        }),
      }),
    });
    this.mapView =
      this.mapViewToSet == null
        ? new View({
            //projection: 'EPSG:4326',
            // projection: this.siteViewParameters["projection"], //"EPSG:25830",
            // center: this.siteViewParameters["center"], //[567626, 4793293],
            // zoom: this.siteViewParameters["zoom"], //15, MAL
            projection: this.siteViewParameters["Projection"], //"EPSG:25830",
            enableRotation: false,
            center: [
              parseFloat(this.siteViewParameters["CenterX"]),
              parseFloat(this.siteViewParameters["CenterY"]),
            ], //[567626, 4793293],
            zoom: parseFloat(this.siteViewParameters["Zoom"]), //15.XXXX,
          })
        : this.mapViewToSet;
    this.map = new Map({
      maxTilesLoading: 5,
      target: mapRef,
      layers: [
        // new TileLayer({
        //             title: "Global Imagery",
        //             source: new ol.source.TileWMS({
        //                 url: 'https://maps.opengeo.org/geowebcache/service/wms',
        //                 params: { LAYERS: 'openstreetmap', format: 'image/jpeg', VERSION: '1.1.1' }
        //             })
        //         }),
        // new TileLayer({
        //     opacity:1.0,
        //     source: new WMTS(options)
        // })
        new TileLayer({
          opacity: 1.0,
          extent: projectionExtent,
          source: new WMTS({
            //attributions: "Hola",
            url: "http://www.ign.es/wmts/pnoa-ma",
            layer: "OI.OrthoimageCoverage",
            // params: {
            //   VERSION: "1.0.0",
            // },
            //matrixSet: 'EPSG:4326',
            //matrixSet: 'EPSG:3857',
            matrixSet: "EPSG:25830",
            format: "image/jpeg",
            projection: proj25830,
            tileGrid: new WMTSTileGrid({
              origins: origins, //getTopLeft(projectionExtent),
              resolutions: resolutions,
              matrixIds: matrixIds,
              tileSizes: tileSizes,
              fullTileRanges: fullTileRanges,
            }),
            style: "default",
          }),
        }),
      ],
      controls: [],
      // controls:
      //   new ZoomToExtent({
      //     extent: [
      //       813079.7791264898, 5929220.284081122, 848966.9639063801,
      //       5936863.986909639,
      //     ],
      //   }),
      view: this.mapView,
    });
    this.map.on("moveend", this.onMoveEnd);
    this.markersLayer = new VectorLayer({
      zIndex: 2,
      source: new VectorSource(),
      // style: new Style({
      //     image: new Icon({
      //     crossOrigin: 'anonymous',
      //     anchor: [0.5, 1],
      //     src: 'icon.png'
      //     })
      //   })
      style: new Style({
        image: new Circle({
          radius: 2.5 * 2.5,
          fill: new Fill({
            color: "red",
          }),
          stroke: new Stroke({
            color: "white",
            width: 1,
          }),
        }),
        zIndex: Infinity,
      }),
    });
    this.map.addLayer(this.markersLayer);

    this.newIncidentLayer = new VectorLayer({
      zIndex: 2,
      source: new VectorSource(),
      style: new Style({
        image: new Circle({
          radius: 2.5 * 2.5,
          fill: new Fill({
            color: "yellow",
          }),
          stroke: new Stroke({
            color: "white",
            width: 1,
          }),
        }),
        zIndex: Infinity,
      }),
    });
    this.map.addLayer(this.newIncidentLayer);
    this.map.on("singleclick", (evt) => {
      // console.log(evt.coordinate);
      if (this.currentSelectMode == "selectIncident") {
        var feature = this.map.forEachFeatureAtPixel(
          evt.pixel,
          function (feature) {
            return feature;
          }
        );
        if (feature) {
          // var geometry = feature.getGeometry();
          // var coord = geometry.getCoordinates();
          //PRUEBA this.selectedIncidentId = feature.get("id");
          this.setSelectedIncident(feature.get("id"));
          //this.selectedIncidentIdProp = feature.get("id");
          this.$emit("selectIncidentClick", feature.get("id"));
          //console.info(feature.getProperties());
        }
      } else if (this.currentSelectMode == "editIncident") {
        if (this.createdIncidentMarker != null) {
          this.createdIncidentMarker.setGeometry(new Point(evt.coordinate));
        } else {
          this.createdIncidentMarker = new Feature(new Point(evt.coordinate));
          this.newIncidentLayer
            .getSource()
            .addFeature(this.createdIncidentMarker);
        }
        this.$emit("clickedCoordinates", evt.coordinate);
      } else if (this.currentSelectMode == "none") {
        this.$emit("clickedCoordinates", evt.coordinate);
      }
    });
  },
  computed: {
    siteViewInfo() {
      return this.$store.getters.getDefaultSite;
    },
    dataMapView() {
      return this.$store.getters.dataMapView;
    },
  },
  watch: {
    // imageDict: {
    //   deep: false,
    //   handler() {
    //     //Este método, cuando cambia la propiedad imageDict,
    //     //Comprueba el número de imágenes guardadas
    //     //Si se llena el buffer, borra la capa más antigua
    //     //Crea una nueva capalayer, apunta e
    //     console.log("Adding image because of data update");
    //     if(this.imageLayer != null)
    //     {
    //         this.map.removeLayer(this.imageLayer);
    //         this.imageLayer = null;
    //     }
    //     //Remove the last image layer from the map
    //     this.showImageMethod(null);
    //   },
    // },
    imageDict: {
      deep: false,
      handler() {
        // console.log("Adding image because of data update");
        //Viene un imageDict
        //Hay que comprobar el UUID
        var nImages = this.imageLayerArray.length;
        // console.log("nImages before processing imageDict: " + nImages);
        if (this.imageDict["uuid"] in this.imageUUIDDict) {
          //Activar esta imagen
          this.setImageLayerVisible(this.imageDict["uuid"]);
        } else {
          //Si la imagen no está en la lista
          if (nImages == this.maxLoadedImages) {
            //Si se llenó el buffer
            //Borrar la primera imagen del array.
            delete this.imageUUIDDict[this.imageLayerArray[0].get("uuid")];
            this.map.removeLayer(this.imageLayerArray[0]);
            this.imageLayerArray.splice(0, 1);
          }
          //Create an ImageLayer, add to map and add to array
          var anImageLayer = this.createImageLayer(this.imageDict);
          this.map.addLayer(anImageLayer);
          this.imageUUIDDict[this.imageDict["uuid"]] = true;
          this.imageLayerArray.push(anImageLayer);
          this.setImageLayerVisible(this.imageDict["uuid"]);
        }
        nImages = this.imageLayerArray.length;
        // console.log("nImages after processing imageDict: " + nImages);
      },
    },
    currentsDict: {
      deep: false,
      handler() {
        // console.log("Update currents because of data update");
        // if (this.currentsLayer != null) {
        //   this.map.removeLayer(this.currentsLayer);
        //   this.currentsLayer = null;
        // }
        //Remove the last image layer from the map
        this.showWind(null);
      },
    },
    worstDirection: {
      deep: false,
      handler() {
        // console.log("Update worstDirection because of data update");
        // Changed 270 -> 90
        this.worstDirectionEx = Math.cos(
          (this.worstDirection * 3.1415926) / 180.0
        );
        // Changed 270 -> 90
        this.worstDirectionEy = Math.sin(
          (this.worstDirection * 3.1415926) / 180.0
        );
        // if(this.map !== null)
        //   this.map.redraw();
      },
    },
    speedColors: {
      deep: false,
      handler() {
        // console.log("Update speedColors because of data update");
        const nColors = this.speedColors.length;
        this.speedColorsR = Array(nColors);
        this.speedColorsG = Array(nColors);
        this.speedColorsB = Array(nColors);
        this.speedColorsA = Array(nColors);
        for (var whichColor = 0; whichColor < nColors; whichColor++) {
          //var rgb = 'rgb(200, 12, 53)';
          var rgbaComponents = this.speedColors[whichColor]
            .replace(/[^\d,]/g, "")
            .split(",");
          this.speedColorsR[whichColor] = parseInt(rgbaComponents[0]);
          this.speedColorsG[whichColor] = parseInt(rgbaComponents[1]);
          this.speedColorsB[whichColor] = parseInt(rgbaComponents[2]);
          if (rgbaComponents.length > 3) {
            this.speedColorsA[whichColor] = parseInt(rgbaComponents[3]);
          }
        }
        // if(this.map !== null)
        //   this.map.redraw();
      },
    },
    incidentsArray: {
      deep: false,
      handler() {
        // console.log("Update incidentsArray because of data update");
        var sameImageUUID = null;
        // console.log(this.incidentsArray, "this.incidentsArray");
        if (this.incidentsArray == null || this.incidentsArray == []) {
          this.markersLayer.getSource().clear();
          return;
        } else {
          const nIncidents = this.incidentsArray.length;
          //Create an array to hold the geometries.
          this.markersArray = Array(nIncidents);
          //Fill the array
          for (
            var whichIncidentIdx = 0;
            whichIncidentIdx < nIncidents;
            whichIncidentIdx++
          ) {
            if (this.incidentsArray[whichIncidentIdx]["X"] != -999) {
              if (sameImageUUID != null) {
                if (
                  sameImageUUID !=
                  this.incidentsArray[whichIncidentIdx]["ImageUUID"]
                );
                sameImageUUID = null;
              } else {
                if (whichIncidentIdx == 0)
                  sameImageUUID =
                    this.incidentsArray[whichIncidentIdx]["ImageUUID"];
              }
              this.markersArray[whichIncidentIdx] = new Feature({
                geometry: new Point([
                  this.incidentsArray[whichIncidentIdx]["X"],
                  this.incidentsArray[whichIncidentIdx]["Y"],
                ]),
                id: this.incidentsArray[whichIncidentIdx]["Id"],
              });
              //console.log(this.incidentsArray[whichIncidentIdx]['Id'])
            }
          }
          //Clear the layer source
          this.markersLayer.getSource().clear();
          this.markersLayer.getSource().addFeatures(this.markersArray);
          //If sameImageUUID is not null, show that image
          //Show that image
          if (sameImageUUID != null) this.setImageLayerVisible(sameImageUUID);
        }
      },
    },
    selectedIncidentIdProp: {
      deep: false,
      handler() {
        //Get the feature from the
        // const nMarkers = this.markersArray.length;
        // for (
        //   var whichMarkerIdx = 0;
        //   whichMarkerIdx < nMarkers;
        //   whichMarkerIdx++
        // ) {
        //   if (
        //     this.markersArray[whichMarkerIdx].get("id") ==
        //     this.selectedIncidentIdProp
        //   ) {
        //     if (this.selectedIncidentIdProp != this.selectedIncidentId) {
        //       this.selectClick.getFeatures().clear();
        //       this.selectClick
        //         .getFeatures()
        //         .push(this.markersArray[whichMarkerIdx]);
        //       break;
        //     }
        //   }
        // }
        this.setSelectedIncident(this.selectedIncidentIdProp);
      },
    },
    showImage: {
      deep: false,
      handler() {
        if (this.imageLayerArray != null) {
          if (!this.showImage) {
            const nImages = this.imageLayerArray.length;
            for (var whichIdx = 0; whichIdx < nImages; whichIdx++)
              this.imageLayerArray[whichIdx].setVisible(this.showImage);
          }
          // if (this.showImage)
          //   this.imageDateTimeLabel = this.imageLayerArray[whichIdx]
          //     .get("dateTime")
          //     .toLocaleString("es-ES");
        }
      },
    },
    showCurrents: {
      deep: false,
      handler() {
        if (this.currentsLayer != null) {
          this.currentsLayer.setVisible(this.showCurrents);
        }
      },
    },
    showIncidents: {
      deep: false,
      handler() {
        if (this.markersLayer != null) {
          this.markersLayer.setVisible(this.showIncidents);
        }
      },
    },
    currentSelectMode: {
      deep: false,
      handler() {
        this.setClickMode(this.currentSelectMode);
        this.setClickModeLayers(this.currentSelectMode);
      },
    },
    mapViewToSet: {
      deep: false,
      handler() {
        // console.log("Handler for mapViewToSet");
        // console.log(this.mapViewToSet);
        this.mapViewToSet != null ? this.map.setView(this.mapViewToSet) : null;
      },
    },
  },
};
</script>

<style scoped>
#aMap1 {
  width: 100%;
  height: 100%;
}
</style>

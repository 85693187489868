
import { defineComponent, computed, ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";

export default defineComponent({
  name: "filterImages",
  props: ["startDate", "endDate"],
  emits: ["applyfilter"],
  components: {
    Datepicker,
  },
  setup(props, { emit }) {
    let filterDate = ref([]);
    let actualDate = ref([]) as any;
    let haveFilter = ref(false) as any;
    const store = useStore();
    const minDate = computed(() => {
      return store.getters.getMinDate;
    });
    const maxDate = computed(() => {
      return store.getters.getMaxDate;
    });
    function setFilters() {
      // console.log("Llamando a setFilters");
      // console.log(filterDate.value);
      emit("applyfilter", {
        startDate: new Date(filterDate.value[0]),
        endDate: new Date(filterDate.value[1]),
      });
      actualDate.value = filterDate.value;
      haveFilter.value = true;
    }
    function resetFilters() {
      filterDate.value = [];
      haveFilter.value = false;
      actualDate.value = [];
      // console.log(filterDate.value);
      emit("applyfilter", null);
    }

    function setFilterDate(arrayDates) {
      // Filter forced by insertion of incident
      filterDate.value = arrayDates;
      actualDate.value = arrayDates;
      // Remove all filters
    }
    return {
      filterDate,
      resetFilters,
      minDate,
      maxDate,
      setFilters,
      haveFilter,
      actualDate,
      setFilterDate,
    };
  },
});

enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_CURRENTS_LIST = "getCurrentsList",
  GET_INCIDENTS_LIST = "getIncidentsList",
  GET_LAND_INCIDENTS_LIST = "getLandIncidentsList",
  GET_IMAGES_LIST = "getImagesList",
  GET_METOCEAN_DATA = "getMetoceanData",
  CHANGE_PASSWORD = "changePassword",
  GET_IMAGE_BASE64 = "getImageBase64",
  GET_CURRENTS_FORECAST_SIZE = "getCurrentsForecastSize",
  GET_CURRENTS_FIELD = "getCurrentsField",
  GET_AVAILABLE_BEACHS = "getAvailableBeaches",
  SET_USER_LANGUAGE = "setUserLanguage",
  GET_SITE_VIEW_INFO = "getSiteViewInfo",
  ADD_NEW_INCIDENT = "addNewIncident",
  ADD_NEW_LAND_INCIDENT = "addNewLandIncident",
  GET_INCIDENT_IMAGES_LIST = "getIncidentImagesList",
  UPDATE_INCIDENT = "updateIncident",
  UPDATE_LAND_INCIDENT = "updateLandIncident",
  CHECK_TOKEN_VALIDITY = "checkTokenValidity",
  CHANGE_FORGOTTEN_PASSWORD = "changeForgottenPassword",
  SET_SITE_VIEW_INFO = "setSiteViewInfo",
  GET_TYPE_LAND_INCIDENTS_LIST = "getTypeLandIncidentsList",
  GET_CAUSE_LAND_INCIDENTS_LIST = "getCauseLandIncidentsList",
  GET_BODY_REGIONS_LIST = "getBodyRegionsList",
  GET_BODY_REGIONS_PARTS_LIST = "getBodyRegionsPartsList",
  GET_BODY_PART_ZONES_LIST = "getBodyPartZonesList",
  GET_LAND_ACTIONS_LIST = "getLandActionsList",
  GET_LAND_EXTRA_INCIDENT = "getLandExtraIncident",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_CURRENTS_LIST = "setCurrentsList",
  SET_INCIDENTS_LIST = "setIncidentsList",
  SET_LAND_INCIDENTS_LIST = "setLandIncidentsList",
  SET_IMAGES_LIST = "setImagesList",
  SET_METOCEAN_DATA = "setMetoceanData",
  SET_IMAGE_BASE64 = "setImageBase64",
  SET_EMAIL = "setEmail",
  SET_IMAGE_LOADING = "setImageLoading",
  SET_IMAGE_VISIBLE = "setImageVisible",
  SET_CURRENTS_VISIBLE = "setCurrentsVisible",
  SET_INCIDENTS_VISIBLE = "setIncidentsVisible",
  SET_INCIDENT_ID_SELECTED = "setIncidentIdSelected",
  SET_SPEED_COLOR = "setSpeedColor",
  SET_SPEED_THRESHOLDS = "setSpeedThresholds",
  SET_WORST_DIRECTION_CARTESIAN = "setWorstDirectionCartesian",
  SET_CURRENTS_FIELD = "setCurrentsField",
  SET_MAP_VIEW = "setMapView",
  SET_LANGUAGE = "setLanguage",
  SET_AVAILABLE_BEACHS = "setAvailableBeaches",
  SET_DEFAULT_SITE = "setDefaultSite",
  SET_MAX_LOADED_IMAGES = "setMaxLoadedImages",
  SET_MAX_DATE = "setMaxDate",
  SET_MIN_DATE = "setMinDate",
  SET_RESET_VIEWS = "setResetViews",
  SET_VIEW_INFO = "setViewInfo",
  SET_SUN_DATA = "setSunData",
  SET_INCIDENT_IMAGES_LIST = "setIncidentImagesList",
  SET_EDIT_INCIDENT = "setEditIncident",
  RESET_STATE_USER = "resetStateUser",
  RESET_STATE_DATA = "resetStateData",
  SET_LAST_DATE_INCIDENT_ADDED = "setLastDateIncidentAdded",
  SET_TYPE_LAND_INCIDENTS = "setTypeLandIncidents",
  SET_CAUSE_LAND_INCIDENTS = "setCauseLandIncidents",
  SET_BODY_REGIONS = "setBodyRegions",
  SET_BODY_REGIONS_PARTS = "setBodyRegionsParts",
  SET_BODY_PART_ZONES = "setBodyPartZones",
  SET_BODY_PARTS_MAPPED = "setBodyPartsMapped",
  SET_LAND_ACTIONS = "setLandActions",
  SET_ENABLE_LAND_INCIDENTS = "setEnableLandIncidents",
  SET_EDIT_LAND_INCIDENT = "setEditLandIncident",
  SET_LAST_DATE_LAND_INCIDENT_ADDED = "setLastDateLandIncidentAdded",
  SET_BODY_REGION_SELECTED = "setBodyRegionSelected",
}

export { Actions, Mutations };


import {
  defineComponent,
  onMounted,
  onBeforeMount,
  onActivated,
  ref,
} from "vue";
import ImagesTable from "@/components/tables/ImagesTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ToolbarImages from "@/layout/toolbar/ToolbarImages.vue";
import { subheaderDisplay } from "@/core/helpers/config";
import { computed } from "vue";
import MapComponentOL from "@/components/maps/MapComponentOL.vue";

export default defineComponent({
  name: "images-view",
  components: {
    ImagesTable,
    ToolbarImages,
    MapComponentOL,
  },
  setup() {
    const store = useStore();
    let theToolbar = ref();
    let propageDate = (arrayDate) => {
      theToolbar.value.setFilterDate(arrayDate);
    };
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });
    let theMap = ref();
    let tableView = ref();
    let lastUUIDImage = null;
    let imageData = computed(() => {
      return store.getters.dataImageBase64;
    });
    let mapView = computed(() => {
      return store.getters.dataMapView;
    });
    let imageIsLoading = computed(() => {
      return store.getters.imageIsLoading;
    });
    let showImage = computed(() => {
      return store.getters.isImageVisible;
    });
    function setMapView(view) {
      store.commit(Mutations.SET_MAP_VIEW, view);
    }

    function clickROW(uuid, index) {
      if (index > 2)
        document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")
          [index - 3].scrollIntoView({ behavior: "smooth" });
      else
        document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")[0]
          .scrollIntoView({ block: "end", behavior: "smooth" });

      refreshImage(uuid);
    }

    const previousRow = () => {
      tableView.value.previousRow();
    };

    const nextRow = () => {
      tableView.value.nextRow();
    };

    const siteId = () => {
      return store.getters.getDefaultSiteId;
    };

    let siteViewParameters = computed(() => {
      return store.getters.dataSiteViewInfo;
    });
    const refreshImage = (uuid) => {
      if (uuid != null) {
        if (!theMap.value.isImageLoaded(uuid)) {
          // console.log("Refrescando imagen", uuid);
          store
            .dispatch(Actions.GET_IMAGE_BASE64, {
              siteId: siteId(),
              uuid: uuid,
            })
            .then(() => {
              store.commit(Mutations.SET_IMAGE_VISIBLE, true);
              console.log("End refreshImage");
              lastUUIDImage = uuid;
            });
        } else {
          // console.log("Cargo imagen de cache");
          theMap.value.setImageLayerVisible(uuid);
        }
      } else {
        store.commit(Mutations.SET_IMAGE_VISIBLE, false);
      }
    };
    // if (Object.keys({ ...store.getters.dataImageBase64 }).length === 0) {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    onBeforeMount(() => {
      store.commit(Mutations.SET_CURRENTS_VISIBLE, false);
      store.commit(Mutations.SET_INCIDENTS_VISIBLE, false);
    });
    onActivated(() => {
      theMap.value.resizeMap();
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: "images",
      });
      store.commit(Mutations.SET_CURRENTS_VISIBLE, false);
      store.commit(Mutations.SET_INCIDENTS_VISIBLE, false);
      console.log("LAST UUID IMAGE IS ", lastUUIDImage);
      refreshImage(lastUUIDImage);
    });
    const loadData = (filterDate = null) => {
      let filterStart =
        filterDate == null ? new Date() : filterDate["startDate"];
      let filterEnd =
        filterDate == null ? new Date(filterStart) : filterDate["endDate"];
      filterStart.setHours(0, 0, 0, 0);
      if (filterDate == null) filterStart.setDate(filterStart.getDate() - 4);
      filterEnd.setHours(23, 59, 59, 0);
      store
        .dispatch(Actions.GET_IMAGES_LIST, {
          siteId: siteId(),
          startDate: filterStart.toISOString(),
          endDate: filterEnd.toISOString(),
        })
        .then((data) => {
          propageDate([filterStart, filterEnd]);
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");

          if (data.length > 0) {
            store
              .dispatch(Actions.GET_IMAGE_BASE64, {
                siteId: siteId(),
                uuid: data[0]["UUID"],
              })
              .then(() => {
                lastUUIDImage = data[0]["UUID"];
                tableView.value.active_row = 0;
              });
          } else {
            lastUUIDImage = null;
            store.commit(Mutations.SET_IMAGE_VISIBLE, false);
          }
        })
        .catch(() => {
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("images", []);
      loadData();
    });

    return {
      pageTitle,
      subheaderDisplay,
      imageData,
      refreshImage,
      setMapView,
      mapView,
      clickROW,
      theMap,
      tableView,
      imageIsLoading,
      previousRow,
      nextRow,
      loadData,
      showImage,
      siteViewParameters,
      theToolbar,
    };
  },
});

<template>
  <div class="card mb-5">
    <!--begin::Body-->
    <div
      id="container-canvas"
      class="card-body py-3"
      style="height: 39vh; width: 100%"
    >
      <canvas id="hs-chart" height="auto"></canvas>
    </div>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
import annotationPlugin from "chartjs-plugin-annotation";
Chart.register(annotationPlugin);
import zoomPlugin from "chartjs-plugin-zoom";
Chart.register(zoomPlugin);

export default {
  name: "MetOceanPlot",
  props: [
    "wlChartDatasets",
    "wlChartOptions",
    "wlChartLabels",
    "hsChartDatasets",
    "hsChartOptions",
    "hsChartLabels",
    "hsChartDirs",
    "hsChartWindDirs",
    "hsChartWindSpeeds",
    "showNow",
    "suntimesInfo",
    "instantMarker",
  ],
  hsChartOptionsLocal: null,
  auxAnnotations: null,
  maxHs: 0,
  mounted() {
    this.createHsChart();
    window.addEventListener("resize", () => {
      let canvas = document.getElementById("hs-chart");
      if (canvas) {
        let container = document.getElementById("container-canvas");
        canvas.width = container.style.width;
        canvas.height = container.style.height;
        this.createHsChart();
      }
    });
  },
  methods: {
    drawArrow(arrowLength, cartesianAngleDeg, arrowWidth, color) {
      const canvas = document.createElement("canvas");
      canvas.width = arrowLength;
      canvas.height = arrowLength;
      const centerx = 0.5 * arrowLength;
      const centery = 0.5 * arrowLength;
      const ctx = canvas.getContext("2d");
      //variables to be used when creating the arrow
      var headLength = 0.25 * arrowLength;
      var angleRad = (cartesianAngleDeg * 3.1415926) / 180.0; //Math.atan2(toy-fromy,tox-fromx);
      var fromx = centerx - 0.5 * arrowLength * Math.cos(angleRad);
      var tox = centerx + 0.5 * arrowLength * Math.cos(angleRad);
      var fromy = centery + 0.5 * arrowLength * Math.sin(angleRad);
      var toy = centery - 0.5 * arrowLength * Math.sin(angleRad);
      ctx.save();
      ctx.strokeStyle = color;
      //starting path of the arrow from the start square to the end square
      //and drawing the stroke
      ctx.beginPath();
      ctx.moveTo(fromx, fromy);
      const p1x =
        fromx + 0.5 * arrowWidth * Math.cos(angleRad + 3.1415926 * 0.5);
      const p1y =
        fromy - 0.5 * arrowWidth * Math.sin(angleRad + 3.1415926 * 0.5);
      const p6x =
        fromx + 0.5 * arrowWidth * Math.cos(angleRad - 3.1415926 * 0.5);
      const p6y =
        fromy - 0.5 * arrowWidth * Math.sin(angleRad - 3.1415926 * 0.5);
      ctx.lineTo(p1x, p1y);
      const p2x = p1x + (arrowLength - headLength) * Math.cos(angleRad);
      const p2y = p1y - (arrowLength - headLength) * Math.sin(angleRad);
      const p5x = p6x + (arrowLength - headLength) * Math.cos(angleRad);
      const p5y = p6y - (arrowLength - headLength) * Math.sin(angleRad);
      ctx.lineTo(p2x, p2y);
      const p3x = p2x + arrowWidth * Math.cos(angleRad + 3.1415926 * 0.5);
      const p3y = p2y - arrowWidth * Math.sin(angleRad + 3.1415926 * 0.5);
      const p4x = p5x + arrowWidth * Math.cos(angleRad - 3.1415926 * 0.5);
      const p4y = p5y - arrowWidth * Math.sin(angleRad - 3.1415926 * 0.5);
      ctx.lineTo(p3x, p3y);
      ctx.lineTo(tox, toy);
      ctx.lineTo(p4x, p4y);
      ctx.lineTo(p5x, p5y);
      ctx.lineTo(p6x, p6y);
      ctx.lineTo(fromx, fromy);
      ctx.lineWidth = 1; //arrowWidth;
      ctx.stroke();
      ctx.fillStyle = color;
      ctx.fill();
      ctx.restore();
      return canvas;
    },
    getWindSpeedColor(minVal, maxVal, aVal) {
      const iLimits = [0, 46, 175, 255];
      const rLimits = [0.066, 0.15, 0.463, 0.996];
      const gLimits = [0.124, 0.244, 0.725, 0.984];
      const bLimits = [0.25, 0.568, 0.709, 0.901];
      var cIndex = (255.0 * (aVal - minVal)) / (maxVal - minVal);
      if (cIndex > 255.0) cIndex = 255.0;
      for (var i = 0; i < iLimits.length - 1; i++) {
        if (cIndex < iLimits[i] + 1) {
          const iUp = iLimits[i + 1];
          const iDown = iLimits[i];
          const rUp = rLimits[i + 1];
          const rDown = rLimits[i];
          const gUp = gLimits[i + 1];
          const gDown = gLimits[i];
          const bUp = bLimits[i + 1];
          const bDown = bLimits[i];
          const rValue =
            rDown + ((cIndex - iDown) / (iUp - iDown)) * (rUp - rDown);
          const gValue =
            gDown + ((cIndex - iDown) / (iUp - iDown)) * (gUp - gDown);
          const bValue =
            bDown + ((cIndex - iDown) / (iUp - iDown)) * (bUp - bDown);
          return (
            "rgb(" +
            rValue.toFixed(0) +
            "," +
            gValue.toFixed(0) +
            "," +
            bValue.toFixed(0) +
            ")"
          );
        }
      }
    },
    createHsChart() {
      const ctx = document.getElementById("hs-chart");
      //Calculate the window width and height
      var horizontalScreen = false;
      if (window.screen.width > window.screen.height) horizontalScreen = true;
      //Check add anotation to plugins
      this.hsChartOptionsLocal = this.hsChartOptions;
      if (this.hsChartDatasets == null) console.log("hsChartDatasets is null");
      else {
        if (!("annotation" in this.hsChartOptionsLocal["plugins"]))
          this.hsChartOptionsLocal["plugins"]["annotation"] = {};
        const nowDatetime = new Date();
        const maxHsInteger =
          1 + Math.ceil(Math.max(...this.hsChartDatasets[1]["data"]));
        this.hsChartOptionsLocal.scales.A.min = 0;
        this.hsChartOptionsLocal.scales.A.max = maxHsInteger;
        this.hsChartOptionsLocal.scales.A.title.text = "Hs (m)";
        const annotation1 = {
          drawTime: "afterDatasetsDraw",
          type: "line",
          borderColor: "red",
          borderWidth: 2,
          scaleID: "x",
          value: nowDatetime,
        };
        const arrowWidth = 3;
        const arrowLength = 20;
        const arrowColor = "black";
        var hsArrowVerticalOffset = horizontalScreen
          ? 0.06 * maxHsInteger
          : 0.04 * maxHsInteger;
        var hsLabelVerticalOffset = horizontalScreen
          ? -0.04 * maxHsInteger
          : -0.03 * maxHsInteger;
        var windArrowVerticalPostion = horizontalScreen
          ? 0.95 * maxHsInteger
          : 0.97 * maxHsInteger;
        var windLabelsVerticalPosition = horizontalScreen
          ? windArrowVerticalPostion - 0.08 * maxHsInteger
          : windArrowVerticalPostion - 0.05 * maxHsInteger;
        var directionArrows = this.hsChartDirs
          .map((aDir, anIndex) => {
            if (!(anIndex % 6 == 0)) return null;
            var cartesianAngleDeg = 270 - aDir;
            const dateCenterHs = this.hsChartLabels[anIndex];
            return {
              drawTime: "afterDatasetsDraw",
              type: "label",
              xValue: dateCenterHs,
              yValue:
                this.hsChartDatasets[1]["data"][anIndex] +
                hsArrowVerticalOffset,
              yScaleID: "A",
              content: this.drawArrow(
                arrowLength,
                cartesianAngleDeg,
                arrowWidth,
                arrowColor
              ),
              backgroundColor: "rgba(245,245,245,0)",
              borderWidth: 0,
            };
          })
          .filter((n) => n);

        const windArrowColor = "blue";
        var windArrows = this.hsChartWindDirs
          .map((aDir, anIndex) => {
            if (!(anIndex % 3 == 0)) return null;
            var cartesianAngleDeg = 270 - aDir;
            //const dateCenter = new Date(this.hsChartLabels[anIndex]);
            const dateCenter = new Date(this.hsChartLabels[anIndex].getTime());
            return {
              drawTime: "afterDatasetsDraw",
              type: "label",
              xValue: dateCenter,
              yValue: windArrowVerticalPostion,
              yScaleID: "A",
              content: this.drawArrow(
                arrowLength * 0.9,
                cartesianAngleDeg,
                arrowWidth * 0.75,
                windArrowColor
              ),
              backgroundColor: "rgba(245,245,245,0)", //TODO: Hacer que el color del recuadro cambie con el valor del viento
              borderWidth: 0,
            };
          })
          .filter((n) => n);
        var windSpeedLabels = this.hsChartWindSpeeds
          .map((aSpeed, anIndex2) => {
            if (!(anIndex2 % 3 == 0)) return null;
            //const dateCenter = new Date(this.hsChartLabels[anIndex]);
            const dateCenterWind = new Date(
              this.hsChartLabels[anIndex2].getTime()
            );
            return {
              drawTime: "afterDatasetsDraw",
              type: "label",
              xValue: dateCenterWind,
              yValue: windLabelsVerticalPosition,
              yScaleID: "A",
              content: aSpeed == -999 ? "NaN" : (aSpeed * 3.6).toFixed(0), //m/s to km/h
              backgroundColor: "rgba(245,245,245,0)", //TODO: Hacer que el color del recuadro cambie con el valor del viento
              borderWidth: 0,
              font: { family: "'Poppins','Roboto'", size: 11, weight: 400 },
            };
          })
          .filter((n) => n);
        var hsLabels = this.hsChartDatasets[1]["data"]
          .map((aHs, anIndex2) => {
            if (!(anIndex2 % 6 == 0)) return null;
            //const dateCenter = new Date(this.hsChartLabels[anIndex]);
            const dateCenterHs = new Date(
              this.hsChartLabels[anIndex2].getTime()
            );
            return {
              drawTime: "afterDatasetsDraw",
              type: "label",
              xValue: dateCenterHs,
              yValue: aHs + hsLabelVerticalOffset,
              yScaleID: "A",
              content: aHs == -999 ? "NaN" : aHs.toFixed(1),
              backgroundColor: "rgba(245,245,245,0)",
              borderWidth: 0,
              font: { family: "'Poppins','Roboto'", size: 11, weight: 400 },
            };
          })
          .filter((n) => n);
        //Hay que añadir las etiquetas de color del fondo. Hay que ver analizar la serie temporal.
        //Primero hay que ver cuántas divisiones hay.
        //Para saberlo, hay redondear hacia arriba la diferencia entre la fecha final menos la primera, en días
        const startDateTime = this.hsChartLabels[0];
        const endDateTime = this.hsChartLabels[this.hsChartLabels.length - 1];
        const nDays =
          Math.ceil((endDateTime - startDateTime) / (1000 * 3600 * 24)) + 1;
        var annotationsArray = [];
        var isOdd = true;
        const evenColor = "rgba(0,0,0,0.1)";
        const oddColor = "rgba(1,1,1,0)";
        const labelBackColor = "rgba(1,1,1,0)";
        //Labels
        for (var whichDay = 0; whichDay < nDays; whichDay++) {
          var labelDate = new Date(startDateTime.getTime());
          labelDate.setDate(labelDate.getDate() + whichDay);
          labelDate.setHours(0, 0, 0, 0);
          // console.log(labelDate.toString());
          const labelDateString = labelDate.toString();
          const labelDateStringTokens = labelDateString.split(" ");
          var xMaxDate = new Date(labelDate);
          xMaxDate.setDate(labelDate.getDate() + 1);
          xMaxDate.setHours(0, 0, 0, 0);
          // console.log(xMaxDate);
          annotationsArray.push({
            drawTime: "beforeDatasetsDraw",
            type: "box",
            backgroundColor: labelBackColor, //isOdd ? oddColor : evenColor,
            borderWidth: 0,
            xMin: labelDate,
            xMax:
              whichDay == nDays - 1
                ? this.hsChartLabels[this.hsChartLabels.length - 1]
                : xMaxDate,
            label: {
              drawTime: "beforeDraw",
              enabled: true,
              content:
                labelDateStringTokens[2] + " " + labelDateStringTokens[1],
              position: {
                x: "center",
                y: "15%",
              },
            },
          });
          isOdd = !isOdd;
        }
        //DayNightBacgrounds
        if (this.suntimesInfo != null) {
          var dayNightBackgroundsArray = [];
          isOdd = true;
          const nRectangles = this.suntimesInfo.length - 1;
          for (
            var whichRectangle = 0;
            whichRectangle < nRectangles;
            whichRectangle++
          ) {
            //Para cada día, dibujar dos rectángulos. La noche y el día.
            //
            var xMinDate = new Date(this.suntimesInfo[whichRectangle]);
            xMaxDate = new Date(this.suntimesInfo[whichRectangle + 1]);
            dayNightBackgroundsArray.push({
              drawTime: "beforeDatasetsDraw",
              type: "box",
              backgroundColor: isOdd ? oddColor : evenColor,
              borderWidth: 0,
              xMin: xMinDate,
              xMax: xMaxDate,
            });
            isOdd = !isOdd;
          }
        }
        this.hsChartOptionsLocal.scales.x["min"] = this.hsChartLabels[0];
        this.hsChartOptionsLocal["scales"]["x"]["max"] =
          this.hsChartLabels[this.hsChartLabels.length - 1];
        if (this.suntimesInfo != null) {
          this.auxAnnotations = directionArrows
            .concat(annotationsArray)
            .concat(annotation1)
            .concat(windArrows)
            .concat(windSpeedLabels)
            .concat(hsLabels)
            .concat(dayNightBackgroundsArray);
          this.hsChartOptionsLocal["plugins"]["annotation"]["annotations"] =
            this.auxAnnotations;
        } else {
          this.hsChartOptionsLocal["plugins"]["annotation"]["annotations"] =
            directionArrows
              .concat(annotationsArray)
              .concat(annotation1)
              .concat(windArrows)
              .concat(windSpeedLabels)
              .concat(hsLabels);
        }
        this.hsChartOptionsLocal["plugins"]["zoom"] = {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
          pan: {
            enabled: true,
            mode: "x",
          },
          limits: {
            x: {
              min: this.hsChartLabels[0],
              max: this.hsChartLabels[this.hsChartLabels.length - 1],
            },
          },
        };
      }
      if (this.chartHs) this.chartHs.destroy();
      // console.log(Chart);
      //Chart.defaults.global.defaultFontFamily = "'Poppins','Roboto'";
      this.chartHs = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.hsChartLabels,
          datasets: this.hsChartDatasets,
        },
        options: this.hsChartOptionsLocal,
      });
    },
  },
  watch: {
    hsChartDatasets: {
      deep: false,
      handler() {
        //console.clear();
        console.log("Update Hs Chart because of data update");
        if (this.chartHs) this.chartHs.destroy();
        this.createHsChart();
      },
    },
    instantMarker: {
      deep: false,
      handler() {
        if (this.instantMarker == null) {
          this.hsChartOptionsLocal["plugins"]["annotation"]["annotations"] =
            this.auxAnnotations;
          this.chartHs.options = this.hsChartOptionsLocal;
          this.chartHs.update(0);
        } else {
          const annotation1 = {
            drawTime: "afterDatasetsDraw",
            type: "line",
            borderColor: "gray",
            borderWidth: 2,
            scaleID: "x",
            value: this.instantMarker,
          };
          console.log("Update instant marker because of data update");
          this.hsChartOptionsLocal["plugins"]["annotation"]["annotations"] =
            this.auxAnnotations.concat(annotation1);
          this.chartHs.options = this.hsChartOptionsLocal;
          this.chartHs.update(0);
        }
      },
    },
  },
};
</script>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "toolbar",
  id: "kt_toolbar"
}
const _hoisted_2 = {
  id: "kt_page_title",
  "data-kt-swapper": "false",
  "data-kt-swapper-mode": "prepend",
  "data-kt-swapper-parent": "{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}",
  class: "page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0 d-md-none d-sm-none d-lg-block",
  style: {"padding":"15px 0 0 0 0 !important"}
}
const _hoisted_3 = { class: "d-flex align-items-center text-dark fw-bolder my-1 fs-3" }
const _hoisted_4 = { class: "d-flex align-items-center py-1" }
const _hoisted_5 = {
  href: "",
  class: "btn btn-sm btn-danger rounded-pill"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "kt_toolbar_container",
      class: _normalizeClass([{
        'container-fluid': _ctx.toolbarWidthFluid,
        'container-xxl': !_ctx.toolbarWidthFluid,
      }, "d-flex flex-stack"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("editlandIncident")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "menu-link",
          "active-class": "active",
          to: "/land-incidents/"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("cancel")), 1)
          ]),
          _: 1
        })
      ])
    ], 2)
  ]))
}

import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-widget-12",
  props: {
    widgetClasses: String,
  },
  emits: ["clickROW"],
  setup(props, { emit }) {
    // show page loading
    let active_row = ref(0);
    const router = useRouter();
    const checkedRows = ref([]);
    const store = useStore();
    const list = computed(() => {
      return store.getters.dataincidentsList;
    });
    const isLoading = computed(() => {
      return store.getters.imageIsLoading;
    });
    function getTextCause(causeID) {
      let causes = {
        1: "currentRescue",
        2: "exhaustionRescue",
        3: "other",
      };
      return causes[causeID];
    }
    function getTextIncident(incidentID) {
      let incidents = {
        1: "other",
        2: "surfAccident",
        3: "bathingAccident",
      };
      return incidents[incidentID];
    }
    function clickTR(ImageUUID, index) {
      active_row.value = index;
      emit("clickROW", ImageUUID, active_row);
    }

    function nextRow() {
      if (active_row.value < list.value.length - 1) {
        active_row.value += 1;
        emit("clickROW", list.value[active_row.value].ImageUUID, active_row);
      }
    }

    function previousRow() {
      if (active_row.value > 0) {
        active_row.value = active_row.value - 1;
        emit("clickROW", list.value[active_row.value].ImageUUID, active_row);
      }
    }

    function setRow(numberRow) {
      if (
        numberRow != null &&
        numberRow < list.value.length &&
        numberRow >= 0
      ) {
        active_row.value = numberRow;
        // console.log(list.value[numberRow]);
        emit("clickROW", list.value[active_row.value].ImageUUID, active_row);
      }
    }

    const editIncident = (item) => {
      store.commit(
        Mutations.SET_EDIT_INCIDENT,
        ref({
          siteId: store.getters.getDefaultSite.Id,
          incident: item,
        })
      );
      router.push({ name: "edit-incident" });
    };
    return {
      list,
      checkedRows,
      isLoading,
      getTextCause,
      getTextIncident,
      clickTR,
      active_row,
      nextRow,
      previousRow,
      setRow,
      editIncident,
    };
  },
});

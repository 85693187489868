
import { defineComponent, ref } from "vue";
import FilterImages from "@/components/filters/FilterImages.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "toolbar-images",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  emits: ["applyfilter"],
  components: {
    FilterImages,
  },
  setup(props, { emit }) {
    let dateFilter = ref(null) as any;
    let theFilter = ref(null) as any;
    const propageFilters = (filter) => {
      dateFilter.value = filter;
      emit("applyfilter", filter);
    };

    let filter = ref();
    function setFilterDate(arrayDates) {
      // Filter forced by insertion of incident
      dateFilter.value = {
        startDate: arrayDates[0],
        endDate: arrayDates[1],
      };
      theFilter.value.setFilterDate(arrayDates);
    }
    return {
      toolbarWidthFluid,
      propageFilters,
      filter,
      dateFilter,
      theFilter,
      setFilterDate,
    };
  },
});

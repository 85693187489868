
import { defineComponent, onActivated, ref } from "vue";
import MetoceanTable from "@/components/tables/MetoceanTable.vue";
import MetOceanPlots from "@/components/charts/MetOceanPlots.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "../../../store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ToolbarMetocean from "@/layout/toolbar/ToolbarMetocean.vue";
import { subheaderDisplay } from "@/core/helpers/config";
import "chartjs-adapter-moment";

export default defineComponent({
  name: "metocean-view",
  data() {
    return {
      subheaderDisplay: subheaderDisplay as any,
      chartLabels: null as any,
      chartDatasets: null as any,
      chartLabels2: null as any,
      chartDatasets2: null as any,
      hsChartDirs: null as any,
      hsChartWindDirs: null as any,
      hsChartWindSpeeds: null as any,
      imageDict: null as any,
      wlChartOptions: {
        plugins: {
          //title: {text: 'Nivel del mar',display: true},
          legend: false,
        },
        responsive: true,
        lineTension: 0.25,
        scales: {
          y: { min: 0, max: 5, ticks: { stepSize: 1.0 } },
          x: { type: "time", time: { displayFormats: { hour: "D MMM H:mm" } } },
        },
      } as any,
      hsChartOptions: {
        plugins: {
          //title: {text: 'Nivel del mar',display: true},
          legend: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.35,
        scales: {
          //x:{ type: 'time', time: { displayFormats:{hour: 'D MMM H:mm'} },grid: {display:true, drawBorder:true}},
          x: {
            type: "time",
            time: { displayFormats: { hour: "H:mm" } },
            grid: { display: true, drawBorder: true },
            ticks: {
              font: { family: "'Poppins','Roboto'", size: 11, weight: 400 },
            },
          },
          A: {
            type: "linear",
            position: "left",
            grid: { display: false, drawBorder: true },
            title: {
              font: { family: "'Poppins','Roboto'", size: 13, weight: 600 },
              text: "Hs (m)",
              display: true,
              align: "center",
            },
            ticks: {
              font: { family: "'Poppins','Roboto'", size: 13, weight: 400 },
            },
          },
          B: {
            type: "linear",
            position: "right",
            grid: { display: false, drawBorder: true },
            max: 7,
            min: 0,
            title: {
              font: { family: "'Poppins','Roboto'", size: 13, weight: 600 },
              text: "Nivel (m)",
              display: true,
              align: "center",
            },
            ticks: {
              font: { family: "'Poppins','Roboto'", size: 13, weight: 400 },
            },
          },
        },
      } as any,
      endDate: new Date(), // now
      startDate: new Date(), // - 3 days
      instantMarker: null as Date | null,
    };
  },
  mounted() {
    setCurrentPageBreadcrumbs("metocean", []);
    this.loadData();
  },
  setup() {
    let theToolbar = ref();
    let propageDate = (arrayDate) => {
      theToolbar.value.setFilterDate(arrayDate);
    };
    onActivated(() => {
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: "metoceanForecast",
      });
    });
    return {
      theToolbar,
      propageDate,
    };
  },
  components: {
    MetoceanTable,
    ToolbarMetocean,
    MetOceanPlots,
  },
  methods: {
    refreshChart() {
      if (
        typeof this.metOceanData == "object" &&
        Object.keys(this.metOceanData).length > 0
      ) {
        this.chartLabels = this.metOceanData["TargetDateTime"];
        // .map(
        //   (aDateString) => {
        //     var tempDate = new Date(aDateString);
        //     // console.log(aDateString);
        //     // console.log(tempDate);
        //     tempDate.setTime(
        //       tempDate.getTime() - tempDate.getTimezoneOffset() * 60 * 1000
        //     );
        //     // console.log(tempDate);
        //     return tempDate;
        //   }
        // );
        this.chartDatasets = [
          {
            type: "line",
            data: this.metOceanData["WaterLevel"],
            fill: true,
            backgroundColor: "rgba(0, 177,255,.75)",
            pointRadius: 0,
          },
        ];
        this.chartLabels2 = this.chartLabels;
        (this.chartDatasets2 = [
          {
            type: "line",
            yAxisID: "B",
            data: this.metOceanData["WaterLevel"],
            fill: true,
            backgroundColor: "rgba(186, 228, 245,1.0)",
            borderWidth: 0,
            pointRadius: 0,
            order: 10,
          },
          {
            type: "line",
            yAxisID: "A",
            data: this.metOceanData["TotalHs"],
            fill: false,
            backgroundColor: "rgba(0, 0,255,1.0)",
            borderColor: "rgba(0, 0,255,0.5)",
            borderWidth: 2,
            pointRadius: 0,
            order: 0,
          },
        ]),
          (this.hsChartDirs = this.metOceanData["TotalDirp"]);
        this.hsChartWindDirs = this.metOceanData["WindDirection"];
        this.hsChartWindSpeeds = this.metOceanData["WindSpeed"];
      }
    },
    loadData(filterDate: Date | null = null) {
      let filterStart =
        filterDate == null ? this.startDate : filterDate["startDate"];
      let filterEnd = filterDate == null ? this.endDate : filterDate["endDate"];
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
      filterStart.setHours(0, 0, 0, 0);
      if (filterDate == null) filterEnd.setDate(filterStart.getDate() + 2);
      filterEnd.setHours(23, 59, 59, 0);
      store
        .dispatch(Actions.GET_METOCEAN_DATA, {
          siteId: this.siteId,
          startDate: filterStart.toISOString(),
          endDate: filterEnd.toISOString(),
        })
        .then(() => {
          this.propageDate([filterStart, filterEnd]);
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        })
        .catch(() => {
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    setLineDate(date) {
      this.instantMarker = new Date(date);
    },
  },
  computed: {
    metOceanData() {
      return store.getters.dataMetocean;
    },
    pageTitle() {
      return store.getters.pageTitle;
    },
    siteId() {
      return store.getters.getDefaultSiteId;
    },
    suntimesInfo() {
      return store.getters.datasun;
    },
  },
  watch: {
    metOceanData: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          console.log("metOceanData changed");
          this.refreshChart();
        }
      },
      deep: false,
    },
    suntimesInfo: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          console.log("sunData changed");
          this.refreshChart();
        }
      },
      deep: false,
    },
  },
});

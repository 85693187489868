
import { defineComponent, onMounted, onActivated, ref } from "vue";
import IncidentsTable from "@/components/tables/IncidentsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ToolbarIncidents from "@/layout/toolbar/sea-incidents/ToolbarIncidents.vue";
import { subheaderDisplay } from "@/core/helpers/config";
import { computed } from "vue";
import MapComponentOL from "@/components/maps/MapComponentOL.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "incidents-view",
  components: {
    IncidentsTable,
    ToolbarIncidents,
    MapComponentOL,
  },
  setup() {
    const store = useStore();
    let theToolbar = ref();
    let theMap = ref();
    let showAll = ref(false);
    let tableView = ref();
    let lastUUIDImage = null;
    let selectedIndex = ref(0);
    let infoSelectedIncident = ref(null) as any;
    let mode = ref(null) as any;
    const { t } = useI18n();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });
    const allIncidentsList = computed(() => {
      return store.getters.dataincidentsList;
    });
    const incidentsList = computed(() => {
      return showAll.value
        ? store.getters.dataincidentsList
        : store.getters.dataincidentsList.length > 0
        ? [store.getters.dataincidentsList[selectedIndex.value]]
        : null;
    });
    const selectedIncidentId = computed(() => {
      return store.getters.incidentIdSelected;
    });
    const refreshImage = (uuid) => {
      console.log(theMap);
      if (uuid != null) {
        store.commit(Mutations.SET_IMAGE_VISIBLE, true);
        if (!theMap.value.isImageLoaded(uuid)) {
          console.log("Refrescando imagen", uuid);
          store
            .dispatch(Actions.GET_IMAGE_BASE64, {
              siteId: siteId(),
              uuid: uuid,
            })
            .then(() => {
              console.log("End refreshImage");
              lastUUIDImage = uuid;
            });
        } else {
          theMap.value.setImageLayerVisible(uuid);
          console.log("Cargo imagen de cache");
        }
      }
    };

    let showImage = computed(() => {
      return store.getters.isImageVisible;
    });

    let imageData = computed(() => {
      return store.getters.dataImageBase64;
    });
    let mapView = computed(() => {
      return store.getters.dataMapView;
    });
    let imageIsLoading = computed(() => {
      return store.getters.imageIsLoading;
    });

    let siteViewParameters = computed(() => {
      return store.getters.dataSiteViewInfo;
    });

    function setMapView(view) {
      store.commit(Mutations.SET_MAP_VIEW, view);
    }

    function clickROW(uuid, index) {
      console.log("clickROW", uuid, index.value);
      if (allIncidentsList.value.length > 0) {
        if (index.value != 0)
          document
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")
            [index - 3]?.scrollIntoView({ behavior: "smooth" });
        else
          document
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[0]
            .scrollIntoView({ block: "end" });
        selectedIndex.value = index.value;
        if (allIncidentsList.value[index.value]["ImageUUID"] != null) {
          refreshImage(uuid);
        } else {
          console.log("Estableciendo imagen visible a false");
          store.commit(Mutations.SET_IMAGE_VISIBLE, false);
        }
        console.log("Establishing infoSelectIncident");
        let modIncident = { ...allIncidentsList.value[index.value] };
        console.log(modIncident, "modIncident");
        modIncident["IncidentTypeID"] = getTextIncident(
          allIncidentsList.value[index.value]["IncidentTypeID"]
        );
        modIncident["IncidentCauseID"] = getTextCause(
          allIncidentsList.value[index.value]["IncidentCauseID"]
        );
        infoSelectedIncident.value = modIncident;
      }
    }

    const previousRow = () => {
      tableView.value.previousRow();
    };

    const nextRow = () => {
      tableView.value.nextRow();
    };

    const incidentClicked = (incidentID) => {
      console.log("incidentClicked", incidentID);
      let numberRow = allIncidentsList.value.findIndex(
        (incident) => incidentID == incident.Id
      );
      console.log("Has pinchado la fila", numberRow);
      tableView.value.setRow(numberRow);
    };
    const siteId = () => {
      return store.getters.getDefaultSiteId;
    };

    const list = computed(() => {
      return store.getters.dataincidentsList;
    });

    const lastIncidentAdded = computed(() => {
      return store.getters.getlastDateIncidentAdded;
    });

    let propageDate = (arrayDate) => {
      theToolbar.value.setFilterDate(arrayDate);
    };
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    onActivated(() => {
      store.commit(Mutations.SET_IMAGE_VISIBLE, false);
      store.commit(Mutations.SET_CURRENTS_VISIBLE, false);
      store.commit(Mutations.SET_BREADCRUMB_MUTATION, {
        title: "seaIncidents",
      });
      if (lastIncidentAdded.value != null) {
        let startDate: Date = new Date();
        let endDate: Date = new Date();
        if (list.value.length > 0) {
          if (lastIncidentAdded.value < list.value[0].OccurrenceDateTime) {
            console.log("Soy menor que el primer registro");
            startDate = new Date(lastIncidentAdded.value.getTime());
            endDate = new Date(
              list.value[list.value.length - 1].OccurrenceDateTime.getTime()
            );
            loadData({
              startDate,
              endDate,
            });
          } else if (
            lastIncidentAdded.value >
            list.value[list.value.length - 1].OccurrenceDateTime
          ) {
            startDate = new Date(list.value[0].OccurrenceDateTime.getTime());
            endDate = new Date(lastIncidentAdded.value.getTime());
            console.log("Soy mayor que el último registro");
            loadData({
              startDate,
              endDate,
            });
          } else {
            console.log("Estoy entre ambos registros, refresco");
            // Its between, refresh
            startDate = new Date(list.value[0].OccurrenceDateTime.getTime());
            endDate = new Date(
              list.value[list.value.length - 1].OccurrenceDateTime.getTime()
            );
            loadData({
              startDate,
              endDate,
            });
          }
        } else {
          // Download from date to next 3 days
          startDate = new Date(lastIncidentAdded.value.getTime());
          endDate = new Date(lastIncidentAdded.value.getTime());
          endDate.setDate(endDate.getDate() + 3);
        }
        store.commit(Mutations.SET_LAST_DATE_INCIDENT_ADDED, null);
      }
      refreshImage(lastUUIDImage);
      if (document.getElementsByClassName("activeRow").length > 0) {
        setTimeout(function () {
          document.getElementsByClassName("activeRow")[0].scrollIntoView();
        }, 100);
      }
      if (allIncidentsList.value.length == 0) {
        store.commit(Mutations.SET_IMAGE_VISIBLE, false);
      } else {
        store.commit(Mutations.SET_IMAGE_VISIBLE, true);
      }
      theMap.value.resizeMap();
    });

    const changeShowAll = () => {
      showAll.value = !showAll.value;
      if (showAll.value) {
        mode.value = "selectIncident";
      } else {
        mode.value = null;
      }
    };
    const loadData = (filterDate = null as any) => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
      // Check if lastDateIncidentAdded exists or null
      // if exists check if this date is between list dates
      // if is not, check if is less or greater than list dates
      // ask for new dates including lastDateIncidentAdded
      let filterStart =
        filterDate == null
          ? new Date(new Date().getFullYear(), 0, 1)
          : filterDate["startDate"];
      let filterEnd = filterDate == null ? new Date() : filterDate["endDate"];
      filterStart.setHours(0, 0, 0, 0);
      //if (filterDate == null) filterStart.setDate(filterStart.getDate() - 3);
      filterEnd.setHours(23, 59, 59, 0);
      store
        .dispatch(Actions.GET_INCIDENTS_LIST, {
          siteId: siteId(),
          startDate: filterStart.toISOString(),
          endDate: filterEnd.toISOString(),
          incidentTypeIds: filterDate ? filterDate["incidentTypeIds"] : [],
          incidentCauseIds: filterDate ? filterDate["incidentCauseIds"] : [],
          victimsToHospital: filterDate
            ? filterDate["victimsToHospital"]
            : null,
          jetSkiInvolved: filterDate ? filterDate["jetSkiInvolved"] : null,
          victimTypeIds: filterDate ? filterDate["victimTypeIds"] : [],
        })
        .then((data) => {
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
          propageDate([filterStart, filterEnd]);
          // Get initial base64 image
          if (data.length > 0) {
            let modIncident = { ...allIncidentsList.value[0] };
            // console.log(modIncident, "modIncident");
            modIncident["IncidentTypeID"] = getTextIncident(
              allIncidentsList.value[0]["IncidentTypeID"]
            );
            modIncident["IncidentCauseID"] = getTextCause(
              allIncidentsList.value[0]["IncidentCauseID"]
            );
            infoSelectedIncident.value = modIncident;
          }
          console.log("infoSelectedIncident SEA", infoSelectedIncident.value);
          if (data.length > 0 && data[0]["ImageUUID"] != null) {
            store.commit(Mutations.SET_IMAGE_VISIBLE, true);
            store
              .dispatch(Actions.GET_IMAGE_BASE64, {
                siteId: siteId(),
                uuid: data[0]["ImageUUID"],
              })
              .then(() => {
                // add to buffer
                console.log("Adding lastUUIDImage of view");
                lastUUIDImage = data[0]["ImageUUID"];
              });
          }
        });
    };

    const getTextCause = (causeID) => {
      let causes = {
        1: "currentRescue",
        2: "exhaustionRescue",
        3: "other",
      };
      return causes[causeID];
    };
    const getTextIncident = (incidentID) => {
      let incidents = {
        1: "other",
        2: "surfAccident",
        3: "bathingAccident",
      };
      return incidents[incidentID];
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs(t("seaIncidents"), []);
      loadData();
    });
    return {
      pageTitle,
      subheaderDisplay,
      refreshImage,
      imageData,
      incidentsList,
      selectedIncidentId,
      setMapView,
      mapView,
      theMap,
      clickROW,
      imageIsLoading,
      tableView,
      previousRow,
      nextRow,
      siteViewParameters,
      loadData,
      incidentClicked,
      changeShowAll,
      showAll,
      selectedIndex,
      infoSelectedIncident,
      mode,
      showImage,
      theToolbar,
    };
  },
});

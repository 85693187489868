
import { defineComponent, ref, computed, watch } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";

// interface Filter {
//   currentRescue: boolean;
//   exhaustionRescue: boolean;
//   otherCause: boolean;
//   surfAccident: boolean;
//   bathingAccident: boolean;
//   otherType: boolean;
// }

export default defineComponent({
  name: "filterIncidents",
  components: {
    Datepicker,
  },
  emits: ["applyfilter"],
  setup(props, { emit }) {
    let store = useStore();
    let actualDate = ref([]) as any;
    let haveFilter = ref(false) as any;
    let filtersChanged = ref(false) as any;
    const typeIncidents = computed(() => store.getters.getTypeLandIncidents);
    const causeIncidents = computed(() => store.getters.getCauseLandIncidents);
    const filters = ref({
      siteId: store.getters.getDefaultSite.Id,
      startDate: null,
      endDate: null,
      LandIncidentTypeID: [],
      LandIncidentCauseID: [],
    });
    let filterDate = ref([]) as any;

    const resetFilters = () => {
      filters.value = {
        siteId: store.getters.getDefaultSite.Id,
        startDate: null,
        endDate: null,
        LandIncidentTypeID: ([] as never[]) || null,
        LandIncidentCauseID: ([] as never[]) || null,
      };
      filterDate.value = [];
      emit("applyfilter", null);
      filtersChanged.value = false;
    };

    const minDate = computed(() => {
      return store.getters.getMinDate;
    });
    const maxDate = computed(() => {
      return store.getters.getMaxDate;
    });

    const setFilters = () => {
      emit("applyfilter", filters.value);
      actualDate.value = filterDate.value;
      haveFilter.value = true;
      filtersChanged.value = false;
    };
    watch(filterDate, () => {
      if (filterDate.value) {
        filters.value.startDate = filterDate.value[0];
        filters.value.endDate = filterDate.value[1];
      }
    });

    function setFilterDate(arrayDates) {
      // Filter forced by insertion of incident
      console.log("arrayDates");
      console.log(arrayDates);
      filterDate.value = arrayDates;
      actualDate.value = arrayDates;
      // Remove all filters
      filters.value = {
        siteId: store.getters.getDefaultSite.Id,
        startDate: arrayDates[0],
        endDate: arrayDates[1],
        LandIncidentTypeID: ([] as never[]) || null,
        LandIncidentCauseID: ([] as never[]) || null,
      };
    }

    watch(filters.value, () => {
      // console.log("filters changed");
      filtersChanged.value = true;
    });
    return {
      filters,
      filterDate,
      resetFilters,
      minDate,
      maxDate,
      setFilters,
      haveFilter,
      actualDate,
      filtersChanged,
      typeIncidents,
      causeIncidents,
      setFilterDate,
    };
  },
});

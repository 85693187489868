
import { defineComponent, computed, ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";

export default defineComponent({
  name: "filterCurrents",
  props: ["startDate", "endDate"],
  emits: ["applyfilter"],
  components: {
    Datepicker,
  },
  setup(props, { emit }) {
    let filterDate = ref([]) as any;
    let actualDate = ref([]) as any;
    let haveFilter = ref(false) as any;
    const store = useStore();
    const calculateMaxDate = (maxDate) => {
      if (maxDate == null) {
        maxDate = new Date();
      } else {
        maxDate = new Date(maxDate);
        maxDate > new Date() ? (maxDate = new Date()) : maxDate;
      }
      maxDate.setDate(maxDate.getDate() + 1);
      return maxDate;
    };
    const minDate = computed(() => {
      return store.getters.getMinDate;
    });
    const maxDate = computed(() => {
      return calculateMaxDate(store.getters.getMaxDate);
    });
    function setFilters() {
      let startDate = new Date(filterDate.value[0]);
      let endDate = new Date(filterDate.value[1]);
      emit("applyfilter", {
        startDate: startDate,
        endDate: endDate,
      });
      actualDate.value = filterDate.value;
      haveFilter.value = true;
    }
    function resetFilters() {
      filterDate.value = [];
      haveFilter.value = false;
      actualDate.value = [];
      emit("applyfilter", null);
    }
    function setFilterDate(arrayDates) {
      // Filter forced by insertion of incident
      filterDate.value = arrayDates;
      actualDate.value = arrayDates;
      // Remove all filters
    }

    return {
      filterDate,
      resetFilters,
      minDate,
      maxDate,
      setFilters,
      haveFilter,
      actualDate,
      setFilterDate,
    };
  },
});

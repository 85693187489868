
// import ApiService from "@/core/services/ApiService";
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/theme";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const enableLogger = true;
    if (!enableLogger) {
      window["console"]["log"] = () => {
        // Do nothing
      };
    }
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      const email = window.localStorage.getItem("email");
      const lang = window.localStorage.getItem("lang");
      const defaultSite = window.localStorage.getItem("defaultSite");
      const minDate = window.localStorage.getItem("minDate");
      const maxDate = window.localStorage.getItem("maxDate");
      const siteViewParameters =
        window.localStorage.getItem("siteViewParameters");
      if (email) {
        store.commit(Mutations.SET_EMAIL, email);
      }
      if (lang) {
        store.commit(Mutations.SET_LANGUAGE, lang);
        i18n.locale.value = lang;
      }
      if (defaultSite) {
        store.commit(Mutations.SET_DEFAULT_SITE, JSON.parse(defaultSite));
      }
      if (minDate) {
        store.commit(
          Mutations.SET_MIN_DATE,
          minDate != "null" ? new Date(minDate) : null
        );
      }
      if (maxDate) {
        store.commit(
          Mutations.SET_MAX_DATE,
          maxDate != "null" ? new Date(maxDate) : null
        );
      }
      if (siteViewParameters) {
        store.commit(Mutations.SET_VIEW_INFO, JSON.parse(siteViewParameters));
      }

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});


import { defineComponent, ref, computed, toRefs } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "step-4",
  emits: ["previousStep", "nextStep", "goToStep"],
  props: {
    incidentData: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const myprops = toRefs(props) as any;
    const store = useStore();
    const incidentType =
      myprops.incidentData.value.landIncident.LandIncidentTypeID;
    let listIncidents =
      [myprops.incidentData.value.landIncident] || (null as any);
    let incidentsImagesList = ref([]) as any;
    let mode = ref(null) as any;
    let incidentsArray = ref([]) as any;
    if (myprops.incidentData.value.landIncident.LandIncidentTypeID === 9) {
      myprops.incidentData.value.landIncident.LandIncidentCauseID = 90;
    }
    if (myprops.incidentData.value.landIncident.Comments) {
      let editedBefore =
        myprops.incidentData.value.landIncident.Comments.indexOf("[Editado:");
      if (editedBefore != -1) {
        myprops.incidentData.value.landIncident.Comments =
          myprops.incidentData.value.landIncident.Comments.slice(
            0,
            editedBefore
          );
      }
    }
    // listIncidents = [myprops.incidentData.value.landIncident];
    if (myprops.incidentData.value.landIncident.Comments) {
      let editedBefore =
        myprops.incidentData.value.landIncident.Comments.indexOf("[Editado:");
      if (editedBefore != -1) {
        myprops.incidentData.value.landIncident.Comments =
          myprops.incidentData.value.landIncident.Comments.slice(
            0,
            editedBefore
          );
      }
    }
    setTimeout(() => {
      mode.value = "editIncident";
      incidentsArray.value = listIncidents;
    }, 200);
    const causeIncidents = store.getters.getCauseLandIncidents;
    const goToPreviousStep = () => {
      if (
        myprops.incidentData.value?.landIncident.LandIncidentTypeID > 4 &&
        myprops.incidentData.value?.landIncident.LandIncidentTypeID < 11 &&
        myprops.incidentData.value?.landIncident.LandIncidentTypeID !== 9
      ) {
        console.log("Yendo a paso 2");
        emit("goToStep", 2);
      } else {
        console.log("Yendo a paso anterior");
        emit("previousStep");
      }
    };
    const goToNextStep = () => {
      emit("nextStep");
    };
    const showImage = computed(() => {
      return store.getters.isImageVisible;
    });

    return {
      myprops,
      goToPreviousStep,
      listIncidents,
      router,
      incidentsImagesList,
      showImage,
      mode,
      incidentsArray,
      causeIncidents,
      goToNextStep,
      incidentType,
    };
  },
});

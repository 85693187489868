import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
//import { PropType, resolveComponent } from "vue";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class DataModule extends VuexModule {
  currentsList = {} as any;
  currentsField = null as any;
  incidentsList = {} as any;
  landIncidentsList = {} as any;
  imagesList = {} as any;
  metoceanData = {} as any;
  sunData = [] as any;
  imageBase64Data = [] as any;
  loadingImage = false as boolean;
  showImage = false as any;
  showCurrents = true as boolean;
  showIncidents = false as boolean;
  selectedIncidentId = null as any;
  speedColors = null as any;
  speedThresholds = null as any;
  worstDirectionCartesian = null as any;
  mapView = null as any;
  maxLoadedImages = 15 as number;
  siteViewInfo = null as any;
  incidentsImagesList = [] as any;
  incidentEditing = null as any;
  lastDateIncidentAdded = null as any;
  lastDateLandIncidentAdded = null as any;
  typeLandIncidents = null as any;
  causeLandIncidents = null as any;
  bodyRegions = null as any;
  bodyRegionsParts = null as any;
  bodyPartZones = null as any;
  typeLandIncidentActions = null as any;
  landIncidentsEnabled = false as boolean;
  landIncidentEditing = null as any;
  bodyRegionSelected = [] as any;
  /**
   * Verify user authentication
   * @returns boolean
   */
  get dataCurrentsList(): any {
    return this.currentsList;
  }

  get dataCurrentsField(): any {
    return this.currentsField;
  }

  get dataincidentsList(): any {
    return this.incidentsList;
  }

  get dataLandIncidentsList(): any {
    return this.landIncidentsList;
  }
  get dataImagesList(): any {
    return this.imagesList;
  }

  get dataMetocean(): any {
    return this.metoceanData;
  }

  get datasun(): any {
    return this.sunData;
  }

  get dataImageBase64(): any {
    return this.imageBase64Data;
  }

  get imageIsLoading(): any {
    return this.loadingImage;
  }

  get isImageVisible(): boolean {
    return this.showImage;
  }

  get isCurrentsVisible(): boolean {
    return this.showCurrents;
  }

  get isIncidentsVisible(): boolean {
    return this.showIncidents;
  }

  get incidentSelected(): number {
    return this.selectedIncidentId;
  }

  get dataSpeedColors(): Array<string> {
    return this.speedColors;
  }

  get dataSpeedThresholds(): Array<string> {
    return this.speedThresholds;
  }

  get dataWorstDirectionCartesian(): any {
    return this.worstDirectionCartesian;
  }

  get dataMapView(): any {
    return this.mapView;
  }

  get getMaxLoadedImages(): number {
    return this.maxLoadedImages;
  }

  get dataSiteViewInfo(): any {
    return this.siteViewInfo;
  }

  get dataIncidentImagesList(): any {
    return this.incidentsImagesList;
  }

  get getIncidentEditing(): any {
    return this.incidentEditing;
  }

  get getlastDateIncidentAdded(): any {
    return this.lastDateIncidentAdded;
  }

  get getlastDateLandIncidentAdded(): any {
    return this.lastDateLandIncidentAdded;
  }

  get getTypeLandIncidents(): any {
    return this.typeLandIncidents;
  }

  get getCauseLandIncidents(): any {
    return this.causeLandIncidents;
  }

  get getBodyRegions(): any {
    return this.bodyRegions;
  }

  get getBodyRegionsParts(): any {
    return this.bodyRegionsParts;
  }

  get getBodyPartZones(): any {
    return this.bodyPartZones;
  }

  get getLandActionTypes(): any {
    return this.typeLandIncidentActions;
  }

  get getLandIncidentsEnabled(): boolean {
    return this.landIncidentsEnabled;
  }

  get getLandIncidentEditing(): any {
    return this.landIncidentEditing;
  }

  get getBodyRegionSelected(): any {
    return this.bodyRegionSelected;
  }

  @Action
  [Actions.GET_CURRENTS_LIST](filterCurrentsList) {
    this.context.commit(Mutations.SET_IMAGE_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("get_currents_fields_list", filterCurrentsList)
        .then(({ data }) => {
          data = data.map((element) => {
            element.TargetDateTime = new Date(element.TargetDateTime);
            return element;
          });

          this.context.commit(Mutations.SET_CURRENTS_LIST, data);
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_CURRENTS_FIELD](dataCurrentField) {
    this.context.commit(Mutations.SET_IMAGE_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("get_currents_field", dataCurrentField)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENTS_FIELD, data);
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response.data.msg || response.data.message
          );
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_CURRENTS_FORECAST_SIZE](siteId) {
    return new Promise((resolve, reject) => {
      this.context.commit(Mutations.SET_IMAGE_LOADING, true);
      ApiService.post("get_currents_forecast_site", siteId)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SPEED_THRESHOLDS,
            data.SpeedThresholds.split(",")
          );
          this.context.commit(Mutations.SET_SPEED_COLOR, data.speedColors);
          this.context.commit(
            Mutations.SET_WORST_DIRECTION_CARTESIAN,
            data.WorstDirectionCartesian
          );
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_INCIDENTS_LIST](filterCurrentsList) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_incidents_list", filterCurrentsList)
        .then(({ data }) => {
          data = data.map((element) => {
            element.InsertionDateTime = new Date(element.InsertionDateTime);
            element.OccurrenceDateTime = new Date(element.OccurrenceDateTime);
            return element;
          });
          this.context.commit(Mutations.SET_INCIDENTS_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_INCIDENT_IMAGES_LIST](incidentData) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_incident_images_list", incidentData)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INCIDENT_IMAGES_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.ADD_NEW_INCIDENT](incidentData) {
    return new Promise((resolve, reject) => {
      ApiService.post("insert_incident", incidentData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.ADD_NEW_LAND_INCIDENT](landIncidentData) {
    return new Promise((resolve, reject) => {
      ApiService.post("insert_land_incident", landIncidentData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_INCIDENT](incidentData) {
    return new Promise((resolve, reject) => {
      ApiService.post("update_incident", incidentData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_LAND_INCIDENT](incidentData) {
    return new Promise((resolve, reject) => {
      ApiService.post("update_land_incident", incidentData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_LAND_INCIDENTS_LIST](filterCurrentsList) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_land_incidents_list", filterCurrentsList)
        .then(({ data }) => {
          data = data.map((element) => {
            element.InsertionDateTime = new Date(element.InsertionDateTime);
            element.OccurrenceDateTime = new Date(element.OccurrenceDateTime);
            return element;
          });
          this.context.commit(Mutations.SET_LAND_INCIDENTS_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_LAND_EXTRA_INCIDENT](incidentId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_land_incident_extra_tables", incidentId)
        .then(({ data }) => {
          console.log("Get land extra incident data", data);
          // Remove non used information
          const landIncidentAffectedBodyParts =
            data.landIncidentAffectedBodyParts;

          const landIncidentActions = data.landIncidentActions;
          resolve({
            landIncidentAffectedBodyParts,
            landIncidentActions,
          });
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_IMAGES_LIST](filterImagesList) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_images_list", filterImagesList)
        .then(({ data }) => {
          data = data.map((element) => {
            element.DateTime = new Date(element.DateTime);
            return element;
          });
          this.context.commit(Mutations.SET_IMAGES_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_METOCEAN_DATA](filterMetoceanList) {
    return new Promise((resolve, reject) => {
      ApiService.post("metocean", filterMetoceanList)
        .then(({ data }) => {
          if (Object.keys(data).length > 0) {
            data.metoceanData.TargetDateTime =
              data.metoceanData.TargetDateTime.map((element) => {
                return new Date(element);
              });
            this.context.commit(Mutations.SET_METOCEAN_DATA, data.metoceanData);

            this.context.commit(Mutations.SET_SUN_DATA, data.suntimes);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_IMAGE_BASE64](dataImage) {
    return new Promise((resolve, reject) => {
      this.context.commit(Mutations.SET_IMAGE_LOADING, true);
      ApiService.post("get_image_base64", dataImage)
        .then(({ data }) => {
          data.dateTime = new Date(data.dateTime);
          this.context.commit(Mutations.SET_IMAGE_BASE64, data);
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_IMAGE_LOADING, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_SITE_VIEW_INFO](siteId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_site_view_info", siteId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_VIEW_INFO, data[0]);
          window.localStorage.setItem(
            "siteViewParameters",
            JSON.stringify(data[0])
          );
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.SET_SITE_VIEW_INFO](dataSiteView) {
    return new Promise((resolve, reject) => {
      ApiService.post("set_site_view_info", dataSiteView)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_TYPE_LAND_INCIDENTS_LIST](siteId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_land_incident_types", siteId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TYPE_LAND_INCIDENTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_CAUSE_LAND_INCIDENTS_LIST](siteId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_land_incident_causes", siteId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CAUSE_LAND_INCIDENTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_BODY_REGIONS_LIST](siteId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_body_regions", siteId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BODY_REGIONS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_BODY_REGIONS_PARTS_LIST](siteId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_body_region_parts", siteId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BODY_REGIONS_PARTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_BODY_PART_ZONES_LIST](siteId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_body_part_zones", siteId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BODY_PART_ZONES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_LAND_ACTIONS_LIST](siteId) {
    return new Promise((resolve, reject) => {
      ApiService.post("get_land_incident_action_types", siteId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LAND_ACTIONS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Mutation
  [Mutations.SET_CURRENTS_LIST](dataCurrentsList) {
    this.currentsList = dataCurrentsList;
  }

  @Mutation
  [Mutations.SET_CURRENTS_FIELD](dataCurrentsField) {
    this.currentsField = dataCurrentsField;
  }

  @Mutation
  [Mutations.SET_INCIDENTS_LIST](dataIncidentsList) {
    this.incidentsList = dataIncidentsList;
  }

  @Mutation
  [Mutations.SET_LAND_INCIDENTS_LIST](dataLandIncidentsList) {
    this.landIncidentsList = dataLandIncidentsList;
  }

  @Mutation
  [Mutations.SET_IMAGES_LIST](dataImagesList) {
    this.imagesList = dataImagesList;
  }

  @Mutation
  [Mutations.SET_METOCEAN_DATA](dataMetoceanDict) {
    this.metoceanData = dataMetoceanDict;
  }

  @Mutation
  [Mutations.SET_SUN_DATA](sunData) {
    this.sunData = sunData;
  }

  @Mutation
  [Mutations.SET_IMAGE_LOADING](imageIsLoading) {
    this.loadingImage = imageIsLoading;
  }

  @Mutation
  [Mutations.SET_IMAGE_BASE64](dataImageBase64) {
    this.imageBase64Data = dataImageBase64;
  }

  @Mutation
  [Mutations.SET_IMAGE_VISIBLE](showImageArg) {
    this.showImage = showImageArg;
  }

  @Mutation
  [Mutations.SET_CURRENTS_VISIBLE](showCurrentsArg) {
    this.showCurrents = showCurrentsArg;
  }

  @Mutation
  [Mutations.SET_INCIDENTS_VISIBLE](showIncidentsArg) {
    this.showIncidents = showIncidentsArg;
  }

  @Mutation
  [Mutations.SET_INCIDENT_ID_SELECTED](incidentId) {
    this.selectedIncidentId = incidentId;
  }

  @Mutation
  [Mutations.SET_SPEED_COLOR](speedColors) {
    this.speedColors = speedColors;
  }

  @Mutation
  [Mutations.SET_SPEED_THRESHOLDS](speedThresholds) {
    this.speedThresholds = speedThresholds;
  }

  @Mutation
  [Mutations.SET_WORST_DIRECTION_CARTESIAN](worstDirectionCartesian) {
    this.worstDirectionCartesian = worstDirectionCartesian;
  }

  @Mutation
  [Mutations.SET_MAP_VIEW](mapView) {
    this.mapView = mapView;
  }

  @Mutation
  [Mutations.SET_MAX_LOADED_IMAGES](maxLoadedImages) {
    this.maxLoadedImages = maxLoadedImages;
  }
  @Mutation
  [Mutations.SET_VIEW_INFO](siteViewInfo) {
    this.siteViewInfo = siteViewInfo;
  }

  @Mutation
  [Mutations.SET_INCIDENT_IMAGES_LIST](incidentImagesList) {
    this.incidentsImagesList = incidentImagesList;
  }

  @Mutation
  [Mutations.SET_EDIT_INCIDENT](incidentEditing) {
    this.incidentEditing = incidentEditing;
  }

  @Mutation
  [Mutations.SET_EDIT_LAND_INCIDENT](landIncidentEditing) {
    this.landIncidentEditing = landIncidentEditing;
  }

  @Mutation
  [Mutations.SET_TYPE_LAND_INCIDENTS](typeLandIncidents) {
    this.typeLandIncidents = typeLandIncidents;
  }

  @Mutation
  [Mutations.SET_CAUSE_LAND_INCIDENTS](causeLandIncidents) {
    this.causeLandIncidents = causeLandIncidents;
  }

  @Mutation
  [Mutations.SET_BODY_REGIONS](bodyRegions) {
    this.bodyRegions = bodyRegions;
  }

  @Mutation
  [Mutations.SET_BODY_REGIONS_PARTS](bodyRegionsParts) {
    this.bodyRegionsParts = bodyRegionsParts;
  }

  @Mutation
  [Mutations.SET_BODY_PART_ZONES](bodyPartZones) {
    this.bodyPartZones = bodyPartZones;
  }

  @Mutation
  [Mutations.SET_LAND_ACTIONS](landActionsList) {
    this.typeLandIncidentActions = landActionsList;
  }

  @Mutation
  [Mutations.RESET_STATE_DATA]() {
    this.currentsList = {};
    this.currentsField = null;
    this.incidentsList = {};
    this.imagesList = {};
    this.metoceanData = {};
    this.sunData = [];
    this.imageBase64Data = [];
    this.loadingImage = false;
    this.showImage = false;
    this.showCurrents = true;
    this.showIncidents = false;
    this.selectedIncidentId = null;
    this.speedColors = null;
    this.speedThresholds = null;
    this.worstDirectionCartesian = null;
    this.mapView = null;
    this.maxLoadedImages = 15;
    this.siteViewInfo = null;
    this.incidentsImagesList = [];
    this.incidentEditing = null;
  }

  @Mutation
  [Mutations.SET_LAST_DATE_INCIDENT_ADDED](date) {
    this.lastDateIncidentAdded = date;
  }

  @Mutation
  [Mutations.SET_LAST_DATE_LAND_INCIDENT_ADDED](date) {
    this.lastDateLandIncidentAdded = date;
  }

  @Mutation
  [Mutations.SET_ENABLE_LAND_INCIDENTS](status) {
    this.landIncidentsEnabled = status;
  }

  @Mutation
  [Mutations.SET_BODY_REGION_SELECTED](bodyRegionSelected) {
    this.bodyRegionSelected = bodyRegionSelected;
  }
}
